import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import "./pipelineCfgForm.scss";
import { textFieldStyles } from "./pipelineCfgFormStyles";
import api from "../../apiInterceptor";
const getprojcfgAPITestData = require("../../assets/apiTestData/pipeline-getprojcfg-test-data.json");
const getfecfgAPITestData = require("../../assets/apiTestData/getfecfg-test-data.json");
const listprojectsAPITestData = require("../../assets/apiTestData/listprojects-test-data.json");

const PipelineCfgForm = ({ allowOtherProject, setAllowOtherProject }) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  let IS_LIST_PROJECTS_GET_TYPE = localStorage.getItem(
    "IS_LIST_PROJECTS_GET_TYPE"
  );
  IS_LIST_PROJECTS_GET_TYPE =
    IS_LIST_PROJECTS_GET_TYPE === "true" || IS_LIST_PROJECTS_GET_TYPE === true
      ? true
      : false;
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedProjFg = useSelector((state) => state.selectedFeatureGroup);
  const pipelineConfigs = useSelector((state) => state.pipelineConfigs);
  const [versionsToShow, setVersionsToShow] = useState([]);
  const [dsToShow, setDsToShow] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [allProjectsInfo, setAllProjectsInfo] = useState([]);
  const dispatch = useDispatch();
  const {
    updatePipelineConfigs,
    updateAlertMessage,
    updateIsLoading,
    updateSnackbarMsg,
  } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    getNodeLevelInfo();
    getProjects();
  }, []);

  useEffect(() => {
    if (pipelineConfigs.sourceProjectKey) {
      handleVersionsToShow(pipelineConfigs.sourceProjectKey);
      handleDataSourcesToShow(
        pipelineConfigs.sourceProjectKey,
        pipelineConfigs.sourceProjVersion
      );
    } else {
      setInitialConfigs();
    }
  }, [pipelineConfigs, allProjectsInfo]);

  const setInitialConfigs = () => {
    const newFormData = structuredClone(pipelineConfigs);
    newFormData.sourceProjectKey = selectedProjectKey;
    handleVersionsToShow(selectedProjectKey);
    updatePipelineConfigs(newFormData);
  };

  const getNodeLevelInfo = async () => {
    try {
      const apiUrl = BASE_API_URL + "getprojcfg";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: selectedProjectKey,
        projVersion: selectedProjVersion,
        projFg: selectedProjFg,
        nodeType: "pipeline",
      };
      updateIsLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: getprojcfgAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, {
          headers: headers,
        });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        setAllowOtherProject(result.allowOtherProject);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateSnackbarMsg(response.data.data.reason);
        } else {
          updateSnackbarMsg("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateSnackbarMsg(errorMessage);
    }
  };

  const prepareProjectsDropdown = (projects) => {
    let projectsDropdownList = [];
    projects.forEach((project) => {
      let projObj = {
        key: project.projectKey,
        name: project.pname,
      };
      projectsDropdownList.push(projObj);
    });
    setAllProjects(projectsDropdownList);
  };

  const getProjects = async () => {
    try {
      const apiUrl = BASE_API_URL + "listprojects";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {};
      updateIsLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: listprojectsAPITestData,
        };
      } else {
        if (IS_LIST_PROJECTS_GET_TYPE) {
          response = await api.get(apiUrl, {
            headers: headers,
          });
        } else {
          response = await api.post(apiUrl, payload, { headers: headers });
        }
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        setAllProjectsInfo(response.data.data.posts[0]);
        prepareProjectsDropdown(response.data.data.posts[0]);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateSnackbarMsg(response.data.data.reason);
        } else {
          updateSnackbarMsg("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateSnackbarMsg(errorMessage);
    }
  };

  const getProjectObject = (pKey, projects = allProjectsInfo) => {
    return projects.find((obj) => obj.projectKey === pKey);
  };

  const handleVersionsToShow = (value) => {
    try {
      if (allProjects.length) {
        const projKey = value;
        let versions = [];
        versions = getProjectObject(projKey).versionInfo;
        if (projKey === selectedProjectKey) {
          versions = versions.filter(
            (version) => version.vname !== selectedProjVersion
          );
        }
        setVersionsToShow(versions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDataSources = async (projKey, projVersion) => {
    try {
      const apiUrl = BASE_API_URL + "getfecfg";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: projKey,
        cfgProjectKey: projKey,
        projVersion: projVersion,
        projFg: "base",
      };
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: getfecfgAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, {
          headers: headers,
        });
      }
      if (response.data.status === 200) {
        const feCfg = response.data.data.posts[0];
        const datasources = feCfg.clientMetaData
          ? feCfg.clientMetaData.dsMap
          : {};
        setDsToShow(datasources);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDataSourcesToShow = (projKey, projVersion) => {
    if (projKey && projVersion) {
      getDataSources(projKey, projVersion);
    } else {
      setDsToShow({});
    }
  };

  const handleChange = (e) => {
    try {
      const value = e.target.value;
      const name = e.target.name;
      const newFormData = structuredClone(pipelineConfigs);
      if (e?.target?.type === "text" && name === "name") {
        if (!(value.length <= 50 && /^[a-zA-Z0-9_]*$/.test(value))) {
          return;
        }
      }
      newFormData[name] = value;
      updatePipelineConfigs(newFormData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (e, inputId, value) => {
    const newFormData = structuredClone(pipelineConfigs);
    if (inputId === "sourceProjectKey") {
      value = value.key;
      newFormData.sourceDs = "";
      newFormData.sourceProjVersion = "";
    } else if (inputId === "sourceProjVersion") {
      value = value.vname;
      newFormData.sourceDs = "";
    }
    newFormData[inputId] = value;
    updatePipelineConfigs(newFormData);
  };

  const getDsOptionLabel = (option) => {
    if (option) {
      return dsToShow[option];
    }
    return "";
  };

  const getOptionLabel = (option) => {
    if (typeof option === "object") {
      return option.vname;
    } else {
      return option;
    }
  };

  const getSelectedValueLabel = () => {
    try {
      if (
        allProjectsInfo &&
        pipelineConfigs &&
        pipelineConfigs.sourceProjectKey
      ) {
        return getProjectObject(pipelineConfigs.sourceProjectKey).pname;
      }
      return "";
    } catch (error) {
      console.error(error);
    }
  };

  const renderSelectTags = (value, getTagProps) =>
    value.map((option, index) => (
      <Tooltip key={option} title={option} placement="top">
        <Chip label={option} {...getTagProps({ index })} />
      </Tooltip>
    ));

  const getSelectOptionLabel = (option) => {
    if (typeof option === "object") {
      return option.name;
    }
    return option;
  };

  return (
    <Stack className="pipeline-configs">
      <FormControl id="name" className="text-field" size="small">
        <TextField
          type="text"
          name="name"
          size="small"
          label="Name"
          value={pipelineConfigs ? pipelineConfigs.name : ""}
          onChange={(evt) => handleChange(evt)}
          variant="outlined"
          sx={textFieldStyles}
          autoComplete="off"
        />
      </FormControl>
      <FormControl id="description" className="text-field" size="small">
        <TextField
          type="text"
          name="description"
          size="small"
          label="Description"
          value={pipelineConfigs ? pipelineConfigs.description : ""}
          onChange={(evt) => handleChange(evt)}
          variant="outlined"
          sx={textFieldStyles}
          autoComplete="off"
        />
      </FormControl>
      {allowOtherProject && (
        <Autocomplete
          autoHighlight
          size="small"
          id="tags-outlined"
          options={allProjects}
          getOptionLabel={(option) => getSelectOptionLabel(option)}
          autoComplete
          includeInputInList
          disableClearable
          className="select-dropdown"
          ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
          isOptionEqualToValue={(option, value) =>
            option.name === value.name || value === ""
          }
          value={getSelectedValueLabel()}
          onChange={(e, value) => {
            handleSelectChange(e, "sourceProjectKey", value);
          }}
          componentsProps={{
            paper: {
              sx: {
                backgroundColor: "#212b35",
                color: "#d9d9d9",
                borderRadius: "4px",
                boxShadow:
                  "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                "li:hover": {
                  color: "#69c2d2",
                },
              },
            },
          }}
          renderTags={renderSelectTags}
          renderInput={(params) => (
            <Tooltip title={getSelectedValueLabel()} placement="right">
              <TextField
                {...params}
                size="small"
                label="Project Name"
                placeholder="Select option"
                autoComplete="off"
                sx={textFieldStyles}
              />
            </Tooltip>
          )}
        />
      )}
      <Autocomplete
        autoHighlight
        size="small"
        id="tags-outlined"
        options={versionsToShow}
        getOptionLabel={(option) => getOptionLabel(option)}
        autoComplete
        includeInputInList
        disableClearable
        className="select-dropdown"
        ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
        isOptionEqualToValue={(option, value) =>
          option.vname === value || value === ""
        }
        value={pipelineConfigs ? pipelineConfigs.sourceProjVersion : ""}
        onChange={(e, value) => {
          handleSelectChange(e, "sourceProjVersion", value);
        }}
        componentsProps={{
          paper: {
            sx: {
              backgroundColor: "#212b35",
              color: "#d9d9d9",
              borderRadius: "4px",
              boxShadow:
                "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
              "li:hover": {
                color: "#69c2d2",
              },
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label="Project Version"
            placeholder="Select option"
            autoComplete="off"
            sx={textFieldStyles}
          />
        )}
      />
      <Autocomplete
        autoHighlight
        size="small"
        id="tags-outlined"
        options={Object.keys(dsToShow)}
        getOptionLabel={(option) => getDsOptionLabel(option)}
        autoComplete
        includeInputInList
        disableClearable
        className="select-dropdown"
        ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
        isOptionEqualToValue={(option, value) =>
          option === value || value === ""
        }
        value={
          pipelineConfigs && pipelineConfigs.sourceDs
            ? pipelineConfigs.sourceDs
            : ""
        }
        onChange={(e, value) => {
          handleSelectChange(e, "sourceDs", value);
        }}
        componentsProps={{
          paper: {
            sx: {
              backgroundColor: "#212b35",
              color: "#d9d9d9",
              borderRadius: "4px",
              boxShadow:
                "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
              "li:hover": {
                color: "#69c2d2",
              },
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label="Datasource"
            placeholder="Select option"
            autoComplete="off"
            sx={textFieldStyles}
          />
        )}
      />
      <FormControl id="sourceProjFg" className="text-field" size="small">
        <TextField
          type="text"
          name="sourceProjFg"
          size="small"
          label="Feature group name"
          value={pipelineConfigs ? pipelineConfigs.sourceProjFg : ""}
          disabled
          variant="outlined"
          sx={textFieldStyles}
          autoComplete="off"
        />
      </FormControl>
    </Stack>
  );
};

export default PipelineCfgForm;
