import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { TextField, Tooltip } from "@mui/material";
import { ReactComponent as ConfigureIcon } from "../../assets/icons/configureIcon.svg";
import { ReactComponent as ImportIcon } from "../../assets/icons/importIcon.svg";
import { ReactComponent as SaveIcon } from "../../assets/icons/saveIcon.svg";
import { ReactComponent as CollapseStagesIcon } from "../../assets/icons/collapseModeIcon.svg";
import { ReactComponent as StopIcon } from "../../assets/icons/stopIcon.svg";
import { ReactComponent as ResumeIcon } from "../../assets/icons/resumeIcon.svg";
import { ReactComponent as BackupIcon } from "../../assets/icons/backupIcon.svg";
import { ReactComponent as RestoreIcon } from "../../assets/icons/restoreIcon.svg";
import { ReactComponent as ProjectInfoIcon } from "../../assets/icons/projectInfoIcon.svg";
import { ReactComponent as RunConfigurationIcon } from "../../assets/icons/runConfigurationIcon.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/searchIcon.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copyIcon.svg";
import { ReactComponent as PasteIcon } from "../../assets/icons/pasteIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteIcon2.svg";
import { ReactComponent as DataSourcesIcon } from "../../assets/icons/datasourcesIcon.svg";

import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import api from "../../apiInterceptor";
import "./playgroundToolbar.scss";
import { disableCopyingMode, handleCopyModeDisabling } from "utils/utils";
import DeleteDialog from "../../modals/deleteDialog/deleteDialog";
const stopRequestAPITestData = require("../../assets/apiTestData/success-test-data.json");

const PlaygroundToolbar = ({
  handleSearchChange,
  showDisabledButtons,
  intervalIds,
  saveConfigurations,
  showStopBtn,
  disableStopBtn,
  disableBackupAndRestoreBtn,
  runConfiguration,
  setShowStopBtn,
  executePipeline,
  showResumeBtn,
  updateNodesOpacity,
  flowInfo,
  pasteNodes,
  handleMultiNodeDeletion,
  collapseStages,
  nodes,
  edges,
  updateNodes,
  updateEdges,
  nodesBeforeCollapse,
  setNodesBeforeCollapse,
}) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  let GLOBAL_COLLAPSE_ENABLED = localStorage.getItem("GLOBAL_COLLAPSE_ENABLED");
  let ENABLE_STOP_RESUME_EXECUTION = localStorage.getItem(
    "ENABLE_STOP_RESUME_EXECUTION"
  );
  const userAccessType = localStorage.getItem("ACCESS_TYPE");
  const userAccessRestricted = userAccessType === "view";
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  GLOBAL_COLLAPSE_ENABLED =
    GLOBAL_COLLAPSE_ENABLED === "true" || GLOBAL_COLLAPSE_ENABLED === true
      ? true
      : false;
  ENABLE_STOP_RESUME_EXECUTION =
    ENABLE_STOP_RESUME_EXECUTION === "true" ||
    ENABLE_STOP_RESUME_EXECUTION === true
      ? true
      : false;
  const param = useParams();
  const projectKey = param.projectKey;
  const projVersion = param.projVersion;
  const featureGroup = param.projFg;
  const [showImportBtn, setShowImportBtn] = useState(false);
  const enableCopyMode = useSelector((state) => state.enableCopyMode);
  const copyNodesList = useSelector((state) => state.copyNodesList);
  const isDeleteDialogOpen = useSelector((state) => state.isDeleteDialogOpen);
  const pipeline = useSelector((state) => state.pipeline);
  const collapsedDs = useSelector((state) => state.collapsedDs);
  const globalCollapseEnabled = useSelector(
    (state) => state.globalCollapseEnabled
  );
  const dispatch = useDispatch();
  const {
    updateShowImportConfigDialog,
    updateConfigureVariablesDialogStatus,
    updateIsPlaygroundLoading,
    updateAlertMessage,
    updateShowBackupDialog,
    updateShowRestoreDialog,
    updateEnableCopyMode,
    updateCopyNodesList,
    updateDeleteDialogStatus,
    updateCollapsedDs,
    updateGlobalCollapse,
    updateShowDatasourcesDialog,
    updatePipeline,
    updateShowProjectInfoDialog,
  } = bindActionCreators(actionCreators, dispatch);

  const textFieldStyles = {
    background: "#000",
    zIndex: "10",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D9D9D9",
      },
      "&:hover fieldset": {
        borderColor: "#69C2D2",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#69C2D2",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#D9D9D9",
    },
    "& .MuiInputLabel-root.Mui-error": {
      color: "#d32f2f",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#69C2D2",
      "& .MuiInputLabel-asterisk": {
        color: "#69C2D2",
      },
    },
    "& .MuiInputLabel-outlined.Mui-focused.Mui-error": {
      color: "#69C2D2",
    },
    "& .MuiInputBase-root": {
      color: "#D9D9D9",
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#D9D9D9",
    },
    svg: {
      fill: "#D9D9D9",
    },
  };

  useEffect(() => {
    if (featureGroup === "base") {
      setShowImportBtn(true);
    }
  }, []);

  const resumePipelineExecution = async () => {
    const terminator = {
      dSource: null,
      stageId: null,
    };
    executePipeline(terminator, true);
  };

  const stopPipelineExecution = async () => {
    try {
      const apiUrl = BASE_API_URL + "stopRequest";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: projectKey,
        projVersion: projVersion,
        projFg: featureGroup,
      };
      updateIsPlaygroundLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: stopRequestAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsPlaygroundLoading(false);
      if (response.data.status === 200) {
        setShowStopBtn(true);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsPlaygroundLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const showConfigureVariablesDialog = () => {
    updateConfigureVariablesDialogStatus(true);
  };

  const handleImportConfigClick = () => {
    updateShowImportConfigDialog(true);
  };

  const handleBackupDialogClick = () => {
    updateShowBackupDialog(true);
  };

  const handleRestoreDialogClick = () => {
    updateShowRestoreDialog(true);
  };

  const handleProjectInfoDialogClick = () => {
    updateShowProjectInfoDialog(true);
  };

  const handleCopyIconClick = () => {
    const flow = flowInfo.toObject();
    const nodes = flow.nodes;
    if (enableCopyMode) {
      disableCopyingMode(nodes, updateEnableCopyMode, updateCopyNodesList);
    } else {
      updateEnableCopyMode(true);
      nodes.forEach((nd) => {
        nd.className += " copy-mode";
      });
    }
    updateNodesOpacity(nodes);
  };

  const handlePasteIconClick = async () => {
    await pasteNodes();
    handleCopyModeDisabling(
      flowInfo,
      updateEnableCopyMode,
      updateCopyNodesList,
      updateNodesOpacity
    );
  };

  const handleMultiDeletion = () => {
    updateDeleteDialogStatus(true);
  };

  const collapseAllStages = () => {
    if (Object.keys(pipeline).length !== 0) {
      let allNodes = structuredClone(nodes);
      let allEdges = structuredClone(edges);
      let clonedCollapsedDs = structuredClone(collapsedDs);
      let isGlobalCollapseEnabled = !globalCollapseEnabled;
      if (isGlobalCollapseEnabled && nodesBeforeCollapse.length === 0) {
        setNodesBeforeCollapse(nodes);
      }
      for (let nd of nodes) {
        if (!nd.data.isStage && pipeline[nd.id].stageCount) {
          const updatedNodesAndEdges = collapseStages(
            nd,
            allNodes,
            allEdges,
            isGlobalCollapseEnabled
          );
          allNodes = updatedNodesAndEdges.newNodes;
          allEdges = updatedNodesAndEdges.newEdges;
          const index = clonedCollapsedDs.indexOf(nd.id);
          if (isGlobalCollapseEnabled) {
            if (index === -1) {
              clonedCollapsedDs.push(nd.id);
            }
          } else {
            if (index > -1) {
              clonedCollapsedDs.splice(index, 1);
            }
          }
        }
      }
      if (!isGlobalCollapseEnabled) {
        resetPositionOfCollapsedNode(allNodes);
        setNodesBeforeCollapse([]);
      }
      updateNodes(allNodes);
      updateEdges(allEdges);
      updateCollapsedDs(clonedCollapsedDs);
      updateGlobalCollapse(isGlobalCollapseEnabled);
      let configurations = structuredClone(pipeline);
      configurations.clientMetaData.flow.edges = allEdges;
      configurations.clientMetaData.flow.nodes = allNodes;
      updatePipeline(configurations);
    }
  };

  const resetPositionOfCollapsedNode = (allNodes) => {
    for (let node of allNodes) {
      if (!node.data.isStage) {
        const nodeInfo = nodesBeforeCollapse.find((nd) => nd.id === node.id);
        if (nodeInfo) {
          node.position = nodeInfo.position;
          node.positionAbsolute = nodeInfo.positionAbsolute;
        }
      }
    }
  };

  const openDatasourcesDialog = () => {
    updateShowDatasourcesDialog(true);
  };

  return (
    <div>
      <Stack className="toolbar-container">
        <TextField
          type="text"
          size="small"
          placeholder="Search"
          onChange={handleSearchChange}
          variant="outlined"
          sx={textFieldStyles}
          className="search-box"
          autoComplete="off"
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
        <Stack className="icons-container">
          <Tooltip title="Datasoures" placement="bottom">
            <div
              className={`icon-button  ${
                enableCopyMode ||
                showDisabledButtons ||
                intervalIds.current.length > 0
                  ? "disabled-button"
                  : ""
              }`}
            >
              <IconButton
                onClick={() => openDatasourcesDialog()}
                disabled={
                  enableCopyMode ||
                  showDisabledButtons ||
                  intervalIds.current.length > 0
                }
                aria-label="datasources"
                className="datasources-icon icon"
              >
                <DataSourcesIcon />
              </IconButton>
            </div>
          </Tooltip>
          {enableCopyMode && copyNodesList.length ? (
            <Tooltip title="Delete nodes" placement="bottom">
              <div
                className={`icon-button  ${
                  intervalIds.current.length > 0 ? "disabled-button" : ""
                }`}
              >
                <IconButton
                  onClick={handleMultiDeletion}
                  disabled={intervalIds.current.length > 0}
                  aria-label="Delete nodes"
                  className="delete-icon icon"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </Tooltip>
          ) : (
            <></>
          )}
          {enableCopyMode && copyNodesList.length ? (
            <Tooltip title="Paste nodes" placement="bottom">
              <div
                className={`icon-button  ${
                  intervalIds.current.length > 0 ? "disabled-button" : ""
                }`}
              >
                <IconButton
                  onClick={handlePasteIconClick}
                  disabled={intervalIds.current.length > 0}
                  aria-label="Paste nodes"
                  className="paste-icon icon"
                >
                  <PasteIcon />
                </IconButton>
              </div>
            </Tooltip>
          ) : (
            <></>
          )}
          <Tooltip
            title={enableCopyMode ? "Disable copy mode" : "Enable copy mode"}
            placement="bottom"
          >
            <div
              className={`icon-button  ${
                intervalIds.current.length > 0 ||
                globalCollapseEnabled ||
                collapsedDs.length > 0 ||
                userAccessRestricted
                  ? "disabled-button"
                  : ""
              }`}
            >
              <IconButton
                onClick={handleCopyIconClick}
                disabled={
                  intervalIds.current.length > 0 ||
                  globalCollapseEnabled ||
                  collapsedDs.length > 0 ||
                  userAccessRestricted
                }
                aria-label="Copy nodes"
                className="copy-icon icon"
              >
                <CopyIcon />
              </IconButton>
            </div>
          </Tooltip>
          {showImportBtn && (
            <Tooltip title="Import" placement="bottom">
              <div
                className={`icon-button  ${
                  showDisabledButtons ||
                  enableCopyMode ||
                  intervalIds.current.length > 0 ||
                  globalCollapseEnabled ||
                  collapsedDs.length > 0 ||
                  userAccessRestricted
                    ? "disabled-button"
                    : ""
                }`}
              >
                <IconButton
                  onClick={handleImportConfigClick}
                  disabled={
                    showDisabledButtons ||
                    enableCopyMode ||
                    intervalIds.current.length > 0 ||
                    globalCollapseEnabled ||
                    collapsedDs.length > 0 ||
                    userAccessRestricted
                  }
                  aria-label="import"
                  className="import-icon icon"
                >
                  <ImportIcon />
                </IconButton>
              </div>
            </Tooltip>
          )}
          {GLOBAL_COLLAPSE_ENABLED ? (
            <Tooltip
              title={
                globalCollapseEnabled
                  ? "Uncollapse feature engineering nodes"
                  : "Collapse feature engineering nodes"
              }
              placement="bottom"
            >
              <div
                className={`icon-button  ${
                  enableCopyMode ||
                  showDisabledButtons ||
                  intervalIds.current.length > 0
                    ? "disabled-button"
                    : ""
                }`}
              >
                <IconButton
                  onClick={() => collapseAllStages()}
                  disabled={
                    enableCopyMode ||
                    showDisabledButtons ||
                    intervalIds.current.length > 0 ||
                    Object.keys(pipeline).length === 0
                  }
                  aria-label="collapse feature engineering nodes"
                  className="collapse-stages-icon icon"
                >
                  <CollapseStagesIcon />
                </IconButton>
              </div>
            </Tooltip>
          ) : (
            <></>
          )}
          <Tooltip title="Save" placement="bottom">
            <div
              className={`icon-button  ${
                enableCopyMode ||
                showDisabledButtons ||
                intervalIds.current.length > 0 ||
                userAccessRestricted
                  ? "disabled-button"
                  : ""
              }`}
            >
              <IconButton
                onClick={() => saveConfigurations("globalmode")}
                disabled={
                  enableCopyMode ||
                  showDisabledButtons ||
                  intervalIds.current.length > 0 ||
                  userAccessRestricted
                }
                aria-label="save"
                className="save-icon icon"
              >
                <SaveIcon />
              </IconButton>
            </div>
          </Tooltip>
          {ENABLE_STOP_RESUME_EXECUTION && showStopBtn && (
            <Tooltip
              title={disableStopBtn ? "Stopping" : "Stop"}
              placement="bottom"
            >
              <div
                className={`icon-button  ${
                  disableStopBtn || userAccessRestricted
                    ? "disabled-button"
                    : ""
                }`}
              >
                <IconButton
                  onClick={() => stopPipelineExecution()}
                  disabled={disableStopBtn || userAccessRestricted}
                  aria-label="stop"
                  className="stop-icon icon"
                >
                  <StopIcon />
                </IconButton>
              </div>
            </Tooltip>
          )}
          {ENABLE_STOP_RESUME_EXECUTION && showResumeBtn && (
            <Tooltip title="Resume" placement="bottom">
              <div
                className={`icon-button  ${
                  userAccessRestricted ? "disabled-button" : ""
                }`}
              >
                <IconButton
                  onClick={() => resumePipelineExecution()}
                  disabled={userAccessRestricted}
                  aria-label="resume"
                  className="resume-icon icon"
                >
                  <ResumeIcon />
                </IconButton>
              </div>
            </Tooltip>
          )}
          <Tooltip title="Run" placement="bottom">
            <div
              className={`icon-button  ${
                enableCopyMode ||
                showDisabledButtons ||
                intervalIds.current.length > 0 ||
                globalCollapseEnabled ||
                collapsedDs.length > 0 ||
                userAccessRestricted
                  ? "disabled-button"
                  : ""
              }`}
            >
              <IconButton
                onClick={() => runConfiguration()}
                disabled={
                  enableCopyMode ||
                  showDisabledButtons ||
                  intervalIds.current.length > 0 ||
                  globalCollapseEnabled ||
                  collapsedDs.length > 0 ||
                  userAccessRestricted
                }
                aria-label="run"
                className="run-icon icon"
              >
                <RunConfigurationIcon />
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip title="Configure Variables" placement="bottom">
            <div className={`icon-button`}>
              <IconButton
                onClick={() => showConfigureVariablesDialog()}
                aria-label="configure-variables"
                className="configure-variables-icon icon"
              >
                <ConfigureIcon />
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip title="Backup" placement="bottom">
            <div
              className={`icon-button  ${
                enableCopyMode ||
                disableBackupAndRestoreBtn ||
                globalCollapseEnabled ||
                collapsedDs.length > 0 ||
                userAccessRestricted
                  ? "disabled-button"
                  : ""
              }`}
            >
              <IconButton
                onClick={handleBackupDialogClick}
                aria-label="backup"
                className="backup-icon icon"
                disabled={
                  enableCopyMode ||
                  disableBackupAndRestoreBtn ||
                  globalCollapseEnabled ||
                  collapsedDs.length > 0 ||
                  userAccessRestricted
                }
              >
                <BackupIcon />
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip title="Restore" placement="bottom">
            <div
              className={`icon-button  ${
                enableCopyMode ||
                disableBackupAndRestoreBtn ||
                globalCollapseEnabled ||
                collapsedDs.length > 0 ||
                userAccessRestricted
                  ? "disabled-button"
                  : ""
              }`}
            >
              <IconButton
                onClick={handleRestoreDialogClick}
                aria-label="restore"
                className="restore-icon icon"
                disabled={
                  enableCopyMode ||
                  disableBackupAndRestoreBtn ||
                  globalCollapseEnabled ||
                  collapsedDs.length > 0 ||
                  userAccessRestricted
                }
              >
                <RestoreIcon />
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip title="Info" placement="bottom">
            <div className="icon-button">
              <IconButton
                onClick={handleProjectInfoDialogClick}
                aria-label="restore"
                className="restore-icon icon"
              >
                <ProjectInfoIcon />
              </IconButton>
            </div>
          </Tooltip>
        </Stack>
      </Stack>
      {isDeleteDialogOpen && (
        <DeleteDialog
          deletableItem={copyNodesList}
          deleteHandler={handleMultiNodeDeletion}
          deletionType={"multiple-nodes"}
          flowInfo={flowInfo}
          updateNodesOpacity={updateNodesOpacity}
        />
      )}
    </div>
  );
};

export default PlaygroundToolbar;
