import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import "./DataCleansingHandlerNodeDialog.scss";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import {
  Snackbar,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  Tooltip,
  Typography,
  Autocomplete,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { ReactComponent as QuestionMarkIcon } from "../../assets/icons/questionMark.svg";
import { useReactFlow } from "reactflow";
import {
  getConnectedDataSources,
  getConnectedNodes,
  fetchFeatures,
  prepareColumns,
} from "utils/utils";
import {
  buttonStyles,
  formControlStyles,
  labelStyles,
  menuItemStyles,
  selectStyles,
  chipTextFieldStyles,
  textFieldStyles,
} from "./DataCleansingHandlerNodeDialogStyles";
import CustomTooltip from "components/Tooltip/CustomTooltip";
import api from "../../apiInterceptor";
const dataclnAPITestData = require("../../assets/apiTestData/datacln-test-data.json");
const nodeInfo = require("../../assets/testdata/node-info-testdata.json");
const getprojcfgAPITestData = require("../../assets/apiTestData/getprojcfg-test-data.json");

const DataCleansingHandlerNodeDialog = (modalProps) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  const userAccessType = localStorage.getItem("ACCESS_TYPE");
  const userAccessRestricted = userAccessType === "view";
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const modalType = "dataCleansingHandlerNodeDialog";
  const GroupHeader = styled("div")(({ theme, padding = "4px 10px" }) => ({
    position: "sticky",
    top: "-8px",
    zIndex: 10000,
    padding: padding,
    color: "#FFFFFF",
    backgroundColor: "rgb(68 79 91)",
  }));
  const GroupItems = styled("ul")({
    padding: 0,
  });

  const { getNode } = useReactFlow();
  const formData = {
    name: "",
    description: "",
    dataclnCfg: [],
  };
  let autoCompleteRef = {};
  const columnsInfo = [];
  const filteredOptions = useRef({});
  const [selectAllCheckboxes, setSelectAllCheckboxes] = useState({});
  const selectedNode = modalProps.selectedNode;
  const nodesFlow = modalProps.nodesFlow;
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [dataCleansingOptions, setDataCleansingOptions] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [filteredAllColumns, setFilteredAllColumns] = useState([]);
  const [dataClnCfgs, setDataClnCfgs] = useState([
    {
      dcKey: "",
      column: [],
      inputVal: "",
      outputVal: "",
    },
  ]);
  const snackbarMsg = useSelector((state) => state.snackbarMsg);
  const modalForm = useSelector((state) => state.modalForm);
  const pipeline = useSelector((state) => state.pipeline);
  const nodeConfigurations =
    useSelector((state) => state.nodeConfigurations) || {};
  const detailedMsgTooltipData = useSelector(
    (state) => state.detailedMsgTooltipData
  );
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedFeatureGroup = useSelector(
    (state) => state.selectedFeatureGroup
  );
  const dispatch = useDispatch();
  const {
    updateModalForm,
    updateNodeConfigurations,
    updateAlertMessage,
    updateSnackbarMsg,
    updateIsLoading,
  } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    const absentDs = handleDataSourceAbsence();
    if (absentDs) {
      return;
    }
    getDataCleansingOptions();
    if (modalForm === null) {
      initializeModalForm();
    }
    getNodeLevelInfo();
    initializeFilteredOptionsAndSelectAllCheckboxes();
  }, []);

  const initializeFilteredOptionsAndSelectAllCheckboxes = () => {
    let filteredOpts = {};
    const clonedSelectAllCheckboxes = {};
    filteredOpts["column"] = [];
    clonedSelectAllCheckboxes["column"] = false;
    filteredOptions.current = filteredOpts;
    setSelectAllCheckboxes(clonedSelectAllCheckboxes);
  };

  useEffect(() => {
    let disableSave = false;
    if (!modalForm) {
      disableSave = true;
    } else {
      disableSave = handleSaveDisable();
    }
    if (disableSave) {
      setIsSaveDisabled(true);
    } else {
      setIsSaveDisabled(false);
    }
  }, [modalForm, dataClnCfgs]);

  const handleDataSourceAbsence = () => {
    if (connectedDataSources.length === 0) {
      updateAlertMessage("Datasource is not present");
      modalProps.closeModal(modalType);
      return true;
    }
    return false;
  };

  const initializeModalForm = () => {
    if (nodeConfigurations[selectedNode.id]) {
      const clonedNodeConfigs = structuredClone(
        nodeConfigurations[selectedNode.id]
      );
      const dataClnCfg = convertListToString(clonedNodeConfigs.dataclnCfg);
      setDataClnCfgs(dataClnCfg);
      setTimeout(() => updateModalForm(clonedNodeConfigs), 1);
    } else {
      setTimeout(() => updateModalForm(formData), 1);
    }
  };

  const generateFilteredColumns = (columns) => {
    if (nodeConfigurations[selectedNode.id]) {
      const clonedNodeConfigs = structuredClone(
        nodeConfigurations[selectedNode.id]
      );
      let selectedChips = [];
      for (const cfg of clonedNodeConfigs.dataclnCfg) {
        selectedChips = selectedChips.concat(cfg.column);
      }
      const prevColumns = columns.filter(
        (item) => !selectedChips.includes(item)
      );
      setFilteredAllColumns(prevColumns);
    }
  };

  const convertListToString = (dataclnCfg) => {
    for (const cfg of dataclnCfg) {
      if (cfg.output === "yes" && Array.isArray(cfg.outputVal)) {
        cfg.outputVal = cfg.outputVal.join(",");
      }
      if (cfg.input === "yes" && Array.isArray(cfg.inputVal)) {
        cfg.inputVal = cfg.inputVal.join(",");
      }
    }
    return dataclnCfg;
  };

  const convertStringToList = (dataclnCfg) => {
    for (const cfg of dataclnCfg) {
      if (cfg.output === "yes" && typeof cfg.outputVal === "string") {
        cfg.outputVal = cfg.outputVal.split(",");
      }
      if (cfg.input === "yes" && typeof cfg.inputVal === "string") {
        cfg.inputVal = cfg.inputVal.split(",");
      }
    }
    return dataclnCfg;
  };

  const getDataCleansingOptions = async () => {
    try {
      const apiUrl = BASE_API_URL + "datacln";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      updateIsLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: dataclnAPITestData,
          status: 200,
        };
      } else {
        response = await api.get(apiUrl, {
          headers: headers,
        });
      }
      updateIsLoading(false);
      if (response.status === 200) {
        const result = response.data;
        setDataCleansingOptions(result);
      } else if (response.status === 404) {
        if (response.data.reason) {
          updateAlertMessage(response.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
        modalProps.closeModal(modalType);
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const getSelectedDataCleansingOptionChange = (dcKey) => {
    const option =
      dataCleansingOptions.find((opt) => opt.dckey === dcKey) || {};
    return option;
  };

  const getNodeLevelInfo = async () => {
    try {
      const apiUrl = BASE_API_URL + "getprojcfg";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: selectedProjectKey,
        projVersion: selectedProjVersion,
        projFg: selectedFeatureGroup,
        nodeType: selectedNode.data.nodeType,
      };
      updateIsLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: getprojcfgAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        const nodeColsInfo = result.columnsInfo
          ? result.columnsInfo
          : nodeInfo.columnsInfo;
        initializeColumns(nodeColsInfo);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
        modalProps.closeModal(modalType);
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const initializeColumns = async (nodeColsInfo) => {
    for (let connectedNode of connectedNodes) {
      const features = await fetchFeatures(
        connectedNode.dSource,
        connectedNode.stageId,
        selectedProjectKey,
        selectedProjVersion,
        selectedFeatureGroup,
        updateIsLoading,
        updateAlertMessage
      );
      if (!features) {
        updateSnackbarMsg("Couldn't fetch features. Please save and try again");
        return;
      }
      columnsInfo.push(features);
    }
    const allColumnValues = prepareColumns(columnsInfo, nodeColsInfo);
    setAllColumns(allColumnValues.columns);
    setFilteredAllColumns(allColumnValues.columns);
    generateFilteredColumns(allColumnValues.columns);
  };

  const handleSaveDisable = () => {
    for (const dataClnCfg of dataClnCfgs) {
      const columnLength = dataClnCfg.column.length;
      if (!dataClnCfg.dcKey || columnLength === 0) {
        return true;
      }
      if (dataClnCfg.input === "yes") {
        const inputArray = dataClnCfg.inputVal.split(",");
        const emptyInputArray = inputArray.some((input) => !input);
        if (emptyInputArray || inputArray.length !== columnLength) {
          return true;
        }
      }
      if (dataClnCfg.output === "yes") {
        const outputArray = dataClnCfg.outputVal.split(",");
        const emptyOutputArray = outputArray.some((output) => !output);
        if (emptyOutputArray || outputArray.length !== columnLength) {
          return true;
        }
      }
      if (dataClnCfg.input === "yes" && dataClnCfg.output === "yes") {
        const inputArray = dataClnCfg.inputVal.split(",");
        const outputArray = dataClnCfg.outputVal.split(",");
        if (inputArray.length !== outputArray.length) {
          return true;
        }
      }
    }
    return false;
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (e?.target?.type === "text" && name === "name") {
      if (!(value.length <= 50 && /^[a-zA-Z0-9_]*$/.test(value))) {
        return;
      }
    }
    const newFormData = structuredClone(modalForm);
    newFormData[name] = value;
    updateModalForm(newFormData);
  };

  const handleDataClnCfgChange = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;
    let newDataClnCfgs = structuredClone(dataClnCfgs);
    if (name === "dcKey") {
      const dcData = getSelectedDataCleansingOptionChange(value);
      newDataClnCfgs[index]["input"] = dcData?.input || "";
      newDataClnCfgs[index]["output"] = dcData?.output || "";
      newDataClnCfgs[index]["inputText"] = dcData?.inputText || "";
      newDataClnCfgs[index]["outputText"] = dcData?.outputText || "";
      newDataClnCfgs[index]["inputVal"] = "";
      newDataClnCfgs[index]["outputVal"] = "";
    }
    newDataClnCfgs[index][name] = value;
    setDataClnCfgs(newDataClnCfgs);
  };

  const connectedDataSources = getConnectedDataSources(
    nodesFlow,
    selectedNode,
    getNode
  );
  const connectedNodes = getConnectedNodes(nodesFlow, selectedNode, getNode);

  const checkNodeNameDuplicacy = (nodeConfigs) => {
    const dsMap = pipeline.clientMetaData.dsMap;
    if (nodeConfigs.name || nodeConfigs.fileName) {
      for (let nodeId of Object.keys(dsMap)) {
        if (
          selectedNode.id !== nodeId &&
          ((nodeConfigs.name && dsMap[nodeId] === nodeConfigs.name) ||
            (nodeConfigs.fileName && nodeConfigs.fileName === dsMap[nodeId]))
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    updateSnackbarMsg(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newNodeConfigurations = structuredClone(nodeConfigurations);
    const newFormData = structuredClone(modalForm);
    const duplicatedName = checkNodeNameDuplicacy(newFormData);
    if (duplicatedName) {
      updateSnackbarMsg("Name already taken. Please enter a unique name");
      return;
    }
    const newDataClnCfgs = structuredClone(dataClnCfgs);
    newFormData["dataclnCfg"] = convertStringToList(newDataClnCfgs);
    updateModalForm(newFormData);
    newNodeConfigurations[selectedNode.id] = structuredClone(newFormData);
    newNodeConfigurations[selectedNode.id]["inputDs"] = connectedDataSources[0];
    updateNodeConfigurations(newNodeConfigurations);
    modalProps.unHighlightProblematicNode(selectedNode);
    setTimeout(
      () => modalProps.saveConfig(newNodeConfigurations, selectedNode.id),
      1
    );
    modalProps.closeModal(modalType);
  };

  const getOptionLabel = (option) => {
    try {
      if (option.item) {
        return option.item;
      } else {
        return option;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getChipDropdownValue = (dataClnCfg, inputId) => {
    if (dataClnCfg && dataClnCfg[inputId]) {
      if (typeof dataClnCfg[inputId] === "string") {
        return [dataClnCfg[inputId]];
      } else {
        return dataClnCfg[inputId];
      }
    } else {
      return [];
    }
  };

  const filterMultiSelectOptions = (options, inputValue, inputId) => {
    if (options.length) {
      const filteredOpts = options.filter((option) => {
        if (option.item) {
          return option.item.toLowerCase().includes(inputValue.toLowerCase());
        } else if (option.value) {
          return option.value.toLowerCase().includes(inputValue.toLowerCase());
        } else {
          return option.toLowerCase().includes(inputValue.toLowerCase());
        }
      });
      updateFilteredOptions(filteredOpts, inputId);
      return filteredOpts;
    }
    return [];
  };

  const updateFilteredOptions = (filteredOpt, inputId) => {
    filteredOptions.current[inputId] = filteredOpt;
    const clonedSelectAllCheckboxes = structuredClone(selectAllCheckboxes);
    if (clonedSelectAllCheckboxes[inputId]) {
      clonedSelectAllCheckboxes[inputId] = false;
      setSelectAllCheckboxes(clonedSelectAllCheckboxes);
    }
  };

  const isOptionEqualToValue = (option, value, options) => {
    if (options[0]?.name) {
      options = options.map((opt) => opt.name);
    } else if (options[0]?.value) {
      options = options.map((opt) => opt.value);
    }
    if (options.includes(value)) {
      if (typeof option === "object") {
        return (
          option.value === value ||
          option.item === value ||
          option.name === value ||
          value === ""
        );
      } else {
        return option === value || value === "";
      }
    }
    return value === "";
  };

  const handleChipsChange = (e, value, index) => {
    let newDataClnCfgs = structuredClone(dataClnCfgs);
    let prevSelectedColumns = newDataClnCfgs[index]["column"];
    if (Array.isArray(value)) {
      for (let index = 0; index < value.length; index++) {
        if (typeof value[index] === "object") {
          if (value[index]?.item) {
            value[index] = value[index]?.item;
          } else {
            value[index] = value[index]?.value;
          }
        }
      }
      for (let index = 0; index < value.length - 1; index++) {
        if (value[index] === value[value.length - 1]) return;
      }
    }
    if (typeof value === "object" && !Array.isArray(value)) {
      if (value?.item) {
        value = value?.item;
      } else {
        value = value?.value;
      }
    }
    newDataClnCfgs[index]["column"] = value;
    setDataClnCfgs(newDataClnCfgs);
    if (Array.isArray(prevSelectedColumns) && Array.isArray(value)) {
      let prevColumns = structuredClone(filteredAllColumns);
      let removedItems = [];
      let addedItems = [];
      if (prevSelectedColumns.length < value.length) {
        addedItems = value.filter(
          (item) => !prevSelectedColumns.includes(item)
        );
        prevColumns = prevColumns.filter((item) => !addedItems.includes(item));
      } else {
        removedItems = prevSelectedColumns.filter(
          (item) => !value.includes(item)
        );
        prevColumns = [...prevColumns, ...removedItems];
      }
      setFilteredAllColumns(prevColumns);
    }
  };

  const handleAddDataClnCfg = () => {
    const newDataClnCfgs = structuredClone(dataClnCfgs);
    newDataClnCfgs.push({
      dcKey: "",
      column: [],
      inputVal: "",
      outputVal: "",
    });
    setDataClnCfgs(newDataClnCfgs);
  };

  const handleRemoveDataClnCfg = (index) => {
    const newDataClnCfgs = structuredClone(dataClnCfgs);
    let prevColumns = structuredClone(filteredAllColumns);
    const removedItems = newDataClnCfgs[index].column;
    prevColumns = [...prevColumns, ...removedItems];
    setFilteredAllColumns(prevColumns);
    newDataClnCfgs.splice(index, 1);
    setDataClnCfgs(newDataClnCfgs);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const renderTags = (value, getTagProps) =>
    value.map((option, index) => (
      <Tooltip key={option} title={option} placement="top">
        <Chip label={option} {...getTagProps({ index })} />
      </Tooltip>
    ));

  return (
    <div>
      {modalForm && (
        <div>
          <Dialog
            open={selectedNode !== null}
            aria-labelledby="responsive-dialog-title"
            maxWidth="sm"
            fullWidth
            PaperProps={{
              style: {
                background: "#212B35",
                border: "1px solid #818181",
                boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                color: "#EFF1F1",
                maxHeight: "calc(100% - 122px)",
              },
            }}
          >
            <DialogTitle id="responsive-dialog-title">
              <div>DataCleansing </div>
              <div>
                {detailedMsgTooltipData[selectedNode.data.nodeType] ? (
                  <IconButton>
                    <CustomTooltip
                      title={detailedMsgTooltipData[selectedNode.data.nodeType]}
                    >
                      <QuestionMarkIcon width={25} height={25} />
                    </CustomTooltip>
                  </IconButton>
                ) : (
                  <></>
                )}
                <IconButton onClick={() => modalProps.closeModal(modalType)}>
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent sx={{ paddingTop: "20px !important" }}>
              <FormControl id="name" className="text-field" size="small">
                <TextField
                  type="text"
                  name="name"
                  size="small"
                  label="Name"
                  value={modalForm ? modalForm.name : ""}
                  onChange={(evt) => handleChange(evt)}
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                />
              </FormControl>
              <FormControl id="description" className="text-field" size="small">
                <TextField
                  type="text"
                  name="description"
                  size="small"
                  label="Description"
                  value={modalForm ? modalForm.description : ""}
                  onChange={(evt) => handleChange(evt)}
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                />
              </FormControl>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                className="add-filter-btn-container"
                sx={{ marginTop: "1rem" }}
              >
                <Button
                  variant="contained"
                  onClick={handleAddDataClnCfg}
                  aria-labelledby="row-toggle-button-label"
                  name="filter-toggle"
                  sx={buttonStyles}
                >
                  Add more
                </Button>
              </Stack>
              {dataClnCfgs.map((dataClnCfg, index) => (
                <Stack
                  key={index}
                  direction="row"
                  gap="10px"
                  alignItems="center"
                  className="multi-stat"
                >
                  <Stack gap="20px" sx={{ width: "100%" }}>
                    <FormControl id="dcKey" sx={formControlStyles} size="small">
                      <InputLabel sx={labelStyles}>
                        DataCleansing Type
                      </InputLabel>
                      <Select
                        placeholder="DataCleansing Type"
                        name="dcKey"
                        label="DataCleansing Type"
                        value={dataClnCfg.dcKey}
                        onChange={(evt) => handleDataClnCfgChange(evt, index)}
                        required
                        size="small"
                        sx={selectStyles}
                        inputProps={{ sx: selectStyles }}
                        MenuProps={{ sx: selectStyles }}
                        autoWidth={true}
                      >
                        {dataCleansingOptions.map((option, optionIndex) => (
                          <MenuItem
                            key={optionIndex}
                            value={option.dckey}
                            sx={menuItemStyles}
                          >
                            <Tooltip title={option.name} placement="right">
                              <Typography noWrap>{option.name}</Typography>
                            </Tooltip>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Autocomplete
                      key="column"
                      multiple
                      autoHighlight
                      size="small"
                      id="column"
                      options={filteredAllColumns}
                      getOptionLabel={(option) => getOptionLabel(option)}
                      filterSelectedOptions
                      disableCloseOnSelect
                      className="chips-dropdown mt-2"
                      ListboxProps={{
                        style: { maxHeight: 300, overflow: "auto" },
                      }}
                      isOptionEqualToValue={(option, value) => {
                        const options = allColumns;
                        return isOptionEqualToValue(option, value, options);
                      }}
                      value={getChipDropdownValue(dataClnCfg, "column")}
                      onChange={(e, value) =>
                        handleChipsChange(e, value, index)
                      }
                      renderTags={renderTags}
                      groupBy={(option) => option.title}
                      renderGroup={(params) => (
                        <li key={params.key}>
                          <GroupHeader>{params.group}</GroupHeader>
                          <GroupItems>{params.children}</GroupItems>
                        </li>
                      )}
                      componentsProps={{
                        paper: {
                          sx: {
                            backgroundColor: "#212b35",
                            color: "#d9d9d9",
                            borderRadius: "4px",
                            boxShadow:
                              "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                            "li:hover": {
                              color: "#69c2d2",
                            },
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Comma separated column names"
                          placeholder="Comma separated column names"
                          autoComplete="off"
                          sx={chipTextFieldStyles}
                          inputRef={(input) => {
                            if (input) {
                              autoCompleteRef[input.id] = input;
                            }
                          }}
                        />
                      )}
                      filterOptions={(options, { inputValue }) =>
                        filterMultiSelectOptions(options, inputValue, "column")
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Tooltip
                            title={getOptionLabel(option)}
                            placement="right"
                          >
                            <Typography noWrap>
                              {getOptionLabel(option)}
                            </Typography>
                          </Tooltip>
                        </li>
                      )}
                    />
                    {dataClnCfg.input === "yes" && (
                      <FormControl
                        id="inputVal"
                        className="text-field"
                        size="small"
                      >
                        <TextField
                          type="text"
                          name="inputVal"
                          size="small"
                          label={dataClnCfg.inputText}
                          value={dataClnCfg.inputVal}
                          onChange={(evt) => handleDataClnCfgChange(evt, index)}
                          variant="outlined"
                          sx={textFieldStyles}
                          autoComplete="off"
                        />
                      </FormControl>
                    )}
                    {dataClnCfg.output === "yes" && (
                      <FormControl
                        id="outputVal"
                        className="text-field"
                        size="small"
                      >
                        <TextField
                          type="text"
                          name="outputVal"
                          size="small"
                          label={dataClnCfg.outputText}
                          value={dataClnCfg.outputVal}
                          onChange={(evt) => handleDataClnCfgChange(evt, index)}
                          variant="outlined"
                          sx={textFieldStyles}
                          autoComplete="off"
                        />
                      </FormControl>
                    )}
                  </Stack>
                  <Tooltip title="Remove" placement="top">
                    <IconButton
                      aria-label="remove"
                      className="remove-icon"
                      onClick={() => handleRemoveDataClnCfg(index)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              ))}
            </DialogContent>
            <DialogActions>
              <Stack direction="row" gap="10px" paddingX="40px" paddingY="20px">
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  autoFocus
                  sx={buttonStyles}
                  disabled={isSaveDisabled || userAccessRestricted}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  onClick={() => modalProps.closeModal(modalType)}
                  autoFocus
                  sx={buttonStyles}
                >
                  Cancel
                </Button>
              </Stack>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={snackbarMsg}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={snackbarMsg}
            action={action}
          />
        </div>
      )}
    </div>
  );
};

export default DataCleansingHandlerNodeDialog;
