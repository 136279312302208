import { Handle, Position } from "reactflow";
import "./customNode.scss";
import { ReactComponent as CompletedIcon } from "../../assets/icons/completed.svg";
const nodeUIJson = require("../../assets/testdata/node-ui.json");

const getHandlePosition = (handle) => {
  switch (handle) {
    case "right":
      return Position.Right;
    case "left":
      return Position.Left;
    case "top":
      return Position.Top;
    case "bottom":
      return Position.Bottom;
    default:
      return Position.Right;
  }
};

const CustomNode = ({ data, id }) => {
  const node = data.nodeType;
  const nodeUI = nodeUIJson[node];
  const handleClasses = {
    rightHandle: "right-handle",
    leftHandle: "left-handle",
    topHandle: "top-handle",
  };
  return (
    <div>
      <CompletedIcon className="completed-icon"></CompletedIcon>
      {nodeUI?.handles.map((handle, index) => (
        <Handle
          key={handle.name + handle.position}
          id={id + index}
          className={handleClasses[handle.name]}
          type={handle.type}
          position={getHandlePosition(handle.position)}
        ></Handle>
      ))}
      <div></div>
    </div>
  );
};

export default CustomNode;
