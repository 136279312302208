import api from "./apiInterceptor";
import store from "./state/store";
import { updateAlertMessage } from "./state/action-creators/index";

let continuePolling;
const PollAPI = async (url, params, interval, timeout, receivedRoute) => {
  var endTime = Number(new Date()) + (timeout || 6 * 60 * 60 * 1000);
  interval = interval * 1000 || 10 * 1000;
  continuePolling = true;
  // Actual polling function
  var pollingFunction = async (resolve, reject) => {
    const currentRoute = window.location.pathname;
    if (receivedRoute !== currentRoute) {
      continuePolling = false;
    }
    if (!continuePolling) {
      return reject({ State: "interrupted" });
    }
    let result = null;
    try {
      let url = localStorage.getItem("BASE_API_URL") + "pollapi";
      result = await callPostAPI(url, params);
      if (!result) {
        return reject({ State: "interrupted" });
      }
      result = result.data;
      if (result.status === 404) {
        if (result?.data?.reason) {
          store.dispatch(updateAlertMessage(result.data.reason));
        }
        return reject({ State: "interrupted" });
      }
    } catch (err) {
      console.log("Exception in polling.\n", err);
      console.log(
        "Error in retrieving updates from server. Please contact adminitrator."
      );
      return reject(err);
    }
    if (!result || typeof result === "undefined") {
      console.log("Null result in polling.");
      return reject(
        new Error(
          "Error in retrieving updates from server. Please contact adminitrator."
        )
      );
    } else if (Number(new Date()) < endTime) {
      try {
        if (result.status === "Success") {
          return resolve(result);
        } else if (result.status === "Failure") {
          if (result.message) {
            store.dispatch(updateAlertMessage(result.message));
            console.log(result.message);
          } else {
            console.log(
              "Received status failed in polling. Please contact adminitrator."
            );
          }
          return reject(new Error(result.message));
        } else if (result.status === "InProgress" && !continuePolling) {
          return reject({ State: "interrupted" });
        }
      } catch (err) {}

      if (continuePolling) {
        const pollTimeoutToken = setTimeout(
          pollingFunction,
          interval,
          resolve,
          reject
        );
      } else {
        continuePolling = true;
        return reject({ State: "interrupted" });
      }
    } else {
      return reject(new Error("timed out"));
    }
  };

  const callPostAPI = async (url, payload) => {
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    try {
      const resp = await api.post(url, payload, { headers: headers });
      return resp;
    } catch (error) {
      console.log(error);
    }
  };

  const pollingResponse = await new Promise(pollingFunction);
  return pollingResponse;
};
export default PollAPI;
