import { TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import "./configureDatasource.scss";
import { textFieldStyles } from "./configureDatasourceStyle";
import FileCfgForm from "components/fileCfgForm/fileCfgForm";
import ApiCfgForm from "components/apiCfgForm/apiCfgForm";
import DbCfgForm from "components/dbCfgForm/dbCfgForm";
import PipelineCfgForm from "components/pipelineCfgForm/pipelineCfgForm";

const ConfigureDatasource = ({
  selectedDatasource,
  currentCfgInfo,
  dataMapInfo,
  sourceType,
  updateSourceType,
  updateSaveDisabling,
  uploadedFile,
  isBodyParam,
  isLoginBodyParam,
  setIsLoginBodyParam,
  setIsBodyParam,
}) => {
  const sourceTypeOptions = [
    {
      name: "File",
      value: "file",
    },
    {
      name: "API",
      value: "api",
    },
    {
      name: "Database",
      value: "db",
    },
    {
      name: "Pipeline",
      value: "pipeline",
    },
  ];

  const [allowOtherProject, setAllowOtherProject] = useState(false);
  const fileConfigs = useSelector((state) => state.fileConfigs);
  const databaseConfigs = useSelector((state) => state.databaseConfigs);
  const pipelineConfigs = useSelector((state) => state.pipelineConfigs);
  const apiConfigs = useSelector((state) => state.apiConfigs);
  const dispatch = useDispatch();
  const {
    updateFileConfigs,
    updateApiConfigs,
    updateDatabaseConfigs,
    updatePipelineConfigs,
  } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    initializeCfgs();
  }, []);

  useEffect(() => {
    handleSaveDisabling();
  }, [sourceType, fileConfigs, apiConfigs, databaseConfigs, pipelineConfigs]);

  const getSaveButtonStatusForFile = () => {
    if (fileConfigs.fileName === "" || !fileConfigs.dataFormat) {
      return true;
    }
    if (fileConfigs.hostType === "local") {
      if (!fileConfigs.file) {
        return true;
      }
    } else {
      if (!fileConfigs.storageType) {
        return true;
      }
      if (
        (fileConfigs.storageType === "s3" ||
          fileConfigs.storageType === "azure" ||
          fileConfigs.storageType === "bigquery") &&
        (!fileConfigs.secretKey ||
          !fileConfigs.accessKey ||
          !fileConfigs.authToken)
      ) {
        return true;
      }
      if (
        fileConfigs.storageType === "sftp" &&
        (!fileConfigs.host || !fileConfigs.username || !fileConfigs.password)
      ) {
        return true;
      }
      if (fileConfigs.storageType === "adls") {
        if (
          !fileConfigs.storageAccountName ||
          !fileConfigs.clientId ||
          !fileConfigs.tenantId ||
          !fileConfigs.secretKey
        )
          return true;
        if (
          fileConfigs.pathType === "datapath" &&
          (!fileConfigs.containerName || !fileConfigs.dataPath)
        )
          return true;
        if (fileConfigs.pathType === "url" && !fileConfigs.dataPath)
          return true;
      }
      if (!fileConfigs.dataPath) {
        return true;
      }
    }
    return false;
  };

  const getSaveButtonStatusForApi = () => {
    if (apiConfigs.name === "" || apiConfigs.subType === "") {
      return true;
    }
    if (apiConfigs.subType === "outgoing") {
      if (apiConfigs.loginRequired === "yes") {
        if (
          apiConfigs.loginAPI.url === "" ||
          apiConfigs.loginAPI.method === "" ||
          apiConfigs.loginAPI.authentication === ""
        ) {
          return true;
        }
        for (let loginApiParam of apiConfigs.loginAPI.params) {
          if (loginApiParam.name === "" || loginApiParam.source === "") {
            return true;
          } else if (
            loginApiParam.source === "constant" &&
            loginApiParam.constant === ""
          ) {
            return true;
          }
        }
        for (let loginHeaderParam of apiConfigs.loginAPI.headers) {
          if (loginHeaderParam.name === "" || loginHeaderParam.value === "") {
            return true;
          }
        }
        if (apiConfigs.loginAPI.method === "post") {
          if (apiConfigs.loginAPI.bodyParams.length) {
            for (let loginBodyParam of apiConfigs.loginAPI.bodyParams) {
              if (loginBodyParam.name === "" || loginBodyParam.source === "") {
                return true;
              } else if (
                loginBodyParam.source === "constant" &&
                loginBodyParam.constant === ""
              ) {
                return true;
              }
            }
          }
        }
      }
      if (
        apiConfigs.dataAPI.url === "" ||
        apiConfigs.dataAPI.method === "" ||
        apiConfigs.dataAPI.respKey === ""
      ) {
        return true;
      }
      for (let apiParam of apiConfigs.dataAPI.params) {
        if (apiParam.name === "" || apiParam.source === "") {
          return true;
        } else if (apiParam.source === "constant" && apiParam.constant === "") {
          return true;
        }
      }
      for (let headerParam of apiConfigs.dataAPI.headers) {
        if (headerParam.name === "" || headerParam.value === "") {
          return true;
        }
      }
      if (apiConfigs.dataAPI.method === "post") {
        if (apiConfigs.dataAPI.bodyParams.length) {
          for (let bodyParam of apiConfigs.dataAPI.bodyParams) {
            if (bodyParam.name === "" || bodyParam.source === "") {
              return true;
            } else if (
              bodyParam.source === "constant" &&
              bodyParam.constant === ""
            ) {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  const getSaveButtonStatusForDb = () => {
    if (
      databaseConfigs.name === "" ||
      databaseConfigs.dbType === "" ||
      databaseConfigs.dbTable === "" ||
      (databaseConfigs.dbType !== "bigquery" &&
        (databaseConfigs.name === "" ||
          databaseConfigs.dbHost === "" ||
          databaseConfigs.dbPort === "" ||
          databaseConfigs.dbName === "" ||
          databaseConfigs.dbUser === "" ||
          databaseConfigs.dbPswd === "")) ||
      databaseConfigs.rowSelInfo === "" ||
      (databaseConfigs.dbType === "bigquery" &&
        ((databaseConfigs.files === "" &&
          databaseConfigs.authType === "servacc") ||
          databaseConfigs.parentProject === "" ||
          databaseConfigs.project === "" ||
          databaseConfigs.dataset === ""))
    ) {
      return true;
    }
    if (databaseConfigs.rowSelInfo === "duration") {
      if (
        databaseConfigs.dateCol === "" ||
        databaseConfigs.dateFormat === "" ||
        databaseConfigs.durationInfo === ""
      ) {
        return true;
      }
      if (databaseConfigs.durationInfo === "range") {
        if (
          databaseConfigs.startDate === "" ||
          databaseConfigs.dateFormat === ""
        ) {
          return true;
        }
      } else {
        if (
          databaseConfigs.duration === "" ||
          databaseConfigs.offsetUnit === ""
        ) {
          return true;
        }
      }
    }
    if (
      databaseConfigs.rowSelInfo === "query" &&
      databaseConfigs.query === ""
    ) {
      return true;
    }
    if (databaseConfigs.rowSelInfo === "identifier") {
      const identifierList = databaseConfigs.idList.split(",");
      const hasEmptyString = identifierList.some((identifier) => !identifier);
      if (hasEmptyString || databaseConfigs.idList === "") {
        return true;
      }
    }
    if (
      databaseConfigs.rowSelInfo === "all" ||
      databaseConfigs.rowSelInfo === "duration"
    ) {
      if (databaseConfigs.colSelInfo === "") {
        return true;
      }
      if (databaseConfigs.colSelInfo === "partial") {
        let hasEmptyString = false;
        if (Array.isArray(databaseConfigs["colList"])) {
          hasEmptyString = databaseConfigs["colList"].some(
            (element) => element.length === 0
          );
        }
        if (hasEmptyString || databaseConfigs.colList === "") {
          return true;
        }
      }
    }
    return false;
  };

  const getSaveButtonStatusForPipeline = () => {
    if (
      !pipelineConfigs.name ||
      !pipelineConfigs.sourceProjVersion ||
      !pipelineConfigs.sourceDs
    ) {
      return true;
    }
    if (allowOtherProject && !pipelineConfigs.sourceProjectKey) {
      return true;
    }
    return false;
  };

  const handleSaveDisabling = () => {
    let isDisabled = true;
    if (sourceType === "file" && fileConfigs) {
      isDisabled = getSaveButtonStatusForFile();
    } else if (sourceType === "api" && apiConfigs) {
      isDisabled = getSaveButtonStatusForApi();
    } else if (sourceType === "db" && databaseConfigs) {
      isDisabled = getSaveButtonStatusForDb();
    } else if (sourceType === "pipeline" && pipelineConfigs) {
      isDisabled = getSaveButtonStatusForPipeline();
    }
    updateSaveDisabling(isDisabled);
  };

  const initializeFileCfgs = () => {
    let clonedFileConfigs = structuredClone(fileConfigs);
    clonedFileConfigs = {
      sourceType: "file",
      hostType: "local",
      fileName: "",
      file: "",
      dataFormat: "",
      storageType: "",
      secretKey: "",
      accessKey: "",
      authToken: "",
      dataPath: "",
      host: "",
      username: "",
      password: "",
      storageAccountName: "",
      clientId: "",
      tenantId: "",
      pathType: "url",
      containerName: "",
    };
    if (sourceType === "file") {
      const cfgInfo = structuredClone(currentCfgInfo);
      for (let cfg of Object.keys(cfgInfo)) {
        clonedFileConfigs[cfg] = cfgInfo[cfg];
      }
    }
    updateFileConfigs(clonedFileConfigs);
  };

  const intiializeDbCfgs = () => {
    let clonedDbConfigs = structuredClone(databaseConfigs);
    clonedDbConfigs = {
      name: "",
      description: "",
      dbType: "",
      dbHost: "",
      dbPort: "",
      dbName: "",
      authentication: null,
      authType: "none",
      dbTable: "",
      dbUser: "",
      dbPswd: "",
      rowSelInfo: "",
      dateCol: "",
      dateFormat: "",
      durationInfo: "",
      startDate: "",
      endDate: "",
      duration: "",
      offsetUnit: "",
      query: "",
      colSelInfo: "",
      colList: "",
      idList: "",
      sourceType: "db",
      fileName: "",
      files: "",
      parentProject: "",
      project: "",
      dataset: "",
    };
    if (sourceType === "db") {
      const cfgInfo = structuredClone(currentCfgInfo);
      for (let cfg of Object.keys(cfgInfo)) {
        if (cfg === "idList" && cfgInfo.rowSelInfo === "identifier") {
          if (Array.isArray(cfgInfo.idList) && cfgInfo.idList.length > 0) {
            clonedDbConfigs[cfg] = cfgInfo.idList.join(",");
          } else {
            clonedDbConfigs[cfg] = "";
          }
        } else {
          clonedDbConfigs[cfg] = cfgInfo[cfg];
        }
      }
    }
    updateDatabaseConfigs(clonedDbConfigs);
  };
  const intiializePipelineCfgs = () => {
    let clonedPipelineConfigs = structuredClone(pipelineConfigs);
    clonedPipelineConfigs = {
      name: "",
      description: "",
      sourceProjectKey: "",
      sourceProjVersion: "",
      sourceProjFg: "base",
      sourceDs: "",
      sourceType: "pipeline",
    };
    if (sourceType === "pipeline") {
      const cfgInfo = structuredClone(currentCfgInfo);
      for (let cfg of Object.keys(cfgInfo)) {
        clonedPipelineConfigs[cfg] = cfgInfo[cfg];
      }
    }
    updatePipelineConfigs(clonedPipelineConfigs);
  };

  const initializeApiCfgs = () => {
    let clonedApiConfigs = structuredClone(apiConfigs);
    clonedApiConfigs = {
      name: "",
      sourceType: "api",
      subType: "",
      loginRequired: "no",
      loginAPI: {
        url: "",
        method: "",
        params: [],
        headers: [],
        body: "",
        authentication: "",
        bodyParams: [],
      },
      dataAPI: {
        url: "",
        method: "",
        params: [],
        headers: [],
        respKey: "",
        dataKey: "",
        body: "",
        bodyParams: [],
      },
    };
    if (sourceType === "api") {
      const cfgInfo = getUpdatedAPICfg();
      for (let cfg of Object.keys(cfgInfo)) {
        clonedApiConfigs[cfg] = cfgInfo[cfg];
      }
    }
    updateApiConfigs(clonedApiConfigs);
    initializeAPIParams(clonedApiConfigs);
  };

  const initializeAPIParams = (configs) => {
    const newLoginBodyParamsArray = structuredClone(
      configs.loginAPI.bodyParams
    );
    const newBodyParamsArray = structuredClone(configs.dataAPI.bodyParams);
    if (newBodyParamsArray.length) {
      setIsBodyParam("yes");
    } else {
      setIsBodyParam("no");
    }
    if (newLoginBodyParamsArray.length) {
      setIsLoginBodyParam("yes");
    } else {
      setIsLoginBodyParam("no");
    }
  };

  const getUpdatedAPICfg = () => {
    const cfgInfo = structuredClone(currentCfgInfo);
    if (cfgInfo.subType === "incoming") {
      setIncomingConfigurations(cfgInfo);
    } else {
      if (cfgInfo.loginRequired === "yes") {
        cfgInfo.loginAPI.body = JSON.stringify(cfgInfo.loginAPI.body);
      } else {
        cfgInfo.loginAPI = {
          url: "",
          method: "",
          params: [],
          headers: [],
          body: "",
          authentication: "",
          bodyParams: [],
        };
      }
      cfgInfo.dataAPI.body = JSON.stringify(cfgInfo.dataAPI.body);
    }
    return cfgInfo;
  };

  const setIncomingConfigurations = (configs) => {
    configs["loginRequired"] = "no";
    configs["loginAPI"] = {
      url: "",
      method: "",
      params: [],
      headers: [],
      body: "",
      authentication: "",
      bodyParams: [],
    };
    configs["dataAPI"] = {
      url: "",
      method: "",
      params: [],
      headers: [],
      respKey: "",
      dataKey: "",
      body: "",
      bodyParams: [],
    };
  };

  const initializeCfgs = () => {
    initializeFileCfgs();
    intiializeDbCfgs();
    initializeApiCfgs();
    intiializePipelineCfgs();
  };

  const isOptionEqualToValue = (option, value, options) => {
    if (options[0]?.name) {
      options = options.map((opt) => opt.name);
    } else if (options[0]?.value) {
      options = options.map((opt) => opt.value);
    }
    if (options.includes(value)) {
      if (typeof option === "object") {
        return (
          option.value === value ||
          option.item === value ||
          option.name === value ||
          value === ""
        );
      } else {
        return option === value || value === "";
      }
    }
    return value === "";
  };

  const getNameFromValue = (value, options) => {
    const option = options.find((option) => option.value === value);
    return option ? option.name : value;
  };

  const renderTags = (value, getTagProps) =>
    value.map((option, index) => (
      <Tooltip key={option} title={option} placement="top">
        <Chip label={option} {...getTagProps({ index })} />
      </Tooltip>
    ));

  const getOptionLabel = (option) => {
    if (typeof option === "object") {
      if (option.name) {
        return option.name;
      } else if (option.item) {
        return option.item;
      }
      return option.value;
    }
    return option;
  };

  return (
    <form className="configure-dataSource-form">
      <Stack spacing={4} alignItems="stretch">
        <Autocomplete
          key={"sourceType"}
          autoHighlight
          size="small"
          id="tags-outlined"
          options={sourceTypeOptions}
          getOptionLabel={(option) => getOptionLabel(option)}
          autoComplete
          includeInputInList
          disableClearable
          className="select-dropdown"
          ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
          isOptionEqualToValue={(option, value) => {
            return isOptionEqualToValue(option, value, sourceTypeOptions);
          }}
          value={
            sourceType ? getNameFromValue(sourceType, sourceTypeOptions) : ""
          }
          onChange={(e, value) => {
            updateSourceType(value.value);
          }}
          renderTags={renderTags}
          componentsProps={{
            paper: {
              sx: {
                backgroundColor: "#212b35",
                color: "#d9d9d9",
                borderRadius: "4px",
                boxShadow:
                  "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                "li:hover": {
                  color: "#69c2d2",
                },
              },
            },
          }}
          renderInput={(params) => (
            <Tooltip
              title={getNameFromValue(sourceType, sourceTypeOptions)}
              placement="right"
            >
              <TextField
                {...params}
                size="small"
                label={"Source type"}
                placeholder="Select option"
                autoComplete="off"
                sx={textFieldStyles}
              />
            </Tooltip>
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <Tooltip title={option.name} placement="right">
                <Typography noWrap>{option.name}</Typography>
              </Tooltip>
            </li>
          )}
        />
        {sourceType === "file" && fileConfigs ? (
          <FileCfgForm uploadedFile={uploadedFile}></FileCfgForm>
        ) : sourceType === "api" && apiConfigs ? (
          <ApiCfgForm
            isBodyParam={isBodyParam}
            isLoginBodyParam={isLoginBodyParam}
            setIsLoginBodyParam={setIsLoginBodyParam}
            setIsBodyParam={setIsBodyParam}
          ></ApiCfgForm>
        ) : sourceType === "db" && databaseConfigs ? (
          <DbCfgForm uploadedFile={uploadedFile}></DbCfgForm>
        ) : sourceType === "pipeline" && pipelineConfigs ? (
          <PipelineCfgForm
            setAllowOtherProject={setAllowOtherProject}
            allowOtherProject={allowOtherProject}
          ></PipelineCfgForm>
        ) : (
          <></>
        )}
      </Stack>
    </form>
  );
};

export default ConfigureDatasource;
