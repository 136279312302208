import { React, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import "./importConfigDialog.scss";
import api from "../../apiInterceptor";
import { buttonStyles, textFieldStyles } from "./importConfigDialogStyle";
import { showSuccessAlert } from "utils/utils";
const listprojectsAPITestData = require("../../assets/apiTestData/listprojects-test-data.json");
const importprojAPITestData = require("../../assets/apiTestData/importproj-test-data.json");
const getfecfgAPITestData = require("../../assets/apiTestData/getfecfg-test-data.json");

const ImportConfigDialog = ({ restoreUI }) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  let IS_LIST_PROJECTS_GET_TYPE = localStorage.getItem(
    "IS_LIST_PROJECTS_GET_TYPE"
  );
  IS_LIST_PROJECTS_GET_TYPE =
    IS_LIST_PROJECTS_GET_TYPE === "true" || IS_LIST_PROJECTS_GET_TYPE === true
      ? true
      : false;
  const [projectKey, setProjectKey] = useState("");
  const [projectVersion, setProjectVersion] = useState("");
  const [allProjects, setAllProjects] = useState([]);
  const [allProjectsInfo, setAllProjectsInfo] = useState([]);
  const [versionsToShow, setVersionsToShow] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const showImportConfigDialog = useSelector(
    (state) => state.showImportConfigDialog
  );
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedProjFg = useSelector((state) => state.selectedFeatureGroup);
  const dispatch = useDispatch();
  const {
    updateShowImportConfigDialog,
    updateIsLoading,
    updateAlertMessage,
    updateSuccessAlertMessage,
  } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    if (projectKey && projectVersion) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [projectKey, projectVersion]);

  useEffect(() => {
    getProjects();
  }, []);

  const prepareProjectsDropdown = (projects) => {
    let projectsDropdownList = [];
    projects.forEach((project) => {
      let projObj = {
        key: project.projectKey,
        name: project.pname,
      };
      projectsDropdownList.push(projObj);
    });
    setAllProjects(projectsDropdownList);
  };

  const getProjects = async () => {
    try {
      const apiUrl = BASE_API_URL + "listprojects";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {};
      updateIsLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: listprojectsAPITestData,
        };
      } else {
        if (IS_LIST_PROJECTS_GET_TYPE) {
          response = await api.get(apiUrl, {
            headers: headers,
          });
        } else {
          response = await api.post(apiUrl, payload, { headers: headers });
        }
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        setAllProjectsInfo(response.data.data.posts[0]);
        prepareProjectsDropdown(response.data.data.posts[0]);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const handleClose = () => {
    updateShowImportConfigDialog(false);
  };

  const getImportStatus = async (importedPipeline) => {
    const apiUrl = BASE_API_URL + "importproj";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = {
      projectKey: selectedProjectKey,
      projVersion: selectedProjVersion,
      projFg: "base",
      baseProjectInfo: {
        projectKey: projectKey,
        projVersion: projectVersion,
        projFg: selectedProjFg,
      },
    };
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: importprojAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        handleClose();
        let result = response.data.data.posts[0];
        restoreUI(importedPipeline, true, result.uploadReqd);
        const msg = "Configurations imported!";
        showSuccessAlert(msg, updateSuccessAlertMessage);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const importConfigurations = async () => {
    const apiUrl = BASE_API_URL + "getfecfg";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = {
      projectKey: projectKey,
      cfgProjectKey: projectKey,
      projVersion: projectVersion,
    };
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: getfecfgAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        let importedPipeline = response.data.data.posts[0];
        if (importedPipeline) {
          getImportStatus(importedPipeline);
        }
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const handleProjectChange = (e, value) => {
    const projKey = value.key;
    let versions = [];
    versions = getProjectObject(projKey).versionInfo;
    if (projKey === selectedProjectKey) {
      versions = versions.filter(
        (version) => version.vname !== selectedProjVersion
      );
    }
    setProjectKey(projKey);
    setProjectVersionToEmptyIfNotFound(versions);
    setVersionsToShow(versions);
  };

  const setProjectVersionToEmptyIfNotFound = (verisons) => {
    for (let i = 0; i < verisons.length; i++) {
      if (verisons[i].vname === projectVersion) {
        return;
      }
    }
    setProjectVersion("");
  };

  const getProjectObject = (pKey, projects = allProjectsInfo) => {
    return projects.find((obj) => obj.projectKey === pKey);
  };

  const handleVersionChange = (e, value) => {
    setProjectVersion(value.vname);
  };

  const getOptionLabel = (option) => {
    if (typeof option === "object") {
      return option.vname;
    } else {
      return option;
    }
  };

  return (
    <Dialog
      fullWidth
      open={showImportConfigDialog}
      aria-labelledby="Import Configurations"
      className="import-config-dialog"
      PaperProps={{
        style: {
          background: "#212B35",
          border: "1px solid #818181",
          boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          color: "#EFF1F1",
          maxHeight: "calc(100% - 122px)",
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title">
        {"Import Configurations"}
        <IconButton
          aria-label="close"
          className="close-icon"
          onClick={handleClose}
        >
          <CloseIcon></CloseIcon>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form>
          <Stack>
            <Autocomplete
              autoHighlight
              size="small"
              id="tags-outlined"
              options={allProjects}
              getOptionLabel={(option) => option.name}
              autoComplete
              includeInputInList
              disableClearable
              className="select-dropdown"
              ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name || value === ""
              }
              onChange={(e, value) => {
                handleProjectChange(e, value);
              }}
              componentsProps={{
                paper: {
                  sx: {
                    backgroundColor: "#212b35",
                    color: "#d9d9d9",
                    borderRadius: "4px",
                    boxShadow:
                      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                    "li:hover": {
                      color: "#69c2d2",
                    },
                  },
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Project Name"
                  placeholder="Select option"
                  autoComplete="off"
                  sx={textFieldStyles}
                />
              )}
            />
            <Autocomplete
              autoHighlight
              size="small"
              id="tags-outlined"
              options={versionsToShow}
              getOptionLabel={(option) => getOptionLabel(option)}
              autoComplete
              includeInputInList
              disableClearable
              className="select-dropdown"
              ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
              isOptionEqualToValue={(option, value) =>
                option.vname === value || value === ""
              }
              value={projectVersion}
              onChange={(e, value) => {
                handleVersionChange(e, value);
              }}
              componentsProps={{
                paper: {
                  sx: {
                    backgroundColor: "#212b35",
                    color: "#d9d9d9",
                    borderRadius: "4px",
                    boxShadow:
                      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                    "li:hover": {
                      color: "#69c2d2",
                    },
                  },
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Project Version"
                  placeholder="Select option"
                  autoComplete="off"
                  sx={textFieldStyles}
                />
              )}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          sx={buttonStyles}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={buttonStyles}
          onClick={importConfigurations}
          disabled={isSaveDisabled}
          autoFocus
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportConfigDialog;
