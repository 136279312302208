import { configureStore } from "@reduxjs/toolkit";
import modalFormReducer from "./reducers/modalFormReducer";
import tableReducer from "./reducers/tableReducer";
import currentFormTypeReducer from "./reducers/currentFormTypeReducer";
import modalUIReducer from "./reducers/modalUIReducer";
import nodeConfigurationsReducer from "./reducers/nodeConfigurationsReducer";
import pipelineReducer from "./reducers/pipelineReducer";
import openDeleteDialogReducer from "./reducers/openDeleteDialogReducer";
import openSummaryDialogReducer from "./reducers/openSummaryDialogReducer";
import openSetUpNewJobDialogReducer from "./reducers/openSetUpNewJobDialogReducer";
import openViewRunningJobsDialogReducer from "./reducers/openViewRunningJobsDialogReducer";
import visitedstageNodesReducer from "./reducers/visitedStageNodesReducer";
import errorNodesReducer from "./reducers/errorNodesReducer";
import showCreationDialogReducer from "./reducers/showCreationDialogReducer";
import LoadingReducer from "./reducers/LoadingReducer";
import playgroundLoadingReducer from "./reducers/playgroundLoadingReducer";
import showPollingLoaderRedcer from "./reducers/showPollingLoaderRedcer";
import alertMessageReducer from "./reducers/alertMessageReducer";
import projectKeyReducer from "./reducers/projectKeyReducer";
import currentProjectStatusReducer from "./reducers/currentProjectStatusReducer";
import filterCfgReducer from "./reducers/filterCfgreducer";
import showImportConfigDialogReducer from "./reducers/showImportConfigDialogReducer";
import pollingReducer from "./reducers/pollingReducer";
import statementNamesReducer from "./reducers/statementNamesReducer";
import isAuthenticatedReducer from "./reducers/AuthenticationReducer";
import projVersionReducer from "./reducers/projVersionReducer";
import featureGroupReducer from "./reducers/featureGroupReducer";
import openConfigureVariablesDialogReducer from "./reducers/openConfigureVariablesDialogReducer";
import memoryUsageInfoReducer from "./reducers/memoryUsageInfoReducer";
import cpuUsageInfoReducer from "./reducers/cpuUsageInfoReducer";
import nodesErrorMsgReducer from "./reducers/nodesErrorMsgReducer";
import shortMsgTooltipDataReducer from "./reducers/shortMsgTooltipDataReducer";
import detailedMsgTooltipDataReducer from "./reducers/detailedMsgTooltipDataReducer";
import validationErrorNodesReducer from "./reducers/validationErrorNodesReducer";
import openErrorDialogReducer from "./reducers/openErrorDialogReducer";
import openAlertErrorDialogDialogReducer from "./reducers/openAlertErrorDialogDialogReducer";
import showBackupDialogReducer from "./reducers/showBackupDialogReducer";
import showRestoreDialogReducer from "./reducers/showRestoreDialogReducer";
import snackbarMsgReducer from "./reducers/snackbarMsgReducer";
import successAlertMessageReducer from "./reducers/successAlertMessageReducer";
import modellingNodesDataReducer from "./reducers/modellingNodesDataReducer";
import pivotStatCfgReducer from "./reducers/pivotStatCfgReducer";
import showPipelineExecutionDialogReducer from "./reducers/showPipelineExecutionDialogReducer";
import currentProjNameReducer from "./reducers/currentProjNameReducer";
import currentFgDescReducer from "./reducers/currentFgDescReducer";
import enableCopyModeReducer from "./reducers/enableCopyModeReducer";
import copyNodesListReducer from "./reducers/copyNodesListReducer";
import showPartialExecutionDialogReducer from "./reducers/showPartialExecutionDialogReducer";
import startDsReducer from "./reducers/startDsReducer";
import collapsedDsReducer from "./reducers/collpasedDsReducer";
import globalCollapseReducer from "./reducers/globalCollapseReducer";
import infoAlertMessageReducer from "./reducers/infoAlertMessageReducer";
import showDatasourcesDialogReducer from "./reducers/showDatasourcesDialogReducer";
import fileConfigsReducer from "./reducers/fileConfigsReducer";
import apiConfigsReducer from "./reducers/apiConfigsReducer";
import databaseConfigsReducer from "./reducers/databaseConfigsReducer";
import pipelineConfigsReducer from "./reducers/pipelineConfigsReducer";
import exploredProjectReducer from "./reducers/exploredProjectsReducer";
import showProjectInfoDialogReducer from "./reducers/showProjectInfoDialogReducer";

const store = configureStore({
  reducer: {
    table: tableReducer,
    modalForm: modalFormReducer,
    currentFormType: currentFormTypeReducer,
    modalUI: modalUIReducer,
    nodeConfigurations: nodeConfigurationsReducer,
    pipeline: pipelineReducer,
    isDeleteDialogOpen: openDeleteDialogReducer,
    isConfigureVariablesDialogOpen: openConfigureVariablesDialogReducer,
    isSummaryDialogOpen: openSummaryDialogReducer,
    isSetUpNewJobDialogOpen: openSetUpNewJobDialogReducer,
    isViewRunningJobsDialogOpen: openViewRunningJobsDialogReducer,
    visitedStageNodes: visitedstageNodesReducer,
    errorNodes: errorNodesReducer,
    showCreationDialog: showCreationDialogReducer,
    isLoading: LoadingReducer,
    isPlaygroundLoading: playgroundLoadingReducer,
    showPollingLoader: showPollingLoaderRedcer,
    alertMessage: alertMessageReducer,
    selectedProjectKey: projectKeyReducer,
    selectedProjVersion: projVersionReducer,
    selectedFeatureGroup: featureGroupReducer,
    currentProjectStatus: currentProjectStatusReducer,
    filterCfg: filterCfgReducer,
    showImportConfigDialog: showImportConfigDialogReducer,
    isPolling: pollingReducer,
    statementNames: statementNamesReducer,
    isAuthenticated: isAuthenticatedReducer,
    cpuUsageInfo: cpuUsageInfoReducer,
    memoryUsageInfo: memoryUsageInfoReducer,
    nodesErrorMsg: nodesErrorMsgReducer,
    shortMsgTooltipData: shortMsgTooltipDataReducer,
    detailedMsgTooltipData: detailedMsgTooltipDataReducer,
    validationErrorNodes: validationErrorNodesReducer,
    isErrorDialogOpen: openErrorDialogReducer,
    isAlertErrorDialogOpen: openAlertErrorDialogDialogReducer,
    showBackupDialog: showBackupDialogReducer,
    showRestoreDialog: showRestoreDialogReducer,
    snackbarMsg: snackbarMsgReducer,
    successAlertMessage: successAlertMessageReducer,
    infoAlertMessage: infoAlertMessageReducer,
    modellingNodesData: modellingNodesDataReducer,
    pivotStatCfg: pivotStatCfgReducer,
    showPipelineExecutionDialog: showPipelineExecutionDialogReducer,
    currentProjName: currentProjNameReducer,
    currentFgDesc: currentFgDescReducer,
    enableCopyMode: enableCopyModeReducer,
    copyNodesList: copyNodesListReducer,
    showPartialExecutionDialog: showPartialExecutionDialogReducer,
    startDs: startDsReducer,
    collapsedDs: collapsedDsReducer,
    globalCollapseEnabled: globalCollapseReducer,
    showDatasourcesDialog: showDatasourcesDialogReducer,
    fileConfigs: fileConfigsReducer,
    apiConfigs: apiConfigsReducer,
    databaseConfigs: databaseConfigsReducer,
    pipelineConfigs: pipelineConfigsReducer,
    exploredProjectInfo: exploredProjectReducer,
    showProjectInfoDialog: showProjectInfoDialogReducer,
  },
});
export default store;
