export const nodeSections = [
  { id: "import_data", label: "Import Data" },
  { id: "data_operations", label: "Data Operations" },
  { id: "feature_engineering", label: "Feature Engineering" },
  { id: "feature_recipe", label: "Feature Recipe" },
  { id: "code_recipe", label: "Code Recipe" },
  { id: "fs_recipe", label: "Feature Store Recipe" },
  { id: "modelling", label: "Modelling" },
];

export const nodeList = [
  {
    nodeType: "fileUpload",
    nodeClass: "csv-upload",
    modalType: "customModal",
    nodeKind: "add",
    isStage: false,
    allowOneIncomingEdge: false,
    icon: "csvFileIcon.svg",
    label: "File",
    isDraggable: true,
    section: "import_data",
  },
  {
    nodeType: "database",
    nodeClass: "database",
    modalType: "customModal",
    nodeKind: "add",
    isStage: false,
    allowOneIncomingEdge: false,
    icon: "databaseIcon.svg",
    label: "Database",
    isDraggable: true,
    section: "import_data",
  },
  {
    nodeType: "api",
    nodeClass: "api",
    modalType: "apiDialog",
    nodeKind: "add",
    isStage: false,
    allowOneIncomingEdge: false,
    icon: "apiNodeIcon.svg",
    label: "API",
    isDraggable: true,
    section: "import_data",
  },
  {
    nodeType: "pipeline",
    nodeClass: "pipeline",
    modalType: "pipelineDialog",
    nodeKind: "pipeline",
    isStage: false,
    allowOneIncomingEdge: false,
    icon: "pipelineIcon.svg",
    label: "Pipeline",
    isDraggable: true,
    section: "import_data",
  },
  {
    nodeType: "join",
    nodeClass: "join",
    modalType: "customModal",
    nodeKind: "join",
    isStage: false,
    allowOneIncomingEdge: false,
    icon: "joinIcon.svg",
    label: "Join",
    isDraggable: true,
    section: "data_operations",
  },
  {
    nodeType: "stack",
    nodeClass: "stack",
    modalType: "customModal",
    nodeKind: "stack",
    isStage: false,
    allowOneIncomingEdge: false,
    icon: "stackIcon.svg",
    label: "Stack",
    isDraggable: true,
    section: "data_operations",
  },
  {
    nodeType: "multiStack",
    nodeClass: "multi-stack",
    modalType: "customModal",
    nodeKind: "stack-multi-dur",
    isStage: false,
    allowOneIncomingEdge: false,
    icon: "multiStackIcon.svg",
    label: "Multi Stack",
    isDraggable: true,
    section: "data_operations",
  },
  {
    nodeType: "filter",
    nodeClass: "filter",
    modalType: "filterDialog",
    nodeKind: "filter",
    isStage: false,
    allowOneIncomingEdge: true,
    icon: "filterIcon.svg",
    label: "Filter",
    isDraggable: true,
    section: "data_operations",
  },
  {
    nodeType: "pivot",
    nodeClass: "pivot",
    modalType: "customModal",
    nodeKind: "pivot",
    isStage: false,
    allowOneIncomingEdge: false,
    icon: "pivotIcon.svg",
    label: "Pivot",
    isDraggable: true,
    section: "data_operations",
  },
  {
    nodeType: "clone",
    nodeClass: "clone",
    modalType: "customModal",
    nodeKind: "clone",
    isStage: false,
    allowOneIncomingEdge: true,
    icon: "cloneIcon.svg",
    label: "Clone",
    isDraggable: true,
    section: "data_operations",
  },
  {
    nodeType: "split",
    nodeClass: "split",
    modalType: "customModal",
    nodeKind: "split",
    isStage: false,
    allowOneIncomingEdge: true,
    icon: "splitIcon.svg",
    label: "Split",
    isDraggable: true,
    section: "data_operations",
  },
  {
    nodeType: "explode",
    nodeClass: "explode",
    modalType: "customModal",
    nodeKind: "explode",
    isStage: false,
    allowOneIncomingEdge: true,
    icon: "explodeIcon.svg",
    label: "Explode",
    isDraggable: true,
    section: "data_operations",
  },
  {
    nodeType: "output",
    nodeClass: "output",
    modalType: "exportNodeDialog",
    nodeKind: "exp-data",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "outputIcon.svg",
    label: "Export",
    isDraggable: true,
    section: "data_operations",
  },
  {
    nodeType: "interaction",
    nodeClass: "interaction",
    modalType: "customModal",
    nodeKind: "interaction",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "interactionIcon.svg",
    label: "Interaction",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "ratio",
    nodeClass: "ratio",
    modalType: "customModal",
    nodeKind: "ratio",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "ratioIcon.svg",
    label: "Ratio",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "aggregation",
    nodeClass: "aggregation",
    modalType: "customModal",
    nodeKind: "agg",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "calculatorIcon.svg",
    label: "Aggregate",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "dateOperations",
    nodeClass: "date-operations",
    modalType: "customModal",
    nodeKind: "date-fn",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "dateIcon.svg",
    label: "Date",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "statisticalTransformation",
    nodeClass: "statistical-transformation",
    modalType: "customModal",
    nodeKind: "stat-txn",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "statisticsIcon.svg",
    label: "Stats",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "mathematicalFunctions",
    nodeClass: "mathematical-functions",
    modalType: "customModal",
    nodeKind: "num-arith",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "mathematicalFunctionsIcon.svg",
    label: "Calc",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "rename",
    nodeClass: "rename",
    modalType: "customModal",
    nodeKind: "ren-col",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "renameIcon.svg",
    label: "Rename",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "sorting",
    nodeClass: "sorting",
    modalType: "customModal",
    nodeKind: "sort-cfg",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "sortIcon.svg",
    label: "Sort",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "dropDuplicates",
    nodeClass: "drop-duplicates",
    modalType: "customModal",
    nodeKind: "drop-dup",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "dropDuplicateIcon.svg",
    label: "Drop Duplicates",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "dropFeatures",
    nodeClass: "drop-features",
    modalType: "customModal",
    nodeKind: "drop-features",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "dropFeatureIcon.svg",
    label: "Drop Features",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "missingImputation",
    nodeClass: "missing-imputation",
    modalType: "customModal",
    nodeKind: "missing-imp",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "missingImputationIcon.svg",
    label: "Missing Imputation",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "dataTypeConversion",
    nodeClass: "data-type-conversion",
    modalType: "customModal",
    nodeKind: "type-conv",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "conversionIcon.svg",
    label: "Convert",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "uidAddition",
    nodeClass: "uid-addition",
    modalType: "customModal",
    nodeKind: "uid-add",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "addUidIcon.svg",
    label: "UID",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "fixedValueCreation",
    nodeClass: "fixed-value-creation",
    modalType: "customModal",
    nodeKind: "fix-val",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "fixedValueIcon.svg",
    label: "Fixed Value",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "extractSubstrings",
    nodeClass: "extract-substrings",
    modalType: "customModal",
    nodeKind: "extr-str",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "substringIcon.svg",
    label: "Extract Substrings",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "replaceValues",
    nodeClass: "replace-values",
    modalType: "customModal",
    nodeKind: "rep-val",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "replaceValuesIcon.svg",
    label: "Replace Values",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "windowFeatures",
    nodeClass: "window-features",
    modalType: "multiFilterDialog",
    nodeKind: "window-features",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "windowFeaturesIcon.svg",
    label: "Window Features",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "binaryFeatures",
    nodeClass: "binary-features",
    modalType: "multiFilterDialog",
    nodeKind: "bin-comp",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "binaryFeaturesIcon.svg",
    label: "Conditional Features",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "valueMapping",
    nodeClass: "value-mapping",
    modalType: "valueMappingDialog",
    nodeKind: "value-map",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "valueMappingIcon.svg",
    label: "Value Mapping",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "collapsing",
    nodeClass: "collapsing",
    modalType: "customModal",
    nodeKind: "collapse",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "collapsingIcon.svg",
    label: "Collapse",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "pad",
    nodeClass: "pad",
    modalType: "customModal",
    nodeKind: "pad",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "paddingIcon.svg",
    label: "Padding",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "fill",
    nodeClass: "fill",
    modalType: "customModal",
    nodeKind: "fill",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "fillIcon.svg",
    label: "Fill",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "precision",
    nodeClass: "precision",
    modalType: "customModal",
    nodeKind: "precision",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "precisionIcon.svg",
    label: "Precision",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "dataCleansingHandler",
    nodeClass: "data-cleansing-handler",
    modalType: "dataCleansingHandlerNodeDialog",
    nodeKind: "datacln",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "dataCleansingHandlerIcon.svg",
    label: "Cleansing",
    isDraggable: true,
    section: "feature_engineering",
  },
  {
    nodeType: "observationWindow",
    nodeClass: "observation-window",
    modalType: "customModal",
    nodeKind: "obs-win",
    isStage: false,
    allowOneIncomingEdge: false,
    icon: "observationWindowIcon.svg",
    label: "Observation window",
    isDraggable: true,
    section: "feature_recipe",
  },
  {
    nodeType: "trend",
    nodeClass: "trend",
    modalType: "customModal",
    nodeKind: "trend-shift",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "trendIcon.svg",
    label: "Trend",
    isDraggable: true,
    section: "feature_recipe",
  },
  {
    nodeType: "shift",
    nodeClass: "shift",
    modalType: "customModal",
    nodeKind: "trend-shift",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "shiftIcon.svg",
    label: "Shift",
    isDraggable: true,
    section: "feature_recipe",
  },
  {
    nodeType: "performanceWindow",
    nodeClass: "performance-window",
    modalType: "customModal",
    nodeKind: "perf-win",
    isStage: false,
    allowOneIncomingEdge: false,
    icon: "performanceWindowIcon.svg",
    label: "Performance window",
    isDraggable: true,
    section: "feature_recipe",
  },
  {
    nodeType: "everTarget",
    nodeClass: "ever-target",
    modalType: "customModal",
    nodeKind: "perwinTarget",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "everTargetIcon.svg",
    label: "Ever-target",
    isDraggable: true,
    section: "feature_recipe",
  },
  {
    nodeType: "coinTarget",
    nodeClass: "coin-target",
    modalType: "customModal",
    nodeKind: "perwinTarget",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "coinTargetIcon.svg",
    label: "Coin-target",
    isDraggable: true,
    section: "feature_recipe",
  },
  {
    nodeType: "dpdExtract",
    nodeClass: "dpd-extract",
    modalType: "customModal",
    nodeKind: "dpdExtract",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "dpdExtractIcon.svg",
    label: "Dpd Extract",
    isDraggable: true,
    section: "feature_recipe",
  },
  {
    nodeType: "python",
    nodeClass: "python",
    modalType: "customModal",
    nodeKind: "script",
    isStage: false,
    allowOneIncomingEdge: true,
    icon: "pythonIcon.svg",
    label: "Python",
    isDraggable: true,
    section: "code_recipe",
  },
  {
    nodeType: "pySpark",
    nodeClass: "pySpark",
    modalType: "customModal",
    nodeKind: "script",
    isStage: false,
    allowOneIncomingEdge: true,
    icon: "pysparkIcon.svg",
    label: "PySpark",
    isDraggable: true,
    section: "code_recipe",
  },
  {
    nodeType: "sparkSql",
    nodeClass: "sparkSql",
    modalType: "customModal",
    nodeKind: "code",
    isStage: true,
    allowOneIncomingEdge: true,
    icon: "sparksqlIcon.svg",
    label: "SparkSql",
    isDraggable: false,
    section: "code_recipe",
  },
  {
    nodeType: "transaction",
    nodeClass: "transaction",
    modalType: "fsRecipeDialog",
    nodeKind: "pipeline",
    isStage: false,
    allowOneIncomingEdge: false,
    icon: "transactionIcon.svg",
    label: "Transaction",
    isDraggable: true,
    section: "fs_recipe",
  },
  {
    nodeType: "equifax",
    nodeClass: "equifax",
    modalType: "fsRecipeDialog",
    nodeKind: "pipeline",
    isStage: false,
    allowOneIncomingEdge: false,
    icon: "equifaxIcon.svg",
    label: "Equifax",
    isDraggable: true,
    section: "fs_recipe",
  },
  {
    nodeType: "modelDevelopment",
    nodeClass: "model-development",
    modalType: "customModal",
    nodeKind: "model-dev",
    isStage: false,
    allowOneIncomingEdge: true,
    icon: "modelDevelopmentIcon.svg",
    label: "Model Development",
    isDraggable: true,
    section: "modelling",
  },
  {
    nodeType: "modelScoring",
    nodeClass: "model-score",
    modalType: "customModal",
    nodeKind: "model-score",
    isStage: false,
    allowOneIncomingEdge: true,
    icon: "modelScoringIcon.svg",
    label: "Model Scoring",
    isDraggable: true,
    section: "modelling",
  },
];
