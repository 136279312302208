import React, { useEffect, useRef, useState } from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { ReactComponent as FileUploadIcon } from "../../assets/icons/fileUploadIcon.svg";
import { actionCreators } from "../../state";
import "./fileCfgForm.scss";
import {
  labelStyles,
  textFieldStyles,
  toggleButtonStyles,
} from "./fileCfgFormStyles";
import api from "../../apiInterceptor";
const getprojcfgAPITestData = require("../../assets/apiTestData/getprojcfg-test-data.json");

const FileCfgForm = ({ uploadedFile }) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedProjFg = useSelector((state) => state.selectedFeatureGroup);
  const fileDataFormatOptions = [
    {
      name: "csv",
      value: "csv",
    },
    {
      name: "json",
      value: "json",
    },
    {
      name: "parquet",
      value: "parquet",
    },
  ];
  const fileStoageTypeOptions = [
    {
      name: "Local",
      value: "local",
    },
    {
      name: "S3",
      value: "s3",
    },
    {
      name: "Azure",
      value: "azure",
    },
    {
      name: "BigQuery",
      value: "bigquery",
    },
    {
      name: "HDFS",
      value: "hdfs",
    },
    {
      name: "SFTP",
      value: "sftp",
    },
    {
      name: "Custom",
      value: "custom",
    },
    {
      name: "ADLS",
      value: "adls",
    },
  ];
  const [localUploadOptions, setLocalUploadOptions] = useState(
    fileDataFormatOptions
  );
  const [remoteUploadOptions, setRemoteUploadOptions] = useState(
    fileStoageTypeOptions
  );
  const switchRef = useRef(null);
  const inputRef = useRef(null);
  const fileConfigs = useSelector((state) => state.fileConfigs);
  const dispatch = useDispatch();
  const { updateFileConfigs, updateIsLoading, updateSnackbarMsg } =
    bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    getFileOptions();
  }, []);

  const getFileOptions = async () => {
    try {
      const apiUrl = BASE_API_URL + "getprojcfg";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: selectedProjectKey,
        projVersion: selectedProjVersion,
        projFg: selectedProjFg,
        nodeType: "fileUpload",
      };
      updateIsLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: getprojcfgAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        setFileUploadOptions(result);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateSnackbarMsg(response.data.data.reason);
        } else {
          updateSnackbarMsg("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateSnackbarMsg(errorMessage);
    }
  };

  const setFileUploadOptions = (result) => {
    if (
      result.local &&
      Array.isArray(result.local.options) &&
      result.local.options.length > 0
    ) {
      setLocalUploadOptions(result.local.options);
    }
    if (
      result.remote &&
      Array.isArray(result.remote.options) &&
      result.remote.options.length > 0
    ) {
      setRemoteUploadOptions(result.remote.options);
    }
  };

  const isOptionEqualToValue = (option, value, options) => {
    if (options[0]?.name) {
      options = options.map((opt) => opt.name);
    } else if (options[0]?.value) {
      options = options.map((opt) => opt.value);
    }
    if (options.includes(value)) {
      if (typeof option === "object") {
        return (
          option.value === value ||
          option.item === value ||
          option.name === value ||
          value === ""
        );
      } else {
        return option === value || value === "";
      }
    }
    return value === "";
  };

  const getNameFromValue = (value, options) => {
    const option = options.find((option) => option.value === value);
    return option ? option.name : value;
  };

  const renderTags = (value, getTagProps) =>
    value.map((option, index) => (
      <Tooltip key={option} title={option} placement="top">
        <Chip label={option} {...getTagProps({ index })} />
      </Tooltip>
    ));

  const getOptionLabel = (option) => {
    if (typeof option === "object") {
      if (option.name) {
        return option.name;
      } else if (option.item) {
        return option.item;
      }
      return option.value;
    }
    return option;
  };

  const handleFileInputChange = (evt) => {
    const clonedFileCfg = structuredClone(fileConfigs);
    if (evt.target.name === "hostType") {
      clonedFileCfg["hostType"] = evt.target.checked ? "remote" : "local";
    } else {
      clonedFileCfg[evt.target.name] = evt.target.value;
    }
    updateFileConfigs(clonedFileCfg);
  };

  const handleFileChange = async (e, inputId, fileDropped = false) => {
    let selectedFile = null;
    if (fileDropped) {
      e.preventDefault();
      selectedFile = e.dataTransfer.files[0];
    } else {
      selectedFile = e.target.files[0];
    }
    uploadedFile.current = selectedFile;
    const clonedFileCfg = structuredClone(fileConfigs);
    const newfileDetails = {
      name: selectedFile.name,
      type: selectedFile.type,
      size: selectedFile.size,
    };
    clonedFileCfg[inputId] = newfileDetails;
    clonedFileCfg["dataPath"] = selectedFile.name;
    updateFileConfigs(clonedFileCfg);
  };

  const handleFileRemove = (inputId) => {
    uploadedFile.current = null;
    const clonedFileCfg = structuredClone(fileConfigs);
    clonedFileCfg[inputId] = "";
    updateFileConfigs(clonedFileCfg);
  };

  const onInputClick = (event) => {
    event.target.value = "";
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleSelectChangeForFile = (inputId, value) => {
    const clonedFileCfg = structuredClone(fileConfigs);
    clonedFileCfg[inputId] = value;
    updateFileConfigs(clonedFileCfg);
  };

  return (
    <Stack sx={{ marginTop: "0 !important" }}>
      <FormControl
        key={"hostType"}
        id={"hostType"}
        className="file-upload-radio"
      >
        <FormLabel id="row-toggle-button-label" sx={labelStyles}>
          {"Upload from"}
        </FormLabel>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>Local</Typography>
          <Switch
            onChange={handleFileInputChange}
            ref={switchRef}
            aria-labelledby="row-toggle-button-label"
            name={"hostType"}
            sx={toggleButtonStyles}
            checked={
              fileConfigs["hostType"] && fileConfigs["hostType"] === "remote"
                ? true
                : false
            }
          />
          <Typography>Remote</Typography>
        </Stack>
      </FormControl>
      <TextField
        key={"fileName"}
        type={"text"}
        label={"File name"}
        variant="outlined"
        size="small"
        className="url-input"
        name={"fileName"}
        value={fileConfigs["fileName"]}
        sx={textFieldStyles}
        autoComplete="off"
        onChange={handleFileInputChange}
      />
      {fileConfigs.hostType === "local" ? (
        <Stack>
          <Box key={"file"}>
            <input
              type="file"
              onChange={(evt) => handleFileChange(evt, "file")}
              onClick={onInputClick}
              className="file-upload-input"
              ref={inputRef}
            />
            <div
              className="select-file-button"
              onClick={() => inputRef.current.click()}
            >
              <FileUploadIcon className="file-upload-icon"></FileUploadIcon>
              <span>Select File</span>
            </div>
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              className="file-upload-area"
              onDragOver={handleDragOver}
              onDrop={(evt) => handleFileChange(evt, "file", true)}
            >
              <Stack gap="10px">
                <Stack direction="row" gap="5px">
                  <p>
                    {fileConfigs.file
                      ? fileConfigs.file.name
                      : "Drop your files here"}
                  </p>
                  <Tooltip title="Remove file" placement="top">
                    {fileConfigs.file ? (
                      <Box
                        className="remove-file-icon"
                        onClick={(evt) => handleFileRemove("file")}
                      />
                    ) : (
                      <Box />
                    )}
                  </Tooltip>
                </Stack>
              </Stack>
              {fileConfigs.file && <p>{fileConfigs.file.size} bytes</p>}
            </Stack>
          </Box>
        </Stack>
      ) : (
        <Stack>
          <Autocomplete
            key={"storageType"}
            autoHighlight
            size="small"
            id="tags-outlined"
            options={remoteUploadOptions}
            getOptionLabel={(option) => getOptionLabel(option)}
            autoComplete
            includeInputInList
            disableClearable
            className="select-dropdown"
            ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
            isOptionEqualToValue={(option, value) => {
              return isOptionEqualToValue(option, value, remoteUploadOptions);
            }}
            value={
              fileConfigs["storageType"]
                ? getNameFromValue(
                    fileConfigs["storageType"],
                    remoteUploadOptions
                  )
                : ""
            }
            onChange={(e, value) => {
              handleSelectChangeForFile("storageType", value.value);
            }}
            renderTags={renderTags}
            componentsProps={{
              paper: {
                sx: {
                  backgroundColor: "#212b35",
                  color: "#d9d9d9",
                  borderRadius: "4px",
                  boxShadow:
                    "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                  "li:hover": {
                    color: "#69c2d2",
                  },
                },
              },
            }}
            renderInput={(params) => (
              <Tooltip
                title={getNameFromValue(
                  fileConfigs["storageType"],
                  remoteUploadOptions
                )}
                placement="right"
              >
                <TextField
                  {...params}
                  size="small"
                  label={"Storage type"}
                  placeholder="Select option"
                  autoComplete="off"
                  sx={textFieldStyles}
                />
              </Tooltip>
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Tooltip title={option.name} placement="right">
                  <Typography noWrap>{option.name}</Typography>
                </Tooltip>
              </li>
            )}
          />
          {fileConfigs["storageType"] === "s3" ||
          fileConfigs["storageType"] === "azure" ||
          fileConfigs["storageType"] === "bigquery" ? (
            <Stack>
              <TextField
                key={"secretKey"}
                m={2}
                type={"text"}
                label={"Secret Key"}
                variant="outlined"
                size="small"
                className="url-input"
                name={"secretKey"}
                value={fileConfigs["secretKey"]}
                sx={textFieldStyles}
                autoComplete="off"
                onChange={handleFileInputChange}
              />
              <TextField
                key={"accessKey"}
                m={2}
                type={"text"}
                label={"Access Key"}
                variant="outlined"
                size="small"
                className="url-input"
                name={"accessKey"}
                value={fileConfigs["accessKey"]}
                sx={textFieldStyles}
                autoComplete="off"
                onChange={handleFileInputChange}
              />
              <TextField
                key={"authToken"}
                m={2}
                type={"text"}
                label={"Auth Token"}
                variant="outlined"
                size="small"
                className="url-input"
                name={"authToken"}
                value={fileConfigs["authToken"]}
                sx={textFieldStyles}
                autoComplete="off"
                onChange={handleFileInputChange}
              />
            </Stack>
          ) : (
            <></>
          )}
          {fileConfigs["storageType"] !== "adls" && (
            <TextField
              key={"dataPath"}
              m={2}
              type={"text"}
              label={
                fileConfigs["storageType"] === "local" ||
                fileConfigs["storageType"] === "sftp"
                  ? "Data path"
                  : "Url"
              }
              variant="outlined"
              size="small"
              className="url-input"
              name={"dataPath"}
              value={fileConfigs["dataPath"]}
              sx={textFieldStyles}
              autoComplete="off"
              onChange={handleFileInputChange}
            />
          )}
          {fileConfigs["storageType"] === "sftp" ? (
            <Stack>
              <TextField
                key={"host"}
                m={2}
                type={"text"}
                label={"Host"}
                variant="outlined"
                size="small"
                className="url-input"
                name={"host"}
                value={fileConfigs["host"]}
                sx={textFieldStyles}
                autoComplete="off"
                onChange={handleFileInputChange}
              />
              <TextField
                key={"username"}
                m={2}
                type={"text"}
                label={"Username"}
                variant="outlined"
                size="small"
                className="url-input"
                name={"username"}
                value={fileConfigs["username"]}
                sx={textFieldStyles}
                autoComplete="off"
                onChange={handleFileInputChange}
              />
              <TextField
                key={"password"}
                m={2}
                type={"text"}
                label={"Password"}
                variant="outlined"
                size="small"
                className="url-input"
                name={"password"}
                value={fileConfigs["password"]}
                sx={textFieldStyles}
                autoComplete="off"
                onChange={handleFileInputChange}
              />
            </Stack>
          ) : (
            <></>
          )}
          {fileConfigs["storageType"] === "adls" ? (
            <Stack>
              <FormControl
                id="storageAccountName"
                className="text-field"
                size="small"
              >
                <TextField
                  type="text"
                  name="storageAccountName"
                  size="small"
                  label="Storage Account Name"
                  value={fileConfigs["storageAccountName"]}
                  onChange={handleFileInputChange}
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                  required
                />
              </FormControl>
              <FormControl id="clientId" className="text-field" size="small">
                <TextField
                  type="text"
                  name="clientId"
                  size="small"
                  label="Client Id"
                  value={fileConfigs["clientId"]}
                  onChange={handleFileInputChange}
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                  required
                />
              </FormControl>
              <FormControl id="dataPath" className="text-field" size="small">
                <TextField
                  type="text"
                  name="tenantId"
                  size="small"
                  label="Tenant Id"
                  value={fileConfigs["tenantId"]}
                  onChange={handleFileInputChange}
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                  required
                />
              </FormControl>
              <FormControl id="secretKey" className="text-field" size="small">
                <TextField
                  type="text"
                  name="secretKey"
                  size="small"
                  label="Secret Key"
                  value={fileConfigs["secretKey"]}
                  onChange={handleFileInputChange}
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                  required
                />
              </FormControl>
              <FormControl id="pathType" sx={{ marginTop: "15px !important" }}>
                <Stack direction="row" alignItems="center" gap="20px">
                  <FormLabel
                    id="row-radio-buttons-group-label"
                    sx={labelStyles}
                  >
                    Path Type
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="row-radio-buttons-group-label"
                    name="pathType"
                    value={fileConfigs["pathType"]}
                    onChange={handleFileInputChange}
                  >
                    {[
                      {
                        name: "Data Path",
                        value: "datapath",
                      },
                      {
                        name: "Url",
                        value: "url",
                      },
                    ].map((radio, radioIndex) => (
                      <FormControlLabel
                        key={radioIndex}
                        value={radio.value}
                        control={<Radio style={{ color: "#69C2D2" }} />}
                        label={radio.name}
                      />
                    ))}
                  </RadioGroup>
                </Stack>
              </FormControl>
              {fileConfigs.pathType === "datapath" ? (
                <Stack>
                  <FormControl
                    id="containerName"
                    className="text-field"
                    size="small"
                  >
                    <TextField
                      type="text"
                      name="containerName"
                      size="small"
                      label="Container Name"
                      value={fileConfigs["containerName"] || ""}
                      onChange={handleFileInputChange}
                      variant="outlined"
                      sx={textFieldStyles}
                      autoComplete="off"
                      required
                    />
                  </FormControl>
                  <FormControl
                    id="dataPath"
                    className="text-field"
                    size="small"
                  >
                    <TextField
                      type="text"
                      name="dataPath"
                      size="small"
                      label="Data Path"
                      value={fileConfigs["dataPath"]}
                      onChange={handleFileInputChange}
                      variant="outlined"
                      sx={textFieldStyles}
                      autoComplete="off"
                      required
                    />
                  </FormControl>
                </Stack>
              ) : (
                <Stack>
                  <FormControl
                    id="dataPath"
                    className="text-field"
                    size="small"
                  >
                    <TextField
                      type="text"
                      name="dataPath"
                      size="small"
                      label="Url"
                      value={fileConfigs["dataPath"]}
                      onChange={handleFileInputChange}
                      variant="outlined"
                      sx={textFieldStyles}
                      autoComplete="off"
                      required
                    />
                  </FormControl>
                </Stack>
              )}
            </Stack>
          ) : (
            <></>
          )}
        </Stack>
      )}
      <Autocomplete
        key={"dataFormat"}
        autoHighlight
        size="small"
        id="tags-outlined"
        options={localUploadOptions}
        getOptionLabel={(option) => getOptionLabel(option)}
        autoComplete
        includeInputInList
        disableClearable
        className="select-dropdown"
        ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
        isOptionEqualToValue={(option, value) => {
          return isOptionEqualToValue(option, value, localUploadOptions);
        }}
        value={
          fileConfigs["dataFormat"]
            ? getNameFromValue(fileConfigs["dataFormat"], localUploadOptions)
            : ""
        }
        onChange={(e, value) => {
          handleSelectChangeForFile("dataFormat", value.value);
        }}
        renderTags={renderTags}
        componentsProps={{
          paper: {
            sx: {
              backgroundColor: "#212b35",
              color: "#d9d9d9",
              borderRadius: "4px",
              boxShadow:
                "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
              "li:hover": {
                color: "#69c2d2",
              },
            },
          },
        }}
        renderInput={(params) => (
          <Tooltip
            title={getNameFromValue(
              fileConfigs["dataFormat"],
              localUploadOptions
            )}
            placement="right"
          >
            <TextField
              {...params}
              size="small"
              label={"Source type"}
              placeholder="Select option"
              autoComplete="off"
              sx={textFieldStyles}
            />
          </Tooltip>
        )}
        renderOption={(props, option) => (
          <li {...props}>
            <Tooltip title={option.name} placement="right">
              <Typography noWrap>{option.name}</Typography>
            </Tooltip>
          </li>
        )}
      />
    </Stack>
  );
};

export default FileCfgForm;
