import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import { TextField, Snackbar } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { ReactComponent as QuestionMarkIcon } from "../../assets/icons/questionMark.svg";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import "./fsRecipeDialog.scss";
import api from "../../apiInterceptor";
import { buttonStyles, textFieldStyles } from "./fsRecipeDialogStyle";
import CustomTooltip from "components/Tooltip/CustomTooltip";
const uploadDataAPITestData = require("../../assets/apiTestData/pipeline-getprojcfg-test-data.json");
const fsrecipeImpAPITestData = require("../../assets/apiTestData/fsrecipe-imp-test-data.json");

const FsRecipeDialog = (modalProps) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  const userAccessType = localStorage.getItem("ACCESS_TYPE");
  const userAccessRestricted = userAccessType === "view";
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const modalType = "apiDialog";
  const formData = {
    name: "",
    description: "",
    projectKey: "",
    projVersion: "",
    projFg: "",
    dSource: "",
    dSourceName: "",
    isImported: false,
  };
  const selectedNode = modalProps.selectedNode;
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const modalForm = useSelector((state) => state.modalForm);
  const pipeline = useSelector((state) => state.pipeline);
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedFeatureGroup = useSelector(
    (state) => state.selectedFeatureGroup
  );
  const snackbarMsg = useSelector((state) => state.snackbarMsg);
  const detailedMsgTooltipData = useSelector(
    (state) => state.detailedMsgTooltipData
  );
  const nodeConfigurations =
    useSelector((state) => state.nodeConfigurations) || {};
  const dispatch = useDispatch();
  const {
    updateModalForm,
    updateNodeConfigurations,
    updateSnackbarMsg,
    updateIsLoading,
    updateAlertMessage,
  } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    initializeModalForm();
  }, []);

  useEffect(() => {
    if (modalForm) {
    }
    let disableSave = false;
    if (!modalForm) {
      disableSave = true;
    } else {
      disableSave = handleSaveDisable();
    }
    if (disableSave) {
      setIsSaveDisabled(true);
      return;
    }
    disableSave = false;
    setIsSaveDisabled(false);
  }, [modalForm]);

  const initializeModalForm = () => {
    if (nodeConfigurations[selectedNode.id]) {
      setTimeout(() => updateModalForm(nodeConfigurations[selectedNode.id]), 1);
    } else {
      setTimeout(() => updateModalForm(formData), 1);
    }
  };

  const handleSaveDisable = () => {
    if (!modalForm.name) {
      return true;
    }
    return false;
  };

  const handleChange = (e) => {
    try {
      const value = e.target.value;
      const name = e.target.name;
      const newFormData = structuredClone(modalForm);
      if (e?.target?.type === "text" && name === "name") {
        if (!(value.length <= 50 && /^[a-zA-Z0-9_]*$/.test(value))) {
          return;
        }
      }
      newFormData[name] = value;
      updateModalForm(newFormData);
    } catch (error) {
      console.error(error);
    }
  };

  const checkNodeNameDuplicacy = (nodeConfigs) => {
    const dsMap = pipeline.clientMetaData.dsMap;
    if (nodeConfigs.name || nodeConfigs.fileName) {
      for (let nodeId of Object.keys(dsMap)) {
        if (
          selectedNode.id !== nodeId &&
          ((nodeConfigs.name && dsMap[nodeId] === nodeConfigs.name) ||
            (nodeConfigs.fileName && nodeConfigs.fileName === dsMap[nodeId]))
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const clickSubmit = (e) => {
    if (!modalForm.isImported) {
      importFSRecipe(e);
    } else {
      const newFormData = structuredClone(modalForm);
      saveUploadedFile(e, newFormData);
    }
  };

  const importFSRecipe = async (e) => {
    try {
      const apiUrl = BASE_API_URL + "fsrecipe-imp";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: selectedProjectKey,
        projVersion: selectedProjVersion,
        projFg: selectedFeatureGroup,
        fsKey: selectedNode.data.nodeType,
      };
      updateIsLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: fsrecipeImpAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, {
          headers: headers,
        });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        const newFormData = getProjectInfo(result);
        saveUploadedFile(e, newFormData);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const getProjectInfo = (result) => {
    const newFormData = structuredClone(modalForm);
    newFormData["projectKey"] = result.projectKey;
    newFormData["projVersion"] = result.projVersion;
    newFormData["projFg"] = result.projFg;
    newFormData["dSource"] = result.dSource.key;
    newFormData["sourceDs"] = result.dSource.key;
    newFormData["dSourceName"] = result.dSource.name;
    newFormData["isImported"] = true;
    newFormData["sourceProjectKey"] = result.projectKey;
    newFormData["sourceProjVersion"] = result.projVersion;
    newFormData["sourceProjFg"] = result.projFg;
    return newFormData;
  };

  const saveUploadedFile = async (e, newFormData) => {
    const apiUrl = BASE_API_URL + "upload-data";
    const headers = {};
    let payload = new FormData();
    payload.append("sourceType", "pipeline");
    newFormData["sourceType"] = "pipeline";
    newFormData["dsName"] = newFormData.name;
    payload.append("dSource", selectedNode.id);
    payload.append("sourceDs", selectedNode.id);
    payload.append("projectKey", selectedProjectKey);
    payload.append("projVersion", selectedProjVersion);
    payload.append("projFg", selectedFeatureGroup);
    payload.append("dataCfg", JSON.stringify(newFormData));
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: uploadDataAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, {
          headers: headers,
          data: null,
        });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        handleSubmit(e, newFormData);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const handleSubmit = (e, newFormData) => {
    e.preventDefault();
    const newNodeConfigurations = structuredClone(nodeConfigurations);
    const duplicatedName = checkNodeNameDuplicacy(newFormData);
    if (duplicatedName) {
      updateSnackbarMsg("Name already taken. Please enter a unique name");
      return;
    }
    updateModalForm(newFormData);
    newNodeConfigurations[selectedNode.id] = structuredClone(newFormData);
    updateNodeConfigurations(newNodeConfigurations);
    modalProps.unHighlightProblematicNode(selectedNode);
    setTimeout(
      () => modalProps.saveConfig(newNodeConfigurations, selectedNode.id),
      1
    );
    modalProps.closeModal(modalType);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    updateSnackbarMsg(null);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <div>
      <Dialog
        fullWidth
        open={selectedNode !== null}
        aria-labelledby="fsRecipe-dialog"
        className="fsRecipe-dialog"
        maxWidth="sm"
        PaperProps={{
          style: {
            background: "#212B35",
            border: "1px solid #818181",
            boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            color: "#EFF1F1",
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          {`${selectedNode.data.label} Recipe`}
          <div>
            {detailedMsgTooltipData[selectedNode.data.nodeType] ? (
              <IconButton>
                <CustomTooltip
                  title={detailedMsgTooltipData[selectedNode.data.nodeType]}
                >
                  <QuestionMarkIcon width={25} height={25}></QuestionMarkIcon>
                </CustomTooltip>
              </IconButton>
            ) : (
              <></>
            )}
            <IconButton onClick={() => modalProps.closeModal(modalType)}>
              <CloseIcon></CloseIcon>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <form>
            <Stack>
              <FormControl id="name" className="text-field" size="small">
                <TextField
                  type="text"
                  name="name"
                  size="small"
                  label="Name"
                  value={modalForm ? modalForm.name : ""}
                  onChange={(evt) => handleChange(evt)}
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                />
              </FormControl>
              <FormControl id="description" className="text-field" size="small">
                <TextField
                  type="text"
                  name="description"
                  size="small"
                  label="Description"
                  value={modalForm ? modalForm.description : ""}
                  onChange={(evt) => handleChange(evt)}
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                />
              </FormControl>
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            sx={buttonStyles}
            onClick={() => modalProps.closeModal(modalType)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={buttonStyles}
            onClick={clickSubmit}
            disabled={isSaveDisabled || userAccessRestricted}
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarMsg}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMsg}
        action={action}
      />
    </div>
  );
};

export default FsRecipeDialog;
