import { React } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import "./pipelineExecutionDialog.scss";
import { buttonStyles } from "./pipelineExecutionDialogStyle";

const PipelineExecutionDialog = ({
  executePipeline,
  checkboxList,
  updateCheckboxList,
  executionTerminator,
}) => {
  const showPipelineExecutionDialog = useSelector(
    (state) => state.showPipelineExecutionDialog
  );
  const dispatch = useDispatch();
  const { updateShowPipelineExecutionDialog } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const handleClose = () => {
    updateShowPipelineExecutionDialog(false);
  };

  const handleSubmit = () => {
    const skipExecutionList = checkboxList
      .filter((obj) => obj.checked)
      .map((obj) => obj.id);
    executePipeline(executionTerminator, false, skipExecutionList);
    handleClose();
  };

  const handleChange = (evt, index) => {
    const newCheckboxList = structuredClone(checkboxList);
    newCheckboxList[index].checked = evt.target.checked;
    updateCheckboxList(newCheckboxList);
  };

  return (
    <Dialog
      fullWidth
      open={showPipelineExecutionDialog}
      aria-labelledby="Execute Pipeline"
      className="pipeline-execution-dialog"
      maxWidth="sm"
      PaperProps={{
        style: {
          background: "#212B35",
          border: "1px solid #818181",
          boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          color: "#EFF1F1",
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title">
        Pipeline Re Execution
        <IconButton
          aria-label="close"
          className="close-icon"
          onClick={handleClose}
        >
          <CloseIcon></CloseIcon>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box className="skip-text">Deselect to re execute</Box>
        <Stack direction="column">
          {checkboxList.map((checkboxItem, index) => (
            <FormControlLabel
              key={index}
              className="checkbox-label"
              control={
                <Checkbox
                  sx={{
                    color: "#7ec0d0",
                    "&.Mui-checked": {
                      color: "#7ec0d0",
                    },
                  }}
                  checked={checkboxItem.checked}
                />
              }
              onChange={(evt) => handleChange(evt, index)}
              label={checkboxItem.name}
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          sx={buttonStyles}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={buttonStyles}
          onClick={handleSubmit}
          autoFocus
        >
          Run
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PipelineExecutionDialog;
