import { React, useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";
import "./ruleBasedFilter.scss";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "state";
import {
  buttonStyles,
  formControlStyles,
  labelStyles,
  menuItemStyles,
  selectStyles,
  textFieldStyles,
} from "./ruleBasedFilterStyle";
import {
  fetchFeatures,
  prepareColumns,
  getConfigureVariablesdata,
} from "utils/utils";
const nodeInfo = require("../../assets/testdata/node-info-testdata.json");

const RuleBasedFilter = ({
  connectedDataSources,
  allColumns,
  numColumns,
  catColumns,
  dateColumns,
  mixedColumns,
  dsMap,
  index,
  statementId,
  handleRemoveStatement,
}) => {
  const filterCfg = useSelector((state) => state.filterCfg);
  const statementNames = useSelector((state) => state.statementNames);
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedFeatureGroup = useSelector(
    (state) => state.selectedFeatureGroup
  );
  const dispatch = useDispatch();
  const {
    updateFilterCfg,
    updateStatementNames,
    updateIsLoading,
    updateSnackbarMsg,
    updateAlertMessage,
  } = bindActionCreators(actionCreators, dispatch);
  let configs = useRef(null);
  const currentStatementIndex = useRef(null);
  const [mapNumColumns, setMapNumColumns] = useState([]);
  const [mapCatColumns, setMapCatColumns] = useState([]);
  const [mapDateColumns, setMapDateColumns] = useState([]);
  const [variablesData, setVariablesData] = useState([]);
  const [mapMixedColumns, setMapMixedColumns] = useState([]);
  const operators = [
    "lt",
    "le",
    "gt",
    "ge",
    "ne",
    "eq",
    "and_",
    "or_",
    "add",
    "sub",
    "mult",
    "truediv",
    "floordiv",
  ];
  const GroupHeader = styled("div")(({ theme, padding = "4px 10px" }) => ({
    position: "sticky",
    top: "-8px",
    zIndex: 10000,
    padding: padding,
    color: "#FFFFFF",
    backgroundColor: "rgb(68 79 91)",
  }));

  const GroupItems = styled("ul")({
    padding: 0,
  });

  useEffect(() => {
    configs.current = structuredClone(filterCfg);
    if (isEmpty(configs.current[index])) {
      configs.current[index] = {};
      configs.current[index]["subtype"] = "";
      configs.current[index]["cfg"] = {};
      configs.current[index]["cfg"]["ruleCfg"] = {};
      configs.current[index]["cfg"]["ruleCfg"]["stmtOrder"] = [];
      configs.current[index].cfg.ruleCfg[statementId] = {};
      configs.current[index].cfg.ruleCfg[statementId]["mapCfg"] = {};
    } else if (configs.current[index].cfg.ruleCfg[statementId].mapCfg.mapDs) {
      getFeaturesInfo(
        configs.current[index].cfg.ruleCfg[statementId].mapCfg.mapDs
      );
    }
    configs.current[index]["type"] = "filter";
    configs.current[index]["subtype"] = "rule-filter";
    configs.current[index]["cfg"]["inputDs"] = connectedDataSources[0];
    const newFilterCfg = structuredClone(filterCfg);
    newFilterCfg[index] = structuredClone(configs.current[index]);
    updateFilterCfg(newFilterCfg);
    currentStatementIndex.current = Object.keys(statementNames[index]).indexOf(
      statementId
    );
    getVariablesdata();
  }, []);

  const getVariablesdata = async () => {
    const data = await getConfigureVariablesdata(
      selectedProjectKey,
      selectedProjVersion,
      selectedFeatureGroup,
      updateIsLoading,
      updateAlertMessage
    );
    if (data && Array.isArray(data)) {
      const namesArray = data.map((item) => item.name);
      setVariablesData(namesArray);
    }
    if (!data) {
      updateSnackbarMsg("Something went wrong. Please try again later");
      return;
    }
  };

  const getFeaturesInfo = async (ds) => {
    const columnsInfo = [];
    const features = await fetchFeatures(
      ds,
      null,
      selectedProjectKey,
      selectedProjVersion,
      selectedFeatureGroup,
      updateIsLoading,
      updateAlertMessage
    );
    if (!features) {
      updateSnackbarMsg("Couldn't fetch features. Please save and try again");
      return;
    }
    columnsInfo.push(features);
    const allColumnValues = prepareColumns(columnsInfo, nodeInfo.columnsInfo);
    setMapNumColumns(allColumnValues.allColumnsInfo.numerical);
    setMapCatColumns(allColumnValues.allColumnsInfo.categorical);
    setMapDateColumns(allColumnValues.allColumnsInfo.date);
    setMapMixedColumns(allColumnValues.allColumnsInfo.mixed);
  };

  const handleChange = (e) => {
    configs.current = structuredClone(filterCfg);
    const value = e.target.value;
    if (
      e?.target?.type === "text" &&
      (e.target.name === "name" || e.target.name === "opColName")
    ) {
      if (!(value.length <= 50 && /^[a-zA-Z0-9_]*$/.test(value))) {
        return;
      }
    }
    if (e?.target?.type === "text" && e.target.name === "description") {
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        return;
      }
    }
    if (e?.target?.type === "number" && !/^[0-9]+$/.test(value)) {
      return;
    }
    if (e.target.name === "filterValues") {
      configs.current[index].cfg.ruleCfg[statementId][e.target.name] =
        e.target.value.split(",")[0] !== "" ? e.target.value.split(",") : [];
    } else {
      configs.current[index].cfg.ruleCfg[statementId][e.target.name] =
        e.target.value === "true"
          ? true
          : e.target.value === "false"
          ? false
          : e.target.value;
    }
    if (e.target.name === "valueType") {
      if (e.target.value === "map") {
        configs.current[index].cfg.ruleCfg[statementId]["dataMap"] = true;
      } else {
        configs.current[index].cfg.ruleCfg[statementId]["dataMap"] = false;
      }
    }
    if (e.target.name === "stmtName") {
      const newStatementNames = structuredClone(statementNames);
      newStatementNames[index][statementId] = e.target.value;
      updateStatementNames(newStatementNames);
      currentStatementIndex.current = Object.keys(
        newStatementNames[index]
      ).indexOf(statementId);
    }
    const newFilterCfg = structuredClone(filterCfg);
    newFilterCfg[index] = structuredClone(configs.current[index]);
    updateFilterCfg(newFilterCfg);
  };

  const handleMapInputChange = (e) => {
    configs.current[index].cfg.ruleCfg[statementId].mapCfg[e.target.name] =
      e.target.value;
    const newFilterCfg = structuredClone(configs.current);
    updateFilterCfg(newFilterCfg);
    if (e.target.name === "mapDs") {
      getFeaturesInfo(e.target.value);
    }
  };

  const getOptionLabel = (option, identifier) => {
    if (identifier && identifier === "mapDs") {
      if (Object.keys(dsMap).includes(option)) {
        return dsMap[option];
      } else {
        return option;
      }
    } else if (option.item) {
      return option.item;
    } else {
      return option;
    }
  };

  const isOptionEqualToValue = (option, value, options, identifier = null) => {
    if (identifier && identifier === "mapDs") {
      return dsMap[option] === value;
    } else if (options[0].value) {
      options = options.map((opt) => opt.value);
    }
    if (options.includes(value)) {
      if (typeof option === "object") {
        return option.value === value || option.item === value;
      } else {
        return option === value;
      }
    }
    return value === "";
  };

  const renderTags = (value, getTagProps) =>
    value.map((option, index) => (
      <Tooltip key={option} title={option} placement="top">
        <Chip label={option} {...getTagProps({ index })} />
      </Tooltip>
    ));

  const handleSelectChange = (e, value, inputId, mapTypeInput = false) => {
    configs.current = structuredClone(filterCfg);
    if (typeof value === "object" && !Array.isArray(value)) {
      if (value?.item) {
        value = value?.item;
      } else {
        value = value?.value;
      }
    }
    if (mapTypeInput) {
      configs.current[index].cfg.ruleCfg[statementId].mapCfg[inputId] = value;
      const newFilterCfg = structuredClone(configs.current);
      updateFilterCfg(newFilterCfg);
      if (inputId === "mapDs") {
        getFeaturesInfo(value);
      }
    } else {
      configs.current[index].cfg.ruleCfg[statementId][inputId] = value;
      const newFilterCfg = structuredClone(filterCfg);
      newFilterCfg[index] = structuredClone(configs.current[index]);
      updateFilterCfg(newFilterCfg);
    }
  };

  const getColumns = (columns) => {
    let groupedOptions = [];
    const num = columns.filter((col) => numColumns.includes(col));
    const cat = columns.filter((col) => catColumns.includes(col));
    const dat = columns.filter((col) => dateColumns.includes(col));
    const mixed = columns.filter((col) => mixedColumns.includes(col));
    num.forEach((col) => {
      let obj = { item: col, title: "Numerical" };
      groupedOptions.push(obj);
    });
    cat.forEach((col) => {
      let obj = { item: col, title: "Categorical" };
      groupedOptions.push(obj);
    });
    dat.forEach((col) => {
      let obj = { item: col, title: "Date" };
      groupedOptions.push(obj);
    });
    mixed.forEach((col) => {
      let obj = { item: col, title: "Mixed" };
      groupedOptions.push(obj);
    });
    return groupedOptions;
  };

  const getSelectedDataSourceLabel = () => {
    if (
      !filterCfg[index] ||
      !filterCfg[index].cfg.ruleCfg ||
      !filterCfg[index].cfg.ruleCfg[statementId] ||
      !filterCfg[index].cfg.ruleCfg[statementId].mapCfg ||
      !filterCfg[index].cfg.ruleCfg[statementId].mapCfg.mapDs
    ) {
      return "";
    }
    const value = filterCfg[index].cfg.ruleCfg[statementId].mapCfg.mapDs;
    return dsMap[value];
  };

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap="1rem"
      >
        <FormControl id="stmtName" className="text-field" size="small">
          <TextField
            type="text"
            name="stmtName"
            size="small"
            label="Statement Name"
            value={
              filterCfg[index] &&
              filterCfg[index].cfg.ruleCfg &&
              filterCfg[index].cfg.ruleCfg[statementId] &&
              filterCfg[index].cfg.ruleCfg[statementId].stmtName
                ? filterCfg[index].cfg.ruleCfg[statementId].stmtName
                : ""
            }
            onChange={handleChange}
            autoComplete="off"
            variant="outlined"
            sx={textFieldStyles}
            required
          />
        </FormControl>
        <Button
          variant="contained"
          onClick={() => handleRemoveStatement(statementId)}
          autoFocus
          sx={buttonStyles}
        >
          Remove
        </Button>
      </Stack>
      <FormControl id="filter-on" sx={{ marginTop: "15px !important" }}>
        <FormLabel id="row-radio-buttons-group-label" sx={labelStyles}>
          Filter on
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="row-radio-buttons-group-label"
          name="filterType"
          value={
            filterCfg[index] &&
            filterCfg[index].cfg.ruleCfg &&
            filterCfg[index].cfg.ruleCfg[statementId] &&
            filterCfg[index].cfg.ruleCfg[statementId].filterType
              ? filterCfg[index].cfg.ruleCfg[statementId].filterType
              : ""
          }
          onChange={handleChange}
        >
          <FormControlLabel
            value="colVal"
            control={<Radio style={{ color: "#69C2D2" }} />}
            label="Column based"
          />
          <FormControlLabel
            value="operType"
            control={<Radio style={{ color: "#69C2D2" }} />}
            label="Rule based"
          />
          <FormControlLabel
            value="sub-Str"
            control={<Radio style={{ color: "#69C2D2" }} />}
            label="Substring based"
          />
        </RadioGroup>
      </FormControl>
      {filterCfg[index].cfg?.ruleCfg[statementId]?.filterType === "sub-Str" ? (
        <Stack>
          <Autocomplete
            key={"filterCol"}
            autoHighlight
            size="small"
            id="tags-outlined"
            options={getColumns(allColumns)}
            getOptionLabel={(option) => getOptionLabel(option)}
            autoComplete
            includeInputInList
            disableClearable
            className="select-dropdown"
            ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
            isOptionEqualToValue={(option, value) => {
              return isOptionEqualToValue(option, value, allColumns);
            }}
            value={
              filterCfg[index] &&
              filterCfg[index].cfg.ruleCfg &&
              filterCfg[index].cfg.ruleCfg[statementId] &&
              filterCfg[index].cfg.ruleCfg[statementId]["filterCol"]
                ? filterCfg[index].cfg.ruleCfg[statementId]["filterCol"]
                : ""
            }
            onChange={(e, value) => {
              handleSelectChange(e, value, "filterCol");
            }}
            renderTags={renderTags}
            groupBy={(option) => option.title}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            componentsProps={{
              paper: {
                sx: {
                  backgroundColor: "#212b35",
                  color: "#d9d9d9",
                  borderRadius: "4px",
                  boxShadow:
                    "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                  "li:hover": {
                    color: "#69c2d2",
                  },
                },
              },
            }}
            renderInput={(params) => (
              <Tooltip
                title={
                  filterCfg[index] &&
                  filterCfg[index].cfg.ruleCfg &&
                  filterCfg[index].cfg.ruleCfg[statementId] &&
                  filterCfg[index].cfg.ruleCfg[statementId]["filterCol"]
                    ? filterCfg[index].cfg.ruleCfg[statementId]["filterCol"]
                    : ""
                }
                placement="right"
              >
                <TextField
                  {...params}
                  size="small"
                  label="Column Name"
                  placeholder="Select option"
                  autoComplete="off"
                  sx={textFieldStyles}
                />
              </Tooltip>
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Tooltip title={getOptionLabel(option)} placement="right">
                  <Typography noWrap>{getOptionLabel(option)}</Typography>
                </Tooltip>
              </li>
            )}
          />

          <FormControl id="filterCond" sx={formControlStyles} size="small">
            <InputLabel sx={labelStyles}>Filter condition</InputLabel>
            <Select
              placeholder="Select option"
              name="filterCond"
              label="Filter condition"
              value={
                filterCfg[index] &&
                filterCfg[index].cfg.ruleCfg &&
                filterCfg[index].cfg.ruleCfg[statementId] &&
                filterCfg[index].cfg.ruleCfg[statementId].filterCond
                  ? filterCfg[index].cfg.ruleCfg[statementId].filterCond
                  : ""
              }
              onChange={handleChange}
              required
              size="small"
              sx={selectStyles}
              inputProps={{ sx: selectStyles }}
              MenuProps={{ sx: selectStyles }}
              autoWidth={true}
            >
              <MenuItem value="like" sx={menuItemStyles}>
                like
              </MenuItem>
              <MenuItem value="notlike" sx={menuItemStyles}>
                not like
              </MenuItem>
              <MenuItem value="ilike" sx={menuItemStyles}>
                ilike
              </MenuItem>
              <MenuItem value="rlike" sx={menuItemStyles}>
                rlike
              </MenuItem>
              <MenuItem value="notilike" sx={menuItemStyles}>
                not ilike
              </MenuItem>
              <MenuItem value="notrlike" sx={menuItemStyles}>
                not rlike
              </MenuItem>
            </Select>
          </FormControl>
          <Stack direction="row" gap="1rem">
            <FormControl
              id="valueType"
              className="w-100"
              sx={formControlStyles}
              size="small"
            >
              <InputLabel sx={labelStyles}>Value type</InputLabel>
              <Select
                placeholder="Select option"
                name="valueType"
                label="Value type"
                value={
                  filterCfg[index] &&
                  filterCfg[index].cfg.ruleCfg &&
                  filterCfg[index].cfg.ruleCfg[statementId] &&
                  filterCfg[index].cfg.ruleCfg[statementId].valueType
                    ? filterCfg[index].cfg.ruleCfg[statementId].valueType
                    : "constant"
                }
                onChange={handleChange}
                required
                size="small"
                sx={selectStyles}
                inputProps={{ sx: selectStyles }}
                MenuProps={{ sx: selectStyles }}
                autoWidth={true}
              >
                <MenuItem value="constant" sx={menuItemStyles}>
                  Constant
                </MenuItem>
                {/* <MenuItem value="variable" sx={menuItemStyles}>
                  Variable
                </MenuItem> */}
              </Select>
            </FormControl>
            {filterCfg[index].cfg.ruleCfg[statementId].valueType ===
            "variable" ? (
              <Autocomplete
                key={"subStr"}
                autoHighlight
                size="small"
                id="tags-outlined"
                options={variablesData}
                getOptionLabel={(option) => getOptionLabel(option)}
                autoComplete
                includeInputInList
                disableClearable
                className="select-dropdown w-100"
                ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
                isOptionEqualToValue={(option, value) => {
                  return isOptionEqualToValue(option, value, variablesData);
                }}
                value={
                  filterCfg[index] &&
                  filterCfg[index].cfg.ruleCfg &&
                  filterCfg[index].cfg.ruleCfg[statementId] &&
                  filterCfg[index].cfg.ruleCfg[statementId].subStr
                    ? filterCfg[index].cfg.ruleCfg[statementId].subStr
                    : ""
                }
                onChange={(e, value) => {
                  handleSelectChange(e, value, "subStr");
                }}
                renderTags={renderTags}
                componentsProps={{
                  paper: {
                    sx: {
                      backgroundColor: "#212b35",
                      color: "#d9d9d9",
                      borderRadius: "4px",
                      boxShadow:
                        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                      "li:hover": {
                        color: "#69c2d2",
                      },
                    },
                  },
                }}
                renderInput={(params) => (
                  <Tooltip
                    title={
                      filterCfg[index].cfg.ruleCfg &&
                      filterCfg[index].cfg.ruleCfg[statementId] &&
                      filterCfg[index].cfg.ruleCfg[statementId].subStr
                        ? filterCfg[index].cfg.ruleCfg[statementId].subStr
                        : ""
                    }
                    placement="right"
                  >
                    <TextField
                      {...params}
                      size="small"
                      label="Selected variable"
                      placeholder="Select option"
                      autoComplete="off"
                      sx={textFieldStyles}
                    />
                  </Tooltip>
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Tooltip title={getOptionLabel(option)} placement="right">
                      <Typography noWrap>{getOptionLabel(option)}</Typography>
                    </Tooltip>
                  </li>
                )}
              />
            ) : (
              <FormControl id="subStr" className="text-field" size="small">
                <TextField
                  type="text"
                  name="subStr"
                  size="small"
                  label="Substring"
                  value={
                    filterCfg[index] &&
                    filterCfg[index].cfg.ruleCfg &&
                    filterCfg[index].cfg.ruleCfg[statementId] &&
                    filterCfg[index].cfg.ruleCfg[statementId].subStr
                      ? filterCfg[index].cfg.ruleCfg[statementId].subStr
                      : ""
                  }
                  onChange={handleChange}
                  variant="outlined"
                  autoComplete="off"
                  sx={textFieldStyles}
                  required
                />
              </FormControl>
            )}
          </Stack>
        </Stack>
      ) : filterCfg[index].cfg?.ruleCfg[statementId]?.filterType ===
        "colVal" ? (
        <Stack>
          <Autocomplete
            key={"filterCol"}
            autoHighlight
            size="small"
            id="tags-outlined"
            options={getColumns(allColumns)}
            getOptionLabel={(option) => getOptionLabel(option)}
            autoComplete
            includeInputInList
            disableClearable
            className="select-dropdown"
            ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
            isOptionEqualToValue={(option, value) => {
              return isOptionEqualToValue(option, value, allColumns);
            }}
            value={
              filterCfg[index] &&
              filterCfg[index].cfg.ruleCfg &&
              filterCfg[index].cfg.ruleCfg[statementId] &&
              filterCfg[index].cfg.ruleCfg[statementId]["filterCol"]
                ? filterCfg[index].cfg.ruleCfg[statementId]["filterCol"]
                : ""
            }
            onChange={(e, value) => {
              handleSelectChange(e, value, "filterCol");
            }}
            renderTags={renderTags}
            groupBy={(option) => option.title}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            componentsProps={{
              paper: {
                sx: {
                  backgroundColor: "#212b35",
                  color: "#d9d9d9",
                  borderRadius: "4px",
                  boxShadow:
                    "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                  "li:hover": {
                    color: "#69c2d2",
                  },
                },
              },
            }}
            renderInput={(params) => (
              <Tooltip
                title={
                  filterCfg[index] &&
                  filterCfg[index].cfg.ruleCfg &&
                  filterCfg[index].cfg.ruleCfg[statementId] &&
                  filterCfg[index].cfg.ruleCfg[statementId]["filterCol"]
                    ? filterCfg[index].cfg.ruleCfg[statementId]["filterCol"]
                    : ""
                }
                placement="right"
              >
                <TextField
                  {...params}
                  size="small"
                  label="Column name to filter on"
                  placeholder="Select option"
                  autoComplete="off"
                  sx={textFieldStyles}
                />
              </Tooltip>
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Tooltip title={getOptionLabel(option)} placement="right">
                  <Typography noWrap>{getOptionLabel(option)}</Typography>
                </Tooltip>
              </li>
            )}
          />

          <FormControl id="filterCond" sx={formControlStyles} size="small">
            <InputLabel sx={labelStyles}>Filter condition</InputLabel>
            <Select
              placeholder="Select option"
              name="filterCond"
              label="Filter condition"
              value={
                filterCfg[index] &&
                filterCfg[index].cfg.ruleCfg &&
                filterCfg[index].cfg.ruleCfg[statementId] &&
                filterCfg[index].cfg.ruleCfg[statementId].filterCond
                  ? filterCfg[index].cfg.ruleCfg[statementId].filterCond
                  : ""
              }
              onChange={handleChange}
              required
              size="small"
              sx={selectStyles}
              inputProps={{ sx: selectStyles }}
              MenuProps={{ sx: selectStyles }}
              autoWidth={true}
            >
              <MenuItem value="eq" sx={menuItemStyles}>
                eq
              </MenuItem>
              <MenuItem value="neq" sx={menuItemStyles}>
                neq
              </MenuItem>
              <MenuItem value="null-excl" sx={menuItemStyles}>
                null-excl
              </MenuItem>
              <MenuItem value="null-incl" sx={menuItemStyles}>
                null-incl
              </MenuItem>
            </Select>
          </FormControl>
          {filterCfg[index].cfg &&
          filterCfg[index].cfg.ruleCfg &&
          filterCfg[index].cfg.ruleCfg[statementId] &&
          (filterCfg[index].cfg.ruleCfg[statementId].filterCond === "neq" ||
            filterCfg[index].cfg.ruleCfg[statementId].filterCond === "eq") ? (
            <Stack>
              <FormControl id="valueType" sx={formControlStyles} size="small">
                <InputLabel sx={labelStyles}>Value type</InputLabel>
                <Select
                  placeholder="Select option"
                  name="valueType"
                  label="Value type"
                  value={
                    filterCfg[index] &&
                    filterCfg[index].cfg.ruleCfg &&
                    filterCfg[index].cfg.ruleCfg[statementId] &&
                    filterCfg[index].cfg.ruleCfg[statementId].valueType
                      ? filterCfg[index].cfg.ruleCfg[statementId].valueType
                      : ""
                  }
                  onChange={handleChange}
                  required
                  size="small"
                  sx={selectStyles}
                  inputProps={{ sx: selectStyles }}
                  MenuProps={{ sx: selectStyles }}
                  autoWidth={true}
                >
                  <MenuItem value="map" sx={menuItemStyles}>
                    Map
                  </MenuItem>
                  <MenuItem value="constant" sx={menuItemStyles}>
                    Constant
                  </MenuItem>
                  <MenuItem value="variable" sx={menuItemStyles}>
                    Variable
                  </MenuItem>
                </Select>
              </FormControl>
              {filterCfg[index].cfg.ruleCfg &&
              filterCfg[index].cfg.ruleCfg[statementId] &&
              filterCfg[index].cfg.ruleCfg[statementId].valueType === "map" ? (
                <Stack>
                  <Autocomplete
                    key={"mapDs"}
                    autoHighlight
                    size="small"
                    id="tags-outlined"
                    options={Object.keys(dsMap)}
                    getOptionLabel={(option) => getOptionLabel(option, "mapDs")}
                    autoComplete
                    includeInputInList
                    disableClearable
                    className="select-dropdown"
                    ListboxProps={{
                      style: { maxHeight: 300, overflow: "auto" },
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return isOptionEqualToValue(
                        option,
                        value,
                        Object.keys(dsMap),
                        "mapDs"
                      );
                    }}
                    value={getSelectedDataSourceLabel()}
                    onChange={(e, value) => {
                      handleSelectChange(e, value, "mapDs", true);
                    }}
                    renderTags={renderTags}
                    groupBy={(option) => option.title}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader padding="0px">{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                    componentsProps={{
                      paper: {
                        sx: {
                          backgroundColor: "#212b35",
                          color: "#d9d9d9",
                          borderRadius: "4px",
                          boxShadow:
                            "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                          "li:hover": {
                            color: "#69c2d2",
                          },
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <Tooltip
                        title={getSelectedDataSourceLabel()}
                        placement="right"
                      >
                        <TextField
                          {...params}
                          size="small"
                          label="mapping dataset"
                          placeholder="Select option"
                          autoComplete="off"
                          sx={textFieldStyles}
                        />
                      </Tooltip>
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Tooltip
                          title={getOptionLabel(option, "mapDs")}
                          placement="right"
                        >
                          <Typography noWrap>
                            {getOptionLabel(option, "mapDs")}
                          </Typography>
                        </Tooltip>
                      </li>
                    )}
                  />
                  <Autocomplete
                    key={"mapKeyFeat"}
                    autoHighlight
                    size="small"
                    id="tags-outlined"
                    options={getColumns(allColumns)}
                    getOptionLabel={(option) => getOptionLabel(option)}
                    autoComplete
                    includeInputInList
                    disableClearable
                    className="select-dropdown"
                    ListboxProps={{
                      style: { maxHeight: 300, overflow: "auto" },
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return isOptionEqualToValue(option, value, allColumns);
                    }}
                    value={
                      filterCfg[index] &&
                      filterCfg[index].cfg.ruleCfg &&
                      filterCfg[index].cfg.ruleCfg[statementId] &&
                      filterCfg[index].cfg.ruleCfg[statementId].mapCfg &&
                      filterCfg[index].cfg.ruleCfg[statementId].mapCfg
                        .mapKeyFeat
                        ? filterCfg[index].cfg.ruleCfg[statementId].mapCfg
                            .mapKeyFeat
                        : ""
                    }
                    onChange={(e, value) => {
                      handleSelectChange(e, value, "mapKeyFeat", true);
                    }}
                    renderTags={renderTags}
                    groupBy={(option) => option.title}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                    componentsProps={{
                      paper: {
                        sx: {
                          backgroundColor: "#212b35",
                          color: "#d9d9d9",
                          borderRadius: "4px",
                          boxShadow:
                            "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                          "li:hover": {
                            color: "#69c2d2",
                          },
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <Tooltip
                        title={
                          filterCfg[index] &&
                          filterCfg[index].cfg.ruleCfg &&
                          filterCfg[index].cfg.ruleCfg[statementId] &&
                          filterCfg[index].cfg.ruleCfg[statementId].mapCfg &&
                          filterCfg[index].cfg.ruleCfg[statementId].mapCfg
                            .mapKeyFeat
                            ? filterCfg[index].cfg.ruleCfg[statementId].mapCfg
                                .mapKeyFeat
                            : ""
                        }
                        placement="right"
                      >
                        <TextField
                          {...params}
                          size="small"
                          label="Key column Name"
                          placeholder="Select option"
                          autoComplete="off"
                          sx={textFieldStyles}
                        />
                      </Tooltip>
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Tooltip
                          title={getOptionLabel(option)}
                          placement="right"
                        >
                          <Typography noWrap>
                            {getOptionLabel(option)}
                          </Typography>
                        </Tooltip>
                      </li>
                    )}
                  />
                  <Autocomplete
                    key={"mapValFeat"}
                    autoHighlight
                    size="small"
                    id="tags-outlined"
                    options={getColumns(allColumns)}
                    getOptionLabel={(option) => getOptionLabel(option)}
                    autoComplete
                    includeInputInList
                    disableClearable
                    className="select-dropdown"
                    ListboxProps={{
                      style: { maxHeight: 300, overflow: "auto" },
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return isOptionEqualToValue(option, value, allColumns);
                    }}
                    value={
                      filterCfg[index] &&
                      filterCfg[index].cfg.ruleCfg &&
                      filterCfg[index].cfg.ruleCfg[statementId] &&
                      filterCfg[index].cfg.ruleCfg[statementId].mapCfg &&
                      filterCfg[index].cfg.ruleCfg[statementId].mapCfg
                        .mapValFeat
                        ? filterCfg[index].cfg.ruleCfg[statementId].mapCfg
                            .mapValFeat
                        : ""
                    }
                    onChange={(e, value) => {
                      handleSelectChange(e, value, "mapValFeat", true);
                    }}
                    renderTags={renderTags}
                    groupBy={(option) => option.title}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                    componentsProps={{
                      paper: {
                        sx: {
                          backgroundColor: "#212b35",
                          color: "#d9d9d9",
                          borderRadius: "4px",
                          boxShadow:
                            "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                          "li:hover": {
                            color: "#69c2d2",
                          },
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <Tooltip
                        title={
                          filterCfg[index] &&
                          filterCfg[index].cfg.ruleCfg &&
                          filterCfg[index].cfg.ruleCfg[statementId] &&
                          filterCfg[index].cfg.ruleCfg[statementId].mapCfg &&
                          filterCfg[index].cfg.ruleCfg[statementId].mapCfg
                            .mapValFeat
                            ? filterCfg[index].cfg.ruleCfg[statementId].mapCfg
                                .mapValFeat
                            : ""
                        }
                        placement="right"
                      >
                        <TextField
                          {...params}
                          size="small"
                          label="Value column Name"
                          placeholder="Select option"
                          autoComplete="off"
                          sx={textFieldStyles}
                        />
                      </Tooltip>
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Tooltip
                          title={getOptionLabel(option)}
                          placement="right"
                        >
                          <Typography noWrap>
                            {getOptionLabel(option)}
                          </Typography>
                        </Tooltip>
                      </li>
                    )}
                  />

                  <FormControl
                    id="mapKeyVal"
                    className="text-field"
                    size="small"
                  >
                    <TextField
                      type="text"
                      name="mapKeyVal"
                      size="small"
                      label="Key for which the value is mapped"
                      value={
                        filterCfg[index] &&
                        filterCfg[index].cfg.ruleCfg &&
                        filterCfg[index].cfg.ruleCfg[statementId] &&
                        filterCfg[index].cfg.ruleCfg[statementId].mapCfg &&
                        filterCfg[index].cfg.ruleCfg[statementId].mapCfg
                          .mapKeyVal
                          ? filterCfg[index].cfg.ruleCfg[statementId].mapCfg
                              .mapKeyVal
                          : ""
                      }
                      onChange={handleMapInputChange}
                      autoComplete="off"
                      variant="outlined"
                      sx={textFieldStyles}
                      required
                    />
                  </FormControl>
                </Stack>
              ) : filterCfg[index].cfg.ruleCfg &&
                filterCfg[index].cfg.ruleCfg[statementId] &&
                filterCfg[index].cfg.ruleCfg[statementId].valueType ===
                  "constant" ? (
                <FormControl
                  id="filterValues"
                  className="text-field"
                  size="small"
                >
                  <TextField
                    type="text"
                    name="filterValues"
                    size="small"
                    label="Comma separated values"
                    value={
                      filterCfg[index] &&
                      filterCfg[index].cfg.ruleCfg &&
                      filterCfg[index].cfg.ruleCfg[statementId] &&
                      filterCfg[index].cfg.ruleCfg[statementId].filterValues
                        ? filterCfg[index].cfg.ruleCfg[
                            statementId
                          ].filterValues.toString()
                        : ""
                    }
                    onChange={handleChange}
                    autoComplete="off"
                    variant="outlined"
                    sx={textFieldStyles}
                    required
                  />
                </FormControl>
              ) : filterCfg[index].cfg.ruleCfg &&
                filterCfg[index].cfg.ruleCfg[statementId] &&
                filterCfg[index].cfg.ruleCfg[statementId].valueType ===
                  "variable" ? (
                <Autocomplete
                  key={"selectedVariable"}
                  autoHighlight
                  size="small"
                  id="tags-outlined"
                  options={variablesData}
                  getOptionLabel={(option) => getOptionLabel(option)}
                  autoComplete
                  includeInputInList
                  disableClearable
                  className="select-dropdown"
                  ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
                  isOptionEqualToValue={(option, value) => {
                    return isOptionEqualToValue(option, value, variablesData);
                  }}
                  value={
                    filterCfg[index].cfg.ruleCfg &&
                    filterCfg[index].cfg.ruleCfg[statementId] &&
                    filterCfg[index].cfg.ruleCfg[statementId].selectedVariable
                      ? filterCfg[index].cfg.ruleCfg[statementId]
                          .selectedVariable
                      : ""
                  }
                  onChange={(e, value) => {
                    handleSelectChange(e, value, "selectedVariable");
                  }}
                  renderTags={renderTags}
                  componentsProps={{
                    paper: {
                      sx: {
                        backgroundColor: "#212b35",
                        color: "#d9d9d9",
                        borderRadius: "4px",
                        boxShadow:
                          "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                        "li:hover": {
                          color: "#69c2d2",
                        },
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <Tooltip
                      title={
                        filterCfg[index].cfg.ruleCfg &&
                        filterCfg[index].cfg.ruleCfg[statementId] &&
                        filterCfg[index].cfg.ruleCfg[statementId]
                          .selectedVariable
                          ? filterCfg[index].cfg.ruleCfg[statementId]
                              .selectedVariable
                          : ""
                      }
                      placement="right"
                    >
                      <TextField
                        {...params}
                        size="small"
                        label="Selected variable"
                        placeholder="Select option"
                        autoComplete="off"
                        sx={textFieldStyles}
                      />
                    </Tooltip>
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Tooltip title={getOptionLabel(option)} placement="right">
                        <Typography noWrap>{getOptionLabel(option)}</Typography>
                      </Tooltip>
                    </li>
                  )}
                />
              ) : (
                <></>
              )}
            </Stack>
          ) : (
            <></>
          )}
        </Stack>
      ) : filterCfg[index].cfg?.ruleCfg[statementId]?.filterType ===
        "operType" ? (
        <Stack direction="column">
          <Stack direction="row" gap="1rem">
            <FormControl
              className="w-100"
              id="lhsType"
              sx={formControlStyles}
              size="small"
            >
              <InputLabel sx={labelStyles}>LHS type</InputLabel>
              <Select
                placeholder="Select option"
                name="lhsType"
                label="LHS type"
                value={
                  filterCfg[index] &&
                  filterCfg[index].cfg.ruleCfg &&
                  filterCfg[index].cfg.ruleCfg[statementId] &&
                  filterCfg[index].cfg.ruleCfg[statementId].lhsType
                    ? filterCfg[index].cfg.ruleCfg[statementId].lhsType
                    : ""
                }
                onChange={handleChange}
                required
                size="small"
                sx={selectStyles}
                inputProps={{ sx: selectStyles }}
                MenuProps={{ sx: selectStyles }}
                autoWidth={true}
              >
                <MenuItem value="constant" sx={menuItemStyles}>
                  Constant
                </MenuItem>
                <MenuItem value="column" sx={menuItemStyles}>
                  Column
                </MenuItem>
                <MenuItem value="statement" sx={menuItemStyles}>
                  Statement
                </MenuItem>
                {/* <MenuItem value="variable" sx={menuItemStyles}>
                  Variable
                </MenuItem> */}
              </Select>
            </FormControl>
            {filterCfg[index].cfg.ruleCfg[statementId].lhsType === "column" ? (
              <Autocomplete
                key={"lhs"}
                autoHighlight
                size="small"
                id="tags-outlined"
                options={getColumns(allColumns)}
                getOptionLabel={(option) => getOptionLabel(option)}
                autoComplete
                includeInputInList
                disableClearable
                className="select-dropdown w-100"
                ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
                isOptionEqualToValue={(option, value) => {
                  return isOptionEqualToValue(option, value, allColumns);
                }}
                value={
                  filterCfg[index] &&
                  filterCfg[index].cfg.ruleCfg &&
                  filterCfg[index].cfg.ruleCfg[statementId] &&
                  filterCfg[index].cfg.ruleCfg[statementId].lhs
                    ? filterCfg[index].cfg.ruleCfg[statementId].lhs
                    : ""
                }
                onChange={(e, value) => {
                  handleSelectChange(e, value, "lhs");
                }}
                renderTags={renderTags}
                groupBy={(option) => option.title}
                renderGroup={(params) => (
                  <li key={params.key}>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                  </li>
                )}
                componentsProps={{
                  paper: {
                    sx: {
                      backgroundColor: "#212b35",
                      color: "#d9d9d9",
                      borderRadius: "4px",
                      boxShadow:
                        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                      "li:hover": {
                        color: "#69c2d2",
                      },
                    },
                  },
                }}
                renderInput={(params) => (
                  <Tooltip
                    title={
                      filterCfg[index] &&
                      filterCfg[index].cfg.ruleCfg &&
                      filterCfg[index].cfg.ruleCfg[statementId] &&
                      filterCfg[index].cfg.ruleCfg[statementId].lhs
                        ? filterCfg[index].cfg.ruleCfg[statementId].lhs
                        : ""
                    }
                    placement="right"
                  >
                    <TextField
                      {...params}
                      size="small"
                      label="LHS column"
                      placeholder="Select option"
                      autoComplete="off"
                      sx={textFieldStyles}
                    />
                  </Tooltip>
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Tooltip title={getOptionLabel(option)} placement="right">
                      <Typography noWrap>{getOptionLabel(option)}</Typography>
                    </Tooltip>
                  </li>
                )}
              />
            ) : filterCfg[index].cfg.ruleCfg[statementId].lhsType ===
              "statement" ? (
              <FormControl
                className="w-100"
                id="lhsType"
                sx={formControlStyles}
                size="small"
              >
                <InputLabel sx={labelStyles}>LHS statement</InputLabel>
                <Select
                  placeholder="Select option"
                  name="lhs"
                  label="LHS statement"
                  value={
                    filterCfg[index] &&
                    filterCfg[index].cfg.ruleCfg &&
                    filterCfg[index].cfg.ruleCfg[statementId] &&
                    filterCfg[index].cfg.ruleCfg[statementId].lhs
                      ? filterCfg[index].cfg.ruleCfg[statementId].lhs
                      : ""
                  }
                  onChange={handleChange}
                  required
                  size="small"
                  sx={selectStyles}
                  inputProps={{ sx: selectStyles }}
                  MenuProps={{ sx: selectStyles }}
                  autoWidth={true}
                >
                  {Object.keys(statementNames[index]).map(
                    (option, optionIndex) =>
                      statementId !== option &&
                      optionIndex < currentStatementIndex.current && (
                        <MenuItem
                          key={optionIndex}
                          value={option}
                          sx={menuItemStyles}
                        >
                          <Tooltip
                            title={statementNames[index][option]}
                            placement="right"
                          >
                            <Typography noWrap>
                              {statementNames[index][option]}
                            </Typography>
                          </Tooltip>
                        </MenuItem>
                      )
                  )}
                </Select>
              </FormControl>
            ) : filterCfg[index].cfg.ruleCfg[statementId].lhsType ===
              "variable" ? (
              <Autocomplete
                key={"lhs"}
                autoHighlight
                size="small"
                id="tags-outlined"
                options={variablesData}
                getOptionLabel={(option) => getOptionLabel(option)}
                autoComplete
                includeInputInList
                disableClearable
                className="select-dropdown w-100"
                ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
                isOptionEqualToValue={(option, value) => {
                  return isOptionEqualToValue(option, value, variablesData);
                }}
                value={
                  filterCfg[index] &&
                  filterCfg[index].cfg.ruleCfg &&
                  filterCfg[index].cfg.ruleCfg[statementId] &&
                  filterCfg[index].cfg.ruleCfg[statementId].lhs
                    ? filterCfg[index].cfg.ruleCfg[statementId].lhs
                    : ""
                }
                onChange={(e, value) => {
                  handleSelectChange(e, value, "lhs");
                }}
                renderTags={renderTags}
                componentsProps={{
                  paper: {
                    sx: {
                      backgroundColor: "#212b35",
                      color: "#d9d9d9",
                      borderRadius: "4px",
                      boxShadow:
                        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                      "li:hover": {
                        color: "#69c2d2",
                      },
                    },
                  },
                }}
                renderInput={(params) => (
                  <Tooltip
                    title={
                      filterCfg[index].cfg.ruleCfg &&
                      filterCfg[index].cfg.ruleCfg[statementId] &&
                      filterCfg[index].cfg.ruleCfg[statementId].lhs
                        ? filterCfg[index].cfg.ruleCfg[statementId].lhs
                        : ""
                    }
                    placement="right"
                  >
                    <TextField
                      {...params}
                      size="small"
                      label="Selected variable"
                      placeholder="Select option"
                      autoComplete="off"
                      sx={textFieldStyles}
                    />
                  </Tooltip>
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Tooltip title={getOptionLabel(option)} placement="right">
                      <Typography noWrap>{getOptionLabel(option)}</Typography>
                    </Tooltip>
                  </li>
                )}
              />
            ) : (
              <FormControl id="lhs" className="text-field" size="small">
                <TextField
                  type="text"
                  name="lhs"
                  size="small"
                  label="LHS constant"
                  className="w-100"
                  value={
                    filterCfg[index] &&
                    filterCfg[index].cfg.ruleCfg &&
                    filterCfg[index].cfg.ruleCfg[statementId] &&
                    filterCfg[index].cfg.ruleCfg[statementId].lhs
                      ? filterCfg[index].cfg.ruleCfg[statementId].lhs
                      : ""
                  }
                  onChange={handleChange}
                  variant="outlined"
                  autoComplete="off"
                  sx={textFieldStyles}
                  required
                />
              </FormControl>
            )}
          </Stack>
          <Autocomplete
            key={"operator"}
            autoHighlight
            size="small"
            id="tags-outlined"
            options={operators}
            getOptionLabel={(option) => getOptionLabel(option)}
            autoComplete
            includeInputInList
            disableClearable
            className="select-dropdown w-100"
            ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
            isOptionEqualToValue={(option, value) => {
              return isOptionEqualToValue(option, value, operators);
            }}
            value={
              filterCfg[index] &&
              filterCfg[index].cfg.ruleCfg &&
              filterCfg[index].cfg.ruleCfg[statementId] &&
              filterCfg[index].cfg.ruleCfg[statementId].operator
                ? filterCfg[index].cfg.ruleCfg[statementId].operator
                : ""
            }
            onChange={(e, value) => {
              handleSelectChange(e, value, "operator");
            }}
            renderTags={renderTags}
            groupBy={(option) => option.title}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader padding="0px">{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            componentsProps={{
              paper: {
                sx: {
                  backgroundColor: "#212b35",
                  color: "#d9d9d9",
                  borderRadius: "4px",
                  boxShadow:
                    "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                  "li:hover": {
                    color: "#69c2d2",
                  },
                },
              },
            }}
            renderInput={(params) => (
              <Tooltip
                title={
                  filterCfg[index] &&
                  filterCfg[index].cfg.ruleCfg &&
                  filterCfg[index].cfg.ruleCfg[statementId] &&
                  filterCfg[index].cfg.ruleCfg[statementId].operator
                    ? filterCfg[index].cfg.ruleCfg[statementId].operator
                    : ""
                }
                placement="right"
              >
                <TextField
                  {...params}
                  size="small"
                  label="Operator"
                  placeholder="Select option"
                  autoComplete="off"
                  sx={textFieldStyles}
                />
              </Tooltip>
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Tooltip title={getOptionLabel(option)} placement="right">
                  <Typography noWrap>{getOptionLabel(option)}</Typography>
                </Tooltip>
              </li>
            )}
          />

          <Stack direction="row" gap="1rem">
            <FormControl
              className="w-100"
              id="lhsType"
              sx={formControlStyles}
              size="small"
            >
              <InputLabel sx={labelStyles}>RHS type</InputLabel>
              <Select
                placeholder="Select option"
                name="rhsType"
                label="RHS type"
                value={
                  filterCfg[index] &&
                  filterCfg[index].cfg.ruleCfg &&
                  filterCfg[index].cfg.ruleCfg[statementId] &&
                  filterCfg[index].cfg.ruleCfg[statementId].rhsType
                    ? filterCfg[index].cfg.ruleCfg[statementId].rhsType
                    : ""
                }
                onChange={handleChange}
                required
                size="small"
                sx={selectStyles}
                inputProps={{ sx: selectStyles }}
                MenuProps={{ sx: selectStyles }}
                autoWidth={true}
              >
                <MenuItem value="constant" sx={menuItemStyles}>
                  Constant
                </MenuItem>
                <MenuItem value="column" sx={menuItemStyles}>
                  Column
                </MenuItem>
                <MenuItem value="statement" sx={menuItemStyles}>
                  Statement
                </MenuItem>
                {/* <MenuItem value="variable" sx={menuItemStyles}>
                  Variable
                </MenuItem> */}
              </Select>
            </FormControl>
            {filterCfg[index].cfg.ruleCfg[statementId].rhsType === "column" ? (
              <Autocomplete
                key={"rhs"}
                autoHighlight
                size="small"
                id="tags-outlined"
                options={getColumns(allColumns)}
                getOptionLabel={(option) => getOptionLabel(option)}
                autoComplete
                includeInputInList
                disableClearable
                className="select-dropdown w-100"
                ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
                isOptionEqualToValue={(option, value) => {
                  return isOptionEqualToValue(option, value, allColumns);
                }}
                value={
                  filterCfg[index] &&
                  filterCfg[index].cfg.ruleCfg &&
                  filterCfg[index].cfg.ruleCfg[statementId] &&
                  filterCfg[index].cfg.ruleCfg[statementId].rhs
                    ? filterCfg[index].cfg.ruleCfg[statementId].rhs
                    : ""
                }
                onChange={(e, value) => {
                  handleSelectChange(e, value, "rhs");
                }}
                renderTags={renderTags}
                groupBy={(option) => option.title}
                renderGroup={(params) => (
                  <li key={params.key}>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                  </li>
                )}
                componentsProps={{
                  paper: {
                    sx: {
                      backgroundColor: "#212b35",
                      color: "#d9d9d9",
                      borderRadius: "4px",
                      boxShadow:
                        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                      "li:hover": {
                        color: "#69c2d2",
                      },
                    },
                  },
                }}
                renderInput={(params) => (
                  <Tooltip
                    title={
                      filterCfg[index] &&
                      filterCfg[index].cfg.ruleCfg &&
                      filterCfg[index].cfg.ruleCfg[statementId] &&
                      filterCfg[index].cfg.ruleCfg[statementId].rhs
                        ? filterCfg[index].cfg.ruleCfg[statementId].rhs
                        : ""
                    }
                    placement="right"
                  >
                    <TextField
                      {...params}
                      size="small"
                      label="RHS column"
                      placeholder="Select option"
                      autoComplete="off"
                      sx={textFieldStyles}
                    />
                  </Tooltip>
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Tooltip title={getOptionLabel(option)} placement="right">
                      <Typography noWrap>{getOptionLabel(option)}</Typography>
                    </Tooltip>
                  </li>
                )}
              />
            ) : filterCfg[index].cfg.ruleCfg[statementId].rhsType ===
              "statement" ? (
              <FormControl
                className="w-100"
                id="lhsType"
                sx={formControlStyles}
                size="small"
              >
                <InputLabel sx={labelStyles}>RHS statement</InputLabel>
                <Select
                  placeholder="Select option"
                  name="rhs"
                  label="RHS statement"
                  value={
                    filterCfg[index] &&
                    filterCfg[index].cfg.ruleCfg &&
                    filterCfg[index].cfg.ruleCfg[statementId] &&
                    filterCfg[index].cfg.ruleCfg[statementId].rhs
                      ? filterCfg[index].cfg.ruleCfg[statementId].rhs
                      : ""
                  }
                  onChange={handleChange}
                  required
                  size="small"
                  sx={selectStyles}
                  inputProps={{ sx: selectStyles }}
                  MenuProps={{ sx: selectStyles }}
                  autoWidth={true}
                >
                  {Object.keys(statementNames[index]).map(
                    (option, optionIndex) =>
                      statementId !== option &&
                      optionIndex < currentStatementIndex.current && (
                        <MenuItem
                          key={optionIndex}
                          value={option}
                          sx={menuItemStyles}
                        >
                          {statementNames[index][option]}
                        </MenuItem>
                      )
                  )}
                </Select>
              </FormControl>
            ) : filterCfg[index].cfg.ruleCfg[statementId].rhsType ===
              "variable" ? (
              <Autocomplete
                key={"rhs"}
                autoHighlight
                size="small"
                id="tags-outlined"
                options={variablesData}
                getOptionLabel={(option) => getOptionLabel(option)}
                autoComplete
                includeInputInList
                disableClearable
                className="select-dropdown w-100"
                ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
                isOptionEqualToValue={(option, value) => {
                  return isOptionEqualToValue(option, value, variablesData);
                }}
                value={
                  filterCfg[index] &&
                  filterCfg[index].cfg.ruleCfg &&
                  filterCfg[index].cfg.ruleCfg[statementId] &&
                  filterCfg[index].cfg.ruleCfg[statementId].rhs
                    ? filterCfg[index].cfg.ruleCfg[statementId].rhs
                    : ""
                }
                onChange={(e, value) => {
                  handleSelectChange(e, value, "rhs");
                }}
                renderTags={renderTags}
                componentsProps={{
                  paper: {
                    sx: {
                      backgroundColor: "#212b35",
                      color: "#d9d9d9",
                      borderRadius: "4px",
                      boxShadow:
                        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                      "li:hover": {
                        color: "#69c2d2",
                      },
                    },
                  },
                }}
                renderInput={(params) => (
                  <Tooltip
                    title={
                      filterCfg[index].cfg.ruleCfg &&
                      filterCfg[index].cfg.ruleCfg[statementId] &&
                      filterCfg[index].cfg.ruleCfg[statementId].rhs
                        ? filterCfg[index].cfg.ruleCfg[statementId].rhs
                        : ""
                    }
                    placement="right"
                  >
                    <TextField
                      {...params}
                      size="small"
                      label="Selected variable"
                      placeholder="Select option"
                      autoComplete="off"
                      sx={textFieldStyles}
                    />
                  </Tooltip>
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Tooltip title={getOptionLabel(option)} placement="right">
                      <Typography noWrap>{getOptionLabel(option)}</Typography>
                    </Tooltip>
                  </li>
                )}
              />
            ) : (
              <FormControl id="rhs" className="text-field" size="small">
                <TextField
                  type="text"
                  name="rhs"
                  size="small"
                  label="RHS constant"
                  className="w-100"
                  value={
                    filterCfg[index] &&
                    filterCfg[index].cfg.ruleCfg &&
                    filterCfg[index].cfg.ruleCfg[statementId] &&
                    filterCfg[index].cfg.ruleCfg[statementId].rhs
                      ? filterCfg[index].cfg.ruleCfg[statementId].rhs
                      : ""
                  }
                  onChange={handleChange}
                  variant="outlined"
                  autoComplete="off"
                  sx={textFieldStyles}
                  required
                />
              </FormControl>
            )}
          </Stack>
        </Stack>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default RuleBasedFilter;
