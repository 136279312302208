import { React, useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {
  Autocomplete,
  Chip,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReactComponent as FileUploadIcon } from "../../assets/icons/fileUploadIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import "./newProjectDialog.scss";
import api from "../../apiInterceptor";
import {
  buttonStyles,
  textFieldStyles,
  chipTextFieldStyles,
  labelStyles,
} from "./newProjectDialogStyle";
const listfgAPITestData = require("../../assets/apiTestData/listfg-test-data.json");
const fetchfeaturesAPITestData = require("../../assets/apiTestData/fetchfeatures-test-data.json");
const createprojectAPITestData = require("../../assets/apiTestData/createproject-test-data.json");
const importPrjcfgAPITestData = require("../../assets/apiTestData/success-test-data.json");

const NewProjectDialog = ({
  creationType,
  handleExplore,
  projName,
  projVersion,
  projKey,
  projFg,
  versionFgs,
  handleMenuClose,
  listProjects,
}) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const [projectName, setProjectName] = useState("");
  const [projectVersion, setProjectVersion] = useState("");
  const [projectType, setProjectType] = useState("newProject");
  const [featureGroupName, setFeatureGroupName] = useState("");
  const [description, setDescription] = useState("");
  const [uploadedFile, setUploadedFile] = useState("");
  const [allColumns, setAllColumns] = useState([]);
  const [features, setFeatures] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const showCreationDialog = useSelector((state) => state.showCreationDialog);
  const inputRef = useRef(null);
  let selectedFile = useRef(null);
  const dispatch = useDispatch();
  const {
    updateShowCreationDialog,
    updateIsLoading,
    updateAlertMessage,
    updateSnackbarMsg,
  } = bindActionCreators(actionCreators, dispatch);
  const [dialogTitle, setDialogTittle] = useState(null);
  const snackbarMsg = useSelector((state) => state.snackbarMsg);

  useEffect(() => {
    handleMenuClose();
    if (creationType === "project") {
      if (projectType === "importProject" && uploadedFile) {
        setIsSaveDisabled(false);
      } else if (projectType === "newProject" && projectName && description) {
        setIsSaveDisabled(false);
      } else {
        setIsSaveDisabled(true);
      }
    } else if (creationType === "version") {
      if (projectName && description) {
        setIsSaveDisabled(false);
      } else {
        setIsSaveDisabled(true);
      }
    } else {
      if (
        projectName &&
        description &&
        projVersion &&
        features.length > 0 &&
        featureGroupName
      ) {
        setIsSaveDisabled(false);
      } else {
        setIsSaveDisabled(true);
      }
    }
  }, [
    projectName,
    description,
    features,
    featureGroupName,
    projectType,
    uploadedFile,
  ]);

  useEffect(() => {
    initializeDialogTitle();
    initializeProjectNameAndVersion();
    if (creationType === "feature-group") {
      initializeFeatures();
      if (projFg) {
        getSelectedFeatures();
        setFeatureGroupName(projFg);
      }
    }
  }, []);

  const getSelectedFeatures = async () => {
    const apiUrl = BASE_API_URL + "listfg";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = {
      projectKey: projKey,
      projVersion: projVersion,
      projFg: projFg,
    };
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: listfgAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        const fgInfo = result.featureGroups[projFg];
        setFeatures(fgInfo.features);
        setDescription(fgInfo.descr);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
        window.scrollTo(0, 0);
        handleClose();
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const initializeFeatures = async () => {
    const apiUrl = BASE_API_URL + "fetchfeatures";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = {
      projectKey: projKey,
      projVersion: projVersion,
      ds: null,
    };
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: fetchfeaturesAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        const allFeatures = prepareFeatures(result);
        setAllColumns(allFeatures);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
        window.scrollTo(0, 0);
        handleClose();
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const prepareFeatures = (featuresInfo) => {
    let allFeatures = [];
    allFeatures = [
      ...Object.keys(featuresInfo.rowFeatures),
      ...Object.keys(featuresInfo.derivedFeatures),
    ];
    return allFeatures;
  };

  const initializeProjectNameAndVersion = () => {
    if (projName) {
      setProjectName(projName);
    }
    if (projVersion) {
      setProjectVersion(projVersion);
    }
  };

  const initializeDialogTitle = () => {
    if (creationType === "project") {
      setDialogTittle("Create New Project");
    } else if (creationType === "version") {
      setDialogTittle("Create New Version");
    } else if (creationType === "feature-group" && projFg) {
      setDialogTittle("Manage");
    } else {
      setDialogTittle("Create New Feature Group");
    }
  };

  const handleClose = () => {
    updateShowCreationDialog(false);
    updateSnackbarMsg(null);
  };

  const renderTags = (value, getTagProps) =>
    value.map((option, index) => (
      <Tooltip key={option} title={option} placement="top">
        <Chip label={option} {...getTagProps({ index })} />
      </Tooltip>
    ));

  const handleChipsChange = (e, value) => {
    for (let index = 0; index < value.length; index++) {
      if (typeof value[index] === "object") {
        value[index] = value[index]?.value;
      }
    }
    for (let index = 0; index < value.length - 1; index++) {
      if (value[index] === value[value.length - 1]) return;
    }
    setFeatures(value);
  };

  const createProject = async () => {
    const apiUrl = BASE_API_URL + "createproject";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    let payload = {};
    if (creationType === "version") {
      payload = {
        pname: projectName,
        projectKey: projKey,
        description: description,
        ptype: "oda",
      };
    } else {
      payload = {
        pname: projectName,
        description: description,
        ptype: "oda",
      };
    }
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = createprojectAPITestData;
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        handleExplore(
          result.projectKey,
          result.projVersion,
          "base",
          projectName,
          description
        );
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      let errorMessage =
        "Something went wrong. Please contact the administrator";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      updateAlertMessage(errorMessage);
    }
    handleClose();
  };

  const saveUploadedFile = async () => {
    const apiUrl = BASE_API_URL + "import-prjcfg";
    const headers = {};
    let payload = new FormData();
    payload.append("files", selectedFile.current);
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: importPrjcfgAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, {
          headers: headers,
          data: selectedFile.current,
        });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        listProjects(true);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      let errorMessage =
        "Something went wrong. Please contact the administrator";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      updateAlertMessage(errorMessage);
    }
    handleClose();
  };

  const createFeatureGroup = async () => {
    const apiUrl = BASE_API_URL + "configfg";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    let payload = {
      projectKey: projKey,
      projVersion: projVersion,
      description: description,
      features: features,
      projFg: featureGroupName,
      ptype: "oda",
    };
    if (projFg) {
      payload["toModify"] = true;
    }
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: importPrjcfgAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        handleExplore(
          projKey,
          projVersion,
          featureGroupName,
          projectName,
          description
        );
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      let errorMessage =
        "Something went wrong. Please contact the administrator";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      updateAlertMessage(errorMessage);
    }
    handleClose();
  };

  const handleFeatureGroupNameChange = (evt) => {
    if (!/^[a-zA-Z0-9_]*$/.test(evt.target.value)) {
      return;
    }
    setFeatureGroupName(evt.target.value);
  };

  const onInputClick = (event) => {
    event.target.value = "";
  };

  const updateFileDetails = async (newSelectedFile) => {
    selectedFile.current = newSelectedFile;
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileRemove = () => {
    updateFileDetails(null);
    setUploadedFile("");
  };

  const handleFileChange = async (e, fileDropped = false) => {
    let file = null;
    if (fileDropped) {
      e.preventDefault();
      file = e.dataTransfer.files[0];
    } else {
      file = e.target.files[0];
    }
    updateFileDetails(file);
    const newfileDetails = {
      name: file.name,
      type: file.type,
      size: file.size,
    };
    setUploadedFile(newfileDetails);
  };

  const handleSave = () => {
    if (creationType === "project" && projectType === "importProject") {
      saveUploadedFile();
    } else if (creationType === "project" || creationType === "version") {
      createProject();
    } else {
      if (versionFgs.includes(featureGroupName) && !projFg) {
        updateSnackbarMsg(
          "Feature group name already taken.Please enter a unique feature group name"
        );
        return;
      } else {
        createFeatureGroup();
      }
    }
  };

  const getOptionLabel = (option) => {
    if (typeof option === "object") {
      return option.value;
    } else {
      return option;
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    updateSnackbarMsg(null);
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <div>
      <Dialog
        fullWidth
        open={showCreationDialog}
        aria-labelledby="Create New Project"
        className="new-project-dialog"
        PaperProps={{
          style: {
            background: "#212B35",
            border: "1px solid #818181",
            boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            color: "#EFF1F1",
            maxHeight: "calc(100% - 122px)",
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          {dialogTitle}
          <IconButton
            aria-label="close"
            className="close-icon"
            onClick={handleClose}
          >
            <CloseIcon></CloseIcon>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form>
            <Stack spacing={4} alignItems="stretch">
              {creationType === "project" ? (
                <FormControl
                  key="projectType"
                  id="projectType"
                  className="mt-0"
                >
                  <FormLabel
                    id="row-radio-buttons-group-label"
                    sx={labelStyles}
                  >
                    Project Type
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="row-radio-buttons-group-label"
                    name="projectType"
                    value={projectType}
                    onChange={(evt) => setProjectType(evt.target.value)}
                  >
                    <FormControlLabel
                      value="newProject"
                      control={<Radio style={{ color: "#69C2D2" }} />}
                      label="New Project"
                    />
                    <FormControlLabel
                      value="importProject"
                      control={<Radio style={{ color: "#69C2D2" }} />}
                      label="Import Project"
                    />
                  </RadioGroup>
                </FormControl>
              ) : (
                <div></div>
              )}
              {creationType === "project" && projectType === "importProject" ? (
                <Box className="mt-0">
                  <input
                    type="file"
                    onChange={(evt) => handleFileChange(evt)}
                    onClick={onInputClick}
                    className="file-upload-input"
                    ref={inputRef}
                  />
                  <div
                    className="select-file-button"
                    onClick={() => inputRef.current.click()}
                  >
                    <FileUploadIcon className="file-upload-icon"></FileUploadIcon>
                    <span>Select File</span>
                  </div>
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    className="file-upload-area"
                    onDragOver={handleDragOver}
                    onDrop={(evt) => handleFileChange(evt, true)}
                  >
                    <Stack gap="10px">
                      <Stack direction="row" gap="5px">
                        <p>
                          {uploadedFile
                            ? uploadedFile.name
                            : "Drop your files here"}
                        </p>
                        <Tooltip title="Remove file" placement="top">
                          {uploadedFile ? (
                            <Box
                              className="remove-file-icon"
                              onClick={(evt) => handleFileRemove()}
                            />
                          ) : (
                            <Box />
                          )}
                        </Tooltip>
                      </Stack>
                    </Stack>
                    {uploadedFile && <p>{uploadedFile.size} bytes</p>}
                  </Stack>
                </Box>
              ) : (
                <>
                  <FormControl
                    key="pname"
                    id="pname"
                    className="text-field"
                    size="small"
                  >
                    <TextField
                      type="text"
                      name="Project name"
                      label="Project name"
                      value={projectName}
                      size="small"
                      onChange={(evt) => setProjectName(evt.target.value)}
                      variant="outlined"
                      autoComplete="off"
                      sx={textFieldStyles}
                      required
                      inputProps={
                        creationType === "version" ||
                        creationType === "feature-group"
                          ? { readOnly: true }
                          : {}
                      }
                    />
                  </FormControl>
                  {creationType === "feature-group" && (
                    <Stack sx={{ marginTop: "0 !important" }}>
                      <FormControl
                        key="projVersion"
                        id="projVersion"
                        className="text-field"
                        size="small"
                      >
                        <TextField
                          type="text"
                          name="Project version"
                          label="Project version"
                          value={projectVersion}
                          size="small"
                          onChange={(evt) =>
                            setProjectVersion(evt.target.value)
                          }
                          variant="outlined"
                          autoComplete="off"
                          sx={textFieldStyles}
                          required
                          inputProps={
                            creationType === "feature-group"
                              ? { readOnly: true }
                              : {}
                          }
                        />
                      </FormControl>
                      <Autocomplete
                        multiple
                        size="small"
                        id="tags-outlined"
                        sx={{
                          marginTop: "12px",
                        }}
                        options={allColumns}
                        getOptionLabel={(option) => getOptionLabel(option)}
                        disableCloseOnSelect
                        filterSelectedOptions
                        className="chips-dropdown"
                        ListboxProps={{
                          style: { maxHeight: 300, overflow: "auto" },
                        }}
                        value={features}
                        onChange={(e, value) => handleChipsChange(e, value)}
                        renderTags={renderTags}
                        componentsProps={{
                          paper: {
                            sx: {
                              backgroundColor: "#212b35",
                              color: "#d9d9d9",
                              borderRadius: "4px",
                              boxShadow:
                                "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                              "li:hover": {
                                color: "#69c2d2",
                              },
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="Features"
                            placeholder="Features"
                            autoComplete="off"
                            sx={chipTextFieldStyles}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Tooltip
                              title={getOptionLabel(option)}
                              placement="right"
                            >
                              <Typography noWrap>
                                {getOptionLabel(option)}
                              </Typography>
                            </Tooltip>
                          </li>
                        )}
                      />
                      <FormControl
                        key="featureGroupName"
                        id="featureGroupName"
                        className="text-field"
                        size="small"
                      >
                        <TextField
                          type="text"
                          name="Feature group name"
                          label="Feature group name"
                          value={featureGroupName}
                          size="small"
                          onChange={(evt) => handleFeatureGroupNameChange(evt)}
                          variant="outlined"
                          autoComplete="off"
                          sx={textFieldStyles}
                          inputProps={projFg ? { readOnly: true } : {}}
                          required
                        />
                      </FormControl>
                    </Stack>
                  )}
                  <FormControl
                    key="description"
                    id="description"
                    className="text-field"
                    size="small"
                  >
                    <TextField
                      type="text"
                      name="description"
                      label="Description"
                      value={description}
                      size="small"
                      onChange={(evt) => setDescription(evt.target.value)}
                      variant="outlined"
                      autoComplete="off"
                      sx={textFieldStyles}
                      required
                    />
                  </FormControl>
                </>
              )}
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            sx={buttonStyles}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={buttonStyles}
            onClick={handleSave}
            disabled={isSaveDisabled}
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarMsg}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMsg}
        action={action}
      />
    </div>
  );
};

export default NewProjectDialog;
