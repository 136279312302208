import { React, useEffect, useRef, useState } from "react";
import Papa from "papaparse";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { isEmpty } from "lodash";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import "./summaryDialog.scss";
import api from "../../apiInterceptor";
import { Box, Stack, Snackbar } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
const fetchsummaryinfoAPITestData = require("../../assets/apiTestData/fetchsummaryinfo-test-data.json");

const SummaryDialog = ({
  handleDownload,
  getDownloadData,
  dsData,
  getFileName,
  currentNode,
  getConnectedDatasourceId,
}) => {
  const isSummaryDialogOpen = useSelector((state) => state.isSummaryDialogOpen);
  const userAccessType = localStorage.getItem("ACCESS_TYPE");
  const userAccessRestricted = userAccessType === "view";
  const [highlightedColumns, setHighlightedColumns] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [countInfo, setCountInfo] = useState({
    rowCount: "-",
    colCount: "-",
  });
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const [dialogTitle, setDialogTitle] = useState("Sample");
  const dispatch = useDispatch();
  const {
    updateIsLoading,
    updateSummaryDialogStatus,
    updateAlertMessage,
    updateSnackbarMsg,
  } = bindActionCreators(actionCreators, dispatch);
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedFeatureGroup = useSelector(
    (state) => state.selectedFeatureGroup
  );
  const snackbarMsg = useSelector((state) => state.snackbarMsg);
  let maxApiCalls = useRef(1);
  let downloadCalls = useRef(1);
  let columnsToShow = useRef([]);
  const dataGridStyles = {
    "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-footerContainer": {
      background: "#4b5362",
    },
    "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-footerContainer, & .MuiTablePagination-root, & .MuiDataGrid-row":
      {
        color: "rgba(255, 255, 255)",
      },
    "& .MuiDataGrid-row:nth-of-type(even)": {
      background: "#181a1c",
    },
    "& .MuiDataGrid-row:nth-of-type(odd)": {
      background: "#0e0f10",
    },
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within":
      {
        outline: "none !important",
      },
  };
  const dataGridCustomMessage = {
    noRowsLabel: "No sample to show.",
  };
  const buttonStyles = {
    color: "white",
    background: "#69c2d2",
    fontWeight: "600",
    fontSize: "13px",
    lineHeight: "22px",
    textTransform: "none",
    ":hover": {
      background: "#40bcd2",
    },
    ":disabled": {
      background: "rgb(213 205 205 / 59%)",
    },
  };

  useEffect(() => {
    getDialogTitle();
    getCSVFile();
  }, []);

  const getDialogTitle = () => {
    let name;
    if (!isEmpty(dsData)) {
      name = dsData.dataName;
    } else {
      name = getFileName();
    }
    const title = `${name} Sample`;
    setDialogTitle(title);
  };

  const filterColumns = (parsedData) => {
    const headerRow = parsedData[0];
    const indicesToShow = columnsToShow.current.map((column) =>
      headerRow.indexOf(column)
    );
    const filteredParsedData = parsedData.map((row) =>
      row.filter((_, columnIndex) => indicesToShow.includes(columnIndex))
    );
    return filteredParsedData;
  };

  const prepareRowsAndColumns = (filteredParsedData) => {
    const clonedColumns = structuredClone(tableColumns);
    const clonedRows = structuredClone(tableRows);
    filteredParsedData[0].forEach((element) => {
      clonedColumns.push({
        field: element,
        headerName: element,
        minWidth: calculateWidth(element),
        sortable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        headerClassName: highlightedColumns.includes(element)
          ? "summary-table-highlighted-header-column"
          : "",
      });
    });
    filteredParsedData.forEach((element, rowIndex) => {
      let rowInfo = {};
      if (rowIndex > 0) {
        if (clonedRows[rowIndex - 1]) {
          filteredParsedData[0].forEach((col, colIndex) => {
            clonedRows[rowIndex - 1][col] = element[colIndex];
          });
        } else {
          rowInfo["id"] = rowIndex;
          element.forEach((item, colIndex) => {
            if (clonedColumns[colIndex]) {
              rowInfo[clonedColumns[colIndex].field] = item;
            }
          });
          clonedRows.push(rowInfo);
        }
      }
    });
    setTableRows(clonedRows);
    setTableColumns(clonedColumns);
  };

  const calculateWidth = (fieldValue) => {
    const fieldLength = fieldValue.length;
    let charWidthFactor = 9;
    if (fieldLength < 10) {
      charWidthFactor = 11;
    }
    const calculatedWidth = fieldLength * charWidthFactor + 20;
    return calculatedWidth;
  };

  const getCSVFile = async () => {
    try {
      updateIsLoading(true);
      const downloadResponse = await getDownloadData("sample", 0);
      const response = downloadResponse.response;
      if (response?.data) {
        const parsedData = await new Promise((resolve) => {
          Papa.parse(response.data, {
            complete: (result) => {
              resolve(result.data);
            },
          });
        });
        await fetchsummaryinfo();
        const filteredParsedData = filterColumns(parsedData);
        prepareRowsAndColumns(filteredParsedData);
        updateIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateSnackbarMsg(errorMessage);
    }
  };

  const fetchsummaryinfo = async () => {
    try {
      const apiUrl = BASE_API_URL + "fetchsummaryinfo";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      let ds = null;
      let stageId = null;
      let payload = {};
      if (!isEmpty(dsData)) {
        ds = dsData.dsKey;
        payload = {
          projectKey: dsData.projectKey,
          projVersion: dsData.projVersion,
          projFg: dsData.projFg,
        };
      } else {
        if (!currentNode.data.isStage) {
          ds = currentNode.id;
        } else {
          ds = getConnectedDatasourceId(currentNode);
          stageId = currentNode.id;
        }
        payload = {
          projectKey: selectedProjectKey,
          projVersion: selectedProjVersion,
          projFg: selectedFeatureGroup,
        };
      }
      payload["dSource"] = ds;
      payload["stageId"] = stageId;
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: fetchsummaryinfoAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, {
          headers: headers,
          withCredentials: true,
        });
      }
      if (response.data.status === 200) {
        let result = response.data.data.posts[0];
        maxApiCalls.current = result.partitionCount ? result.partitionCount : 1;
        if (result) {
          setCountInfo({
            rowCount: result.rowCount,
            colCount: result.colCount,
          });
          setHighlightedColumns(result.newCols);
          columnsToShow.current = result.columns;
        }
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const handleClose = () => {
    updateSummaryDialogStatus(false);
    updateSnackbarMsg(null);
  };

  const loadMore = async () => {
    updateIsLoading(true);
    const downloadResponse = await getDownloadData(
      "sample",
      downloadCalls.current
    );
    const response = downloadResponse.response;
    if (response?.data) {
      const parsedData = await new Promise((resolve) => {
        Papa.parse(response.data, {
          complete: (result) => {
            resolve(result.data);
          },
        });
      });
      const filteredParsedData = filterColumns(parsedData);
      prepareRowsAndColumns(filteredParsedData);
      updateIsLoading(false);
      downloadCalls.current++;
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    updateSnackbarMsg(null);
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xxl"
        open={isSummaryDialogOpen}
        aria-labelledby="Delete"
        className="summary-dialog"
        PaperProps={{
          style: {
            background: "#212B35",
            border: "1px solid #818181",
            boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            color: "#EFF1F1",
            maxHeight: "calc(100% - 122px)",
          },
        }}
      >
        <DialogTitle id="summary-title">
          <span>{dialogTitle}</span>
          <div className="icons-container">
            <Tooltip title="Download" placement="top">
              <Button
                className={`btn download-btn ${
                  userAccessRestricted ? "disabled" : ""
                }`}
                onClick={() => handleDownload("sample")}
                disabled={userAccessRestricted}
              ></Button>
            </Tooltip>
            <IconButton
              aria-label="close"
              className="close-icon"
              onClick={handleClose}
            >
              <CloseIcon></CloseIcon>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              marginBottom="15px"
            >
              <div className="count-info-container">
                <div className="count-info-row first-row">
                  <div className="count-info-first-col"># of Rows </div>
                  <div className="count-info-second-col">
                    {countInfo?.rowCount}
                  </div>
                </div>
                <div className="count-info-row">
                  <div className="count-info-first-col"># of Columns </div>
                  <div className="count-info-second-col">
                    {countInfo?.colCount}
                  </div>
                </div>
              </div>
              <Button
                variant="contained"
                onClick={loadMore}
                autoFocus
                sx={buttonStyles}
                disabled={downloadCalls.current >= maxApiCalls.current}
              >
                Load more
              </Button>
            </Stack>
            <Box
              className="summary-table-container"
              sx={{ height: 400, width: "100%" }}
            >
              <DataGrid
                rows={tableRows}
                columns={tableColumns}
                disableColumnMenu
                disableRowSelectionOnClick
                autoColumnSize
                sx={dataGridStyles}
                localeText={dataGridCustomMessage}
              />
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarMsg}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMsg}
        action={action}
      />
    </div>
  );
};
export default SummaryDialog;
