const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#D9D9D9",
    },
    "&:hover fieldset": {
      borderColor: "#69C2D2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#69C2D2",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#D9D9D9",
  },
  "& .MuiInputLabel-root.Mui-error": {
    color: "#d32f2f",
  },
  "& .MuiInputLabel-outlined.Mui-focused": {
    color: "#69C2D2",
    "& .MuiInputLabel-asterisk": {
      color: "#69C2D2",
    },
  },
  "& .MuiInputLabel-outlined.Mui-focused.Mui-error": {
    color: "#69C2D2",
  },
  "& .MuiInputBase-root": {
    color: "#D9D9D9",
    height: "2rem",
    marginTop: "5px",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#D9D9D9",
  },
  svg: {
    fill: "#D9D9D9",
  },
};
const menuStyles = {
  boxShadow: 0,
  color: "#D9D9D9",
  "&:hover, &.Mui-focused, &.MuiSelect-nativeOpen": {
    color: "#D9D9D9",
    borderColor: "#D9D9D9",
  },
  "&:hover": {
    "&& fieldset": {
      borderColor: "#69C2D2",
    },
  },
  ul: {
    backgroundColor: "#212B35",
    li: {
      "&:hover": { color: "#69C2D2" },
      "&:selected": { backgroundColor: "none" },
    },
  },
  svg: {
    fill: "#D9D9D9",
  },
  fieldset: {
    borderColor: "#D9D9D9",
    "&:hover, &.Mui-focused": {
      borderColor: "#D9D9D9",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#D9D9D9",
    borderWidth: "1px",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#69C2D2",
    borderWidth: "1px",
  },
};

const menuItemStyles = {
  color: "#D9D9D9",
  backgroundColor: "#212B35",
  "&.Mui-selected": {
    color: "#69C2D2",
  },
  "&:hover": {
    color: "#D9D9D9",
  },
};

const buttonStyles = {
  color: "white",
  background: "#69c2d2",
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "24px",
  textTransform: "none",
  ":hover": {
    background: "#40bcd2",
  },
  ":disabled": {
    background: "rgb(213 205 205 / 59%)",
    opacity: 0.5
  },
};

const actionButtonStyles = {
  background: "transparent",
  textTransform: "none",
  marginRight: "5px",
  minWidth: "fit-content",
  padding: "5px",
  ":disabled": {
    opacity: "0.5",
  },
};

export {
  menuStyles,
  menuItemStyles,
  buttonStyles,
  actionButtonStyles,
  textFieldStyles,
};
