import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

const CustomTooltipComponent = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: 11,
      maxHeight: '300px',
      overflowY: 'auto',
      background: '#484848'
    },
  }));

const CustomTooltip = ({ title, children }) => {
  const multipleLineTitle = title?.split('\n');

  const getTitle = () => {
    return (
      <div>
        {multipleLineTitle?.map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </div>
    )
  };

  return (
    <CustomTooltipComponent title={getTitle()}>
      {children}
    </CustomTooltipComponent>
  )
}

export default CustomTooltip;