import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  Autocomplete,
  Chip,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch } from "react-redux";
import "./configureFeatureMap.scss";
import api from "../../apiInterceptor";
import { textFieldStyles } from "./configureFeatureMapStyle";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import DateFeatureDialog from "components/dateFeatureDialog/dateFeatureDialog";
const fetchdatafeaturemapAPITestData = require("../../assets/apiTestData/fetchdatafeaturemap-test-data.json");
const perffeatmapAPITestData = require("../../assets/apiTestData/success-test-data.json");

const ConfigureFeatureMap = ({ selectedDatasource, dsData, goBack }) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const [savedFeatures, setSavedFeatures] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [rawFeatures, setRawFeatures] = useState([]);
  const [featuresList, setFeaturesList] = useState([]);
  const [featuresInfo, setFeaturesInfo] = useState({});
  const [dateDialogOpen, setDateDialogOpen] = useState(false);
  const [dateTypeFeatureList, setDateTypeFeatureList] = useState([]);
  const dispatch = useDispatch();
  const { updateIsLoading, updateSnackbarMsg } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const buttonStyles = {
    color: "white",
    background: "#69c2d2",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "28px",
    textTransform: "none",
    ":hover": {
      background: "#40bcd2",
    },
    ":disabled": {
      background: "rgb(213 205 205 / 59%)",
    },
  };

  useEffect(() => {
    setFeaturesInfo(dsData.featuresInfo || {});
    if (dsData.featuresInfo) {
      setFeaturesList(Object.keys(dsData.featuresInfo) || []);
    }
    getFeatureMapData();
  }, []);

  useEffect(() => {
    if (featuresList.length > 0 && Object.keys(featuresInfo).length > 0) {
      const dateFeatures = featuresList.filter(
        (feature) => featuresInfo[feature]?.dataType === "Date"
      );
      setDateTypeFeatureList(dateFeatures);
    }
  }, [featuresList, featuresInfo]);

  useEffect(() => {
    handleSaveButtonDisabling();
  }, [savedFeatures]);

  const handleSaveButtonDisabling = () => {
    let saveDisabled = false;
    for (let feature of featuresList) {
      if (!savedFeatures[feature]) {
        saveDisabled = true;
        break;
      }
    }
    setIsSaveDisabled(saveDisabled);
  };

  const handleSave = () => {
    if (dateTypeFeatureList.length === 0) {
      saveFeatureMapData();
    } else {
      setDateDialogOpen(true);
    }
  };

  const getFeatureMapData = async () => {
    try {
      const apiUrl = BASE_API_URL + "fetchdatafeaturemap";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        dSource: dsData.dsKey,
        projFg: dsData.projFg,
        projVersion: dsData.projVersion,
        projectKey: dsData.projectKey,
      };
      let response = {};
      updateIsLoading(true);
      if (USING_TEST_DATA) {
        response = {
          data: fetchdatafeaturemapAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        // Check if 'rawFeatures' exists in the feature configuration
        if (result.featureCfg.rawFeatures) {
          // Check if 'rawFeatures' is a non-empty array
          if (result.featureCfg.rawFeatures.length > 0) {
            // Set the state 'rawFeatures' with the array of raw features
            setRawFeatures(result.featureCfg.rawFeatures);
            // Reset 'savedFeatures' state to an empty object
            setSavedFeatures({});
          } else {
            // If 'rawFeatures' array is empty, navigate back to the previous page
            goBack();
            updateSnackbarMsg("No features available for selection.");
          }
        } else {
          // If 'rawFeatures' does not exist, assume the features are already saved
          // Set 'savedFeatures' state with the feature configuration object
          setSavedFeatures(result.featureCfg);
          // Prepare raw features based on the feature configuration
          prepareRawFeatures(result.featureCfg);
        }
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateSnackbarMsg(response.data.data.reason);
        } else {
          updateSnackbarMsg("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      updateIsLoading(false);
      console.error(error);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateSnackbarMsg(errorMessage);
    }
  };

  const prepareRawFeatures = (cfg) => {
    const features = [];
    for (const feature in cfg) {
      if (cfg.hasOwnProperty(feature)) {
        features.push(cfg[feature]);
      }
    }
    setRawFeatures(features);
  };

  const handleFeatureChange = (e, feature, newValue) => {
    const newSavedFeatures = { ...savedFeatures, [feature]: newValue };
    setSavedFeatures(newSavedFeatures);
  };

  const handleFeatureClear = (feature) => {
    const newSavedFeatures = { ...savedFeatures };
    delete newSavedFeatures[feature];
    setSavedFeatures(newSavedFeatures);
  };

  const saveFeatureMapData = async () => {
    try {
      const apiUrl = BASE_API_URL + "perffeatmap";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        dSource: dsData.dsKey,
        projFg: dsData.projFg,
        projVersion: dsData.projVersion,
        projectKey: dsData.projectKey,
        featureCfg: savedFeatures,
      };
      let response = {};
      updateIsLoading(true);
      if (USING_TEST_DATA) {
        response = {
          data: perffeatmapAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        goBack();
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateSnackbarMsg(response.data.data.reason);
        } else {
          updateSnackbarMsg("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      updateIsLoading(false);
      console.error(error);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateSnackbarMsg(errorMessage);
    }
  };

  const getFilteredFeatures = (currentFeature) => {
    return rawFeatures.filter(
      (rawFeature) =>
        !Object.values(savedFeatures).includes(rawFeature) ||
        savedFeatures[currentFeature] === rawFeature
    );
  };

  const GroupHeader = styled("div")(({ theme, padding = "4px 10px" }) => ({
    position: "sticky",
    top: "-8px",
    zIndex: 10000,
    padding: padding,
    color: "#FFFFFF",
    backgroundColor: "rgb(68 79 91)",
  }));

  const GroupItems = styled("ul")({
    padding: 0,
  });

  return (
    <div className="feature-map-table-container">
      <Stack className="table">
        <Stack className="table-header" direction="row">
          <Box
            className="header-item"
            sx={{ width: "40%", paddingLeft: "8px !important" }}
          >
            Common Data Model Features
          </Box>
          <Box className="header-item" sx={{ width: "20%" }}>
            Data Type
          </Box>
          <Box className="header-item" sx={{ width: "40%" }}>
            User Features
          </Box>
        </Stack>
        <div className="table-content">
          {featuresList.length > 0 ? (
            featuresList.map((feature) => (
              <Stack key={feature} className="table-row" direction="row">
                <Box
                  className="row-item"
                  sx={{ width: "40%", paddingLeft: "8px !important" }}
                >
                  <Tooltip title={feature} placement="top-start">
                    <Typography noWrap sx={{ fontSize: "13px" }}>
                      {feature}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box className="row-item" sx={{ width: "20%" }}>
                  <Tooltip
                    title={featuresInfo[feature].dataType}
                    placement="top-start"
                  >
                    <Typography noWrap sx={{ fontSize: "13px" }}>
                      {featuresInfo[feature].dataType}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box className="row-item" sx={{ width: "38%" }}>
                  <Autocomplete
                    autoHighlight
                    size="small"
                    id="tags-outlined"
                    options={getFilteredFeatures(feature)}
                    autoComplete
                    includeInputInList
                    ListboxProps={{
                      style: { maxHeight: 300, overflow: "auto" },
                    }}
                    value={savedFeatures[feature] || null}
                    onChange={(event, newValue) =>
                      handleFeatureChange(event, feature, newValue)
                    }
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          key={index}
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    groupBy={(option) => option.title}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader padding={"0px"}>
                          {params.group}
                        </GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                    componentsProps={{
                      paper: {
                        sx: {
                          backgroundColor: "#212b35",
                          color: "#d9d9d9",
                          borderRadius: "4px",
                          boxShadow:
                            "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                          "li:hover": {
                            color: "#69c2d2",
                          },
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <Tooltip
                        title={savedFeatures[feature] || ""}
                        placement="right"
                      >
                        <TextField
                          {...params}
                          size="small"
                          label={"Select feature"}
                          placeholder="Select option"
                          autoComplete="off"
                          sx={textFieldStyles}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <IconButton
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                }}
                                onClick={() => handleFeatureClear(feature)}
                                size="small"
                              >
                                <CloseIcon />
                              </IconButton>
                            ),
                          }}
                        />
                      </Tooltip>
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Tooltip title={option} placement="right">
                          <Typography noWrap>{option}</Typography>
                        </Tooltip>
                      </li>
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option === value || value === ""
                    }
                  />
                </Box>
              </Stack>
            ))
          ) : (
            <Box className="empty-text">Information Not available</Box>
          )}
        </div>
      </Stack>
      <Stack
        direction="row"
        justifyContent="end"
        alignItems="center"
        gap="8px"
        marginTop="10px"
      >
        <Button
          variant="contained"
          sx={buttonStyles}
          disabled={isSaveDisabled}
          onClick={handleSave}
          autoFocus
        >
          Save
        </Button>
        <Button
          autoFocus
          variant="contained"
          sx={buttonStyles}
          onClick={goBack}
        >
          Cancel
        </Button>
      </Stack>

      {dateDialogOpen && (
        <DateFeatureDialog
          dateDialogOpen={dateDialogOpen}
          dateTypeFeatureList={dateTypeFeatureList}
          setDateDialogOpen={setDateDialogOpen}
          dsData={dsData}
          featuresInfo={featuresInfo}
          saveFeatureMapData={saveFeatureMapData}
        />
      )}
    </div>
  );
};

export default ConfigureFeatureMap;
