const toggleButtonStyles = {
  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
    backgroundColor: "#69C2D2",
    opacity: "0.5",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#69C2D2",
  },
  ".Mui-disabled": {
    color: "#444950 !important",
  }
};

export { toggleButtonStyles };
