/* eslint-disable import/no-anonymous-default-export */
import { nodeList, nodeSections } from "assets/dev-config/nodeMap";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevronDown.svg";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import api from "../../apiInterceptor";
import { isEmpty } from "lodash";
import "./sidebar.scss";

const IMG = (imgName) => {
  return require(`../../assets/icons/${imgName}`);
};
const sidebarCfgTestdata = require("../../assets/testdata/project_cfg.json");
const getprojcfgAPITestData = require("../../assets/apiTestData/sidebar-getprojcfg-test-data.json");

const toggleButtonStyles = {
  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
    backgroundColor: "#69C2D2",
    opacity: "0.5",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#69C2D2",
  },
  "& .MuiSwitch-track": {
    backgroundColor: "#343b45",
    opacity: "1",
  },
};

const Sidebar = () => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const userAccessType = localStorage.getItem("ACCESS_TYPE");
  const userAccessRestricted = userAccessType === "view";
  const [sidebarCfg, setSidebarCfg] = useState(null);
  const [nodeMode, setNodeMode] = useState("Batch");
  const param = useParams();
  const projectKey = param.projectKey;
  const projVersion = param.projVersion;
  const projFg = param.projFg;
  const dispatch = useDispatch();
  const { updateIsPlaygroundLoading, updateAlertMessage } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const isPlaygroundLoading = useSelector((state) => state.isPlaygroundLoading);
  const isPolling = useSelector((state) => state.isPolling);
  const shortMsgTooltipData = useSelector((state) => state.shortMsgTooltipData);

  useEffect(() => {
    initializeSidebar();
  }, []);

  const initializeSidebar = async () => {
    const apiUrl = BASE_API_URL + "getprojcfg";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = {
      projectKey: projectKey,
      projVersion: projVersion,
      projFg: projFg,
    };
    const showLoader = !isPlaygroundLoading && !isPolling;
    if (showLoader) {
      updateIsPlaygroundLoading(true);
    }
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: getprojcfgAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      if (showLoader) {
        updateIsPlaygroundLoading(false);
      }
      if (response.data.status === 200) {
        let result = response.data.data.posts[0];
        if (isEmpty(result)) {
          setSidebarCfg(sidebarCfgTestdata);
        } else {
          setSidebarCfg(result);
        }
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      if (showLoader) {
        updateIsPlaygroundLoading(false);
      }
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
      console.log(error);
    }
  };

  const onDragStart = (
    event,
    nodeType,
    nodeClass,
    modalType,
    nodeKind,
    isStage,
    allowOneIncomingEdge
  ) => {
    if (userAccessRestricted) return;
    event.dataTransfer.setData("nodeType", nodeType);
    event.dataTransfer.setData("nodeClass", nodeClass);
    event.dataTransfer.setData("modalType", modalType);
    event.dataTransfer.setData("nodeKind", nodeKind);
    event.dataTransfer.setData("isStage", isStage);
    event.dataTransfer.setData("allowOneIncomingEdge", allowOneIncomingEdge);
    event.dataTransfer.effectAllowed = "move";
  };

  const handleToggleChange = (evt) => {
    const checked = evt.target.checked;
    const value = checked ? "RealTime" : "Batch";
    setNodeMode(value);
  };

  return (
    <div className="sidebar-container">
      <Stack direction="row" spacing={2} marginBottom={1} alignItems="center">
        <Typography color={"#EFF1F1"}>Batch</Typography>
        <Switch
          onChange={handleToggleChange}
          aria-labelledby="row-toggle-button-label"
          sx={toggleButtonStyles}
          checked={nodeMode === "RealTime"}
        />
        <Typography color={"#EFF1F1"}>Real Time</Typography>
      </Stack>
      <aside>
        {nodeSections.map((section, i) => {
          return (
            sidebarCfg &&
            sidebarCfg[section.id] &&
            sidebarCfg[section.id].enable && (
              <Accordion
                key={section.id}
                sx={{
                  background: "#151c24;",
                  color: "rgb(239, 241, 241) !important",
                  marginTop: "10px",
                }}
                defaultExpanded
              >
                <AccordionSummary
                  expandIcon={<ChevronDown className="icon" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="accordion-header"
                >
                  <Typography>{sidebarCfg[section.id].name}</Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: "#1e262f",
                    padding: "8px",
                  }}
                >
                  <div className="nodes-container">
                    {sidebarCfg[section.id] &&
                      nodeList
                        .filter((node) => node.section === section.id)
                        .map((node, i) => {
                          if (node.isDraggable) {
                            return (
                              sidebarCfg[section.id].menus[node.nodeType] &&
                              sidebarCfg[section.id].menus[node.nodeType]
                                .enable &&
                              (!sidebarCfg[section.id].menus[node.nodeType]
                                .mode ||
                                sidebarCfg[section.id].menus[
                                  node.nodeType
                                ]?.mode?.includes(nodeMode)) && (
                                <div
                                  key={node.nodeType}
                                  onDragStart={(event) =>
                                    onDragStart(
                                      event,
                                      node.nodeType,
                                      node.nodeClass,
                                      node.modalType,
                                      node.nodeKind,
                                      node.isStage,
                                      node.allowOneIncomingEdge
                                    )
                                  }
                                  draggable
                                >
                                  <Tooltip
                                    title={
                                      shortMsgTooltipData[node.nodeType]
                                        ? shortMsgTooltipData[node.nodeType]
                                        : ""
                                    }
                                    placement="top"
                                  >
                                    <div className="dndnode">
                                      <img
                                        src={IMG(node.icon)}
                                        alt={node.label}
                                        className="icon"
                                      ></img>
                                      {/* <CsvFileIcon className="icon"></CsvFileIcon> */}
                                      <span>{node.label}</span>
                                    </div>
                                  </Tooltip>
                                </div>
                              )
                            );
                          } else {
                            return (
                              sidebarCfg[section.id].menus[node.nodeType] &&
                              sidebarCfg[section.id].menus[node.nodeType]
                                .enable && (
                                <div key={node.nodeType} className="dndnode">
                                  <img
                                    src={IMG(node.icon)}
                                    alt={node.label}
                                    className="icon"
                                  ></img>
                                  {/* <CsvFileIcon className="icon"></CsvFileIcon> */}
                                  <span>{node.label}</span>
                                </div>
                              )
                            );
                          }
                        })}
                  </div>
                </AccordionDetails>
              </Accordion>
            )
          );
        })}
      </aside>
    </div>
  );
};

export default Sidebar;
