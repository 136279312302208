import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {
  Autocomplete,
  Chip,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import "./partialExecutionDialog.scss";
import { buttonStyles, textFieldStyles } from "./partialExecutionDialogStyles";

const PartialExecutionDialog = ({
  currentDs,
  currentStage,
  runConfiguration,
}) => {
  const [dsOptions, setDsOptions] = useState([]);
  const showPartialExecutionDialog = useSelector(
    (state) => state.showPartialExecutionDialog
  );
  const pipeline = useSelector((state) => state.pipeline);
  const snackbarMsg = useSelector((state) => state.snackbarMsg);
  const startDs = useSelector((state) => state.startDs);

  const dispatch = useDispatch();
  const { updateShowPartialExecutionDialog, updateSnackbarMsg, updateStartDs } =
    bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    prepareDropdownOptions();
  }, []);

  const prepareDropdownOptions = () => {
    const dataSources = structuredClone(pipeline.dataSourceOrder);
    let requiredDataSources = dataSources.slice(
      0,
      dataSources.indexOf(currentDs)
    );
    if (requiredDataSources.length === 0) {
      requiredDataSources.push(currentDs);
    }
    const nodesInfo = pipeline.clientMetaData.flow.nodes;
    let completedNodes = 0;
    requiredDataSources = requiredDataSources.filter((ds) => {
      const currentNode = nodesInfo.find((nd) => nd.id === ds);
      if (currentNode.className.includes("completed")) {
        completedNodes++;
        return true;
      } else if (
        currentNode.data.nodeType === "fileUpload" &&
        !currentNode.className.includes("error")
      ) {
        return true;
      }
      return false;
    });
    if (
      requiredDataSources.length !== 0 &&
      requiredDataSources.length === completedNodes &&
      !requiredDataSources.includes(currentDs)
    ) {
      requiredDataSources.push(currentDs);
    }
    setDsOptions(requiredDataSources);
    if (requiredDataSources.length === 0) {
      updateSnackbarMsg("No datasource is present prior to current datasource");
    }
  };

  const handleClose = (emptyStartDs = false) => {
    updateShowPartialExecutionDialog(false);
    updateSnackbarMsg(null);
    if (emptyStartDs) {
      updateStartDs(null);
    }
  };

  const handleSubmit = () => {
    if (!currentStage) {
      runConfiguration(currentDs, null);
    } else {
      runConfiguration(currentDs, currentStage);
    }
    handleClose();
  };

  const getOptionLabel = (option) => {
    if (dsOptions.includes(option)) {
      return pipeline.clientMetaData.dsMap[option];
    } else {
      return option;
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    updateSnackbarMsg(null);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const renderTags = (value, getTagProps) =>
    value.map((option, index) => (
      <Tooltip key={option} title={option} placement="top">
        <Chip label={option} {...getTagProps({ index })} />
      </Tooltip>
    ));

  return (
    <div>
      <Dialog
        fullWidth
        open={showPartialExecutionDialog}
        aria-labelledby="Partial Execution"
        className="partial-execution-dialog"
        PaperProps={{
          style: {
            background: "#212B35",
            border: "1px solid #818181",
            boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            color: "#EFF1F1",
            maxHeight: "calc(100% - 122px)",
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          {"Partial Execution"}
          <IconButton
            aria-label="close"
            className="close-icon"
            onClick={() => handleClose(true)}
          >
            <CloseIcon></CloseIcon>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form>
            <Stack>
              <Stack>
                <Autocomplete
                  key={"startDs"}
                  autoHighlight
                  size="small"
                  id="tags-outlined"
                  options={dsOptions}
                  getOptionLabel={(option) => getOptionLabel(option)}
                  autoComplete
                  includeInputInList
                  disableClearable
                  className="select-dropdown"
                  ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
                  isOptionEqualToValue={(option, value) => {
                    return (
                      pipeline.clientMetaData.dsMap[option] === value ||
                      value === ""
                    );
                  }}
                  value={
                    startDs ? pipeline.clientMetaData.dsMap[startDs] : null
                  }
                  onChange={(e, value) => {
                    updateStartDs(value);
                  }}
                  renderTags={renderTags}
                  componentsProps={{
                    paper: {
                      sx: {
                        backgroundColor: "#212b35",
                        color: "#d9d9d9",
                        borderRadius: "4px",
                        boxShadow:
                          "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                        "li:hover": {
                          color: "#69c2d2",
                        },
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <Tooltip
                      title={pipeline.clientMetaData.dsMap[startDs]}
                      placement="right"
                    >
                      <TextField
                        {...params}
                        size="small"
                        label="Start offset datasource"
                        placeholder="Select option"
                        autoComplete="off"
                        sx={textFieldStyles}
                      />
                    </Tooltip>
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Tooltip title={getOptionLabel(option)} placement="right">
                        <Typography noWrap>{getOptionLabel(option)}</Typography>
                      </Tooltip>
                    </li>
                  )}
                />
              </Stack>
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            sx={buttonStyles}
            onClick={() => handleClose(true)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={buttonStyles}
            onClick={handleSubmit}
            autoFocus
          >
            Start
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarMsg}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMsg}
        action={action}
      />
    </div>
  );
};

export default PartialExecutionDialog;
