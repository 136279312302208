import React from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const CpuUsageGraph = () => {
  const cpuUsageInfo = useSelector((state) => state.cpuUsageInfo);
  const options = {
    chart: {
      id: "area-chart",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      axisBorder: {
        color: "#757575",
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      forceNiceScale: true,
      tickAmount: 3,
      min: 0,
      max: 100,
      axisBorder: {
        show: true,
        color: "#757575",
      },
      axisTicks: {
        show: true,
        color: "#757575",
      },
      labels: {
        style: {
          colors: "#757575",
        },
        formatter: (value) => {
          return Math.round(value).toString();
        },
      },
    },
    tooltip: {
      theme: "dark",
      x: {
        show: false,
      },
      marker: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      colors: ["#70cb05"],
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 1,
        opacityTo: 0,
        stops: [0, 100],
      },
    },
    stroke: {
      curve: "straight",
      colors: ["#70cb05"],
      width: 2,
    },
    grid: {
      show: false,
    },
    height: 250,
  };

  const series = [
    {
      name: "cpu usage (%)",
      data: cpuUsageInfo,
    },
  ];

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height={250}
    />
  );
};

export default CpuUsageGraph;
