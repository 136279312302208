const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#D9D9D9",
    },
    "&:hover fieldset": {
      borderColor: "#69C2D2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#69C2D2",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#D9D9D9",
  },
  "& .MuiInputLabel-root.Mui-error": {
    color: "#d32f2f",
  },
  "& .MuiInputLabel-outlined.Mui-focused": {
    color: "#69C2D2",
    "& .MuiInputLabel-asterisk": {
      color: "#69C2D2",
    },
  },
  "& .MuiInputLabel-outlined.Mui-focused.Mui-error": {
    color: "#69C2D2",
  },
  "& .MuiInputBase-root": {
    color: "#D9D9D9",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#D9D9D9",
  },
  svg: {
    fill: "#D9D9D9",
  },
};

const formControlStyles = {
  "& .MuiInputLabel-root, & .MuiOutlinedInput-root": {
    color: "#D9D9D9",
  },
};

const selectStyles = {
  color: "#D9D9D9",
  "&:hover, &.Mui-focused, &.MuiSelect-nativeOpen": {
    color: "#D9D9D9",
    borderColor: "#D9D9D9",
  },
  "&:hover": {
    "&& fieldset": {
      borderColor: "#69C2D2",
    },
  },
  ul: {
    maxWidth: "300px !important",
    maxHeight: "600px",
    backgroundColor: "#212B35",
    li: {
      "&:hover": { color: "#69C2D2" },
      "&:selected": { backgroundColor: "none" },
      maxWidth: "300px !important",
    },
  },
  svg: {
    fill: "#D9D9D9",
  },
  fieldset: {
    borderColor: "#D9D9D9",
    "&:hover, &.Mui-focused": {
      borderColor: "#D9D9D9",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#D9D9D9",
    borderWidth: "1px",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#69C2D2",
    borderWidth: "1px",
  },
};

const labelStyles = {
  color: "#69C2D2",
  "&.Mui-focused": {
    color: "#69C2D2",
  },
};

const menuItemStyles = {
  color: "#D9D9D9",
  backgroundColor: "#212B35",
  "&.Mui-selected": {
    color: "#69C2D2",
  },
  "&:hover": {
    color: "#D9D9D9",
  },
};

const toggleButtonStyles = {
  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
    backgroundColor: "#69C2D2",
    opacity: "0.5",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#69C2D2",
  },
};
export {
  formControlStyles,
  labelStyles,
  menuItemStyles,
  selectStyles,
  textFieldStyles,
  toggleButtonStyles,
};
