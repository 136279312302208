const buttonStyles = {
  color: "white",
  background: "#69c2d2",
  fontWeight: "600",
  fontSize: "18px",
  lineHeight: "28px",
  textTransform: "none",
  ":hover": {
    background: "#40bcd2",
  },
  ":disabled": {
    background: "rgb(213 205 205 / 59%)",
  },
};

const checkboxStyles = {
  color: "#69C2D2 !important",
};

const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    "&.Mui-error fieldset": {
      borderColor: "#D9D9D9",
    },
    "& fieldset": {
      borderColor: "#D9D9D9",
    },
    "&:hover fieldset": {
      borderColor: "#69C2D2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#69C2D2",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#D9D9D9",
  },
  "& .MuiInputLabel-root.Mui-error": {
    color: "#D9D9D9",
  },
  "& .MuiInputLabel-outlined.Mui-focused": {
    color: "#69C2D2",
    "& .MuiInputLabel-asterisk": {
      color: "#69C2D2",
    },
  },
  "& .MuiInputLabel-outlined.Mui-focused.Mui-error": {
    color: "#69C2D2",
  },
  "& .MuiInputBase-root": {
    color: "#D9D9D9",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#D9D9D9",
  },
  "& .Mui-disabled ": {
    "-webkit-text-fill-color": "#D9D9D9 !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D9D9D9 !important",
    },
  },
  svg: {
    fill: "#D9D9D9",
  },
};

const datePickerStyles = {
  "& .MuiPickersCalendarHeader-label ": {
    fontSize: "14px",
  },
  "& .MuiPickersFadeTransitionGroup-root ": {
    maxHeight: "145px",
  },
  "& .MuiYearCalendar-root  ": {
    maxHeight: "145px",
  },
  "& .MuiDateCalendar-root": {
    color: "#69C2D2",
    borderColor: "#69C2D2",
    backgroundColor: "#212B35",
    height: "fit-content",
  },
  "& .MuiDialogActions-root": {
    backgroundColor: "#212B35",
  },
  "& .MuiPickersCalendarHeader-root": {
    margin: "8px 0px",
  },
  "& .MuiPickersDay-root": {
    height: "20px",
    fontSize: "10px",
    width: "20px",
    margin: "0px 10px",
  },
  "& .MuiTypography-root": {
    height: "10px",
    fontSize: "10px",
  },
  "& .MuiPickersSlideTransition-root": {
    height: "145px",
  },
  "& .MuiDialogActions-root ": {
    padding: "0px",
  },
  "& .MuiPickersCalendarHeader-switchViewButton": {
    color: "#69C2D2",
  },
  "& .MuiButtonBase-root": {
    color: "#69C2D2",
  },
  "& .Mui-selected": {
    color: "#D9D9D9",
    backgroundColor: "#69C2D2 !important",
  },
  "& .MuiPickersDay-today": {
    borderColor: "#69C2D2",
  },
  "& .MuiPickersMonth-root": {
    color: "#69C2D2",
  },
  "& .MuiPickersMonth-monthButton": {
    color: "#69C2D2",
  },
  "& .MuiDayCalendar-weekDayLabel": {
    color: "#69C2D2",
  },
};

const selectStyles = {
  color: "#D9D9D9",
  "&:hover, &.Mui-focused, &.MuiSelect-nativeOpen": {
    color: "#D9D9D9",
    borderColor: "#D9D9D9",
  },
  "&:hover": {
    "&& fieldset": {
      borderColor: "#69C2D2",
    },
  },
  ul: {
    paddingY: "0px !important",
    maxHeight: "600px",
    backgroundColor: "#212B35",
    li: {
      "&:hover": { color: "#69C2D2" },
      "&:selected": { backgroundColor: "none" },
    },
  },
  svg: {
    fill: "#D9D9D9",
  },
  fieldset: {
    borderColor: "#D9D9D9",
    "&:hover, &.Mui-focused": {
      borderColor: "#D9D9D9",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#D9D9D9",
    borderWidth: "1px",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#69C2D2",
    borderWidth: "1px",
  },
};

const menuItemStyles = {
  color: "#D9D9D9",
  backgroundColor: "#212B35",
  "&.Mui-selected": {
    color: "#69C2D2",
    backgroundColor: "#323C47",
  },
  "&:hover": {
    color: "#D9D9D9",
    backgroundColor: "#323C47",
  },
};

const labelStyles = {
  color: "#69C2D2",
  "&.Mui-focused": {
    color: "#69C2D2",
  },
};

const formControlStyles = {
  "& .MuiInputLabel-root, & .MuiOutlinedInput-root": {
    color: "#D9D9D9",
  },
};

export {
  buttonStyles,
  checkboxStyles,
  textFieldStyles,
  datePickerStyles,
  selectStyles,
  menuItemStyles,
  labelStyles,
  formControlStyles,
};
