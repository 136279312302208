const menuStyles = {
  boxShadow: 0,
  color: "#D9D9D9",
  "&:hover, &.Mui-focused, &.MuiSelect-nativeOpen": {
    color: "#D9D9D9",
    borderColor: "#D9D9D9",
  },
  "&:hover": {
    "&& fieldset": {
      borderColor: "#69C2D2",
    },
  },
  ul: {
    backgroundColor: "#212B35",
    li: {
      padding: "0px 15px",
      "&:hover": { color: "#69C2D2" },
      "&:selected": { backgroundColor: "none" },
    },
  },
  svg: {
    fill: "#D9D9D9",
  },
  fieldset: {
    borderColor: "#D9D9D9",
    "&:hover, &.Mui-focused": {
      borderColor: "#D9D9D9",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#D9D9D9",
    borderWidth: "1px",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#69C2D2",
    borderWidth: "1px",
  },
};

const menuItemStyles = {
  color: "#D9D9D9",
  fontSize: "14px",
  paddingTop: 0,
  paddingBottom: 0,
  "&.Mui-selected": {
    color: "#69C2D2",
  },
  "&:hover": {
    color: "#D9D9D9",
  },
};

const logoStyle = {
  height: "28px",
};

export { menuStyles, menuItemStyles, logoStyle };
