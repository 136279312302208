import { React, useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";
import "./filter.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "state";
import RuleBasedFilter from "components/ruleBasedFilter/ruleBasedFilter";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevronDown.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { ReactComponent as ExpandIcon } from "../../assets/icons/plusCircleIcon.svg";
import { ReactComponent as CollapseIcon } from "../../assets/icons/minusCircleIcon.svg";
import {
  formControlStyles,
  labelStyles,
  menuItemStyles,
  selectStyles,
  addStatementBttonStyles,
  textFieldStyles,
} from "./filterStyle";
import {
  fetchFeatures,
  prepareColumns,
  getConfigureVariablesdata,
} from "utils/utils";
const nodeInfo = require("../../assets/testdata/node-info-testdata.json");

const Filter = ({
  connectedDataSources,
  allColumns,
  numColumns,
  catColumns,
  dateColumns,
  mixedColumns,
  dsMap,
  isMultiFilter,
  index,
  showDescription,
  handleRemoveFilter,
  isBinaryFilter,
  showRemove,
}) => {
  const filterCfg = useSelector((state) => state.filterCfg);
  const statementNames = useSelector((state) => state.statementNames);
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedFeatureGroup = useSelector(
    (state) => state.selectedFeatureGroup
  );
  const dispatch = useDispatch();
  const {
    updateFilterCfg,
    updateStatementNames,
    updateIsLoading,
    updateSnackbarMsg,
    updateAlertMessage,
  } = bindActionCreators(actionCreators, dispatch);
  let configs = useRef(null);
  const [mapNumColumns, setMapNumColumns] = useState([]);
  const [mapCatColumns, setMapCatColumns] = useState([]);
  const [mapDateColumns, setMapDateColumns] = useState([]);
  const [mapMixedColumns, setMapMixedColumns] = useState([]);
  const [statementsList, setStatementsList] = useState([]);
  const [variablesData, setVariablesData] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const GroupHeader = styled("div")(({ theme, padding = "4px 10px" }) => ({
    position: "sticky",
    top: "-8px",
    zIndex: 10000,
    padding: padding,
    color: "#FFFFFF",
    backgroundColor: "rgb(68 79 91)",
  }));

  const GroupItems = styled("ul")({
    padding: 0,
  });

  useEffect(() => {
    configs.current = structuredClone(filterCfg);
    if (isEmpty(configs.current[index])) {
      configs.current[index] = {};
      configs.current[index]["subtype"] = "";
      configs.current[index]["cfg"] = {};
      configs.current[index].cfg["mapCfg"] = {};
    } else if (configs.current[index].cfg.mapCfg.mapDs) {
      getFeaturesInfo(configs.current[index].cfg.mapCfg.mapDs);
    }
    configs.current[index]["type"] = "filter";
    configs.current[index]["cfg"]["inputDs"] = connectedDataSources[0];
    const newFilterCfg = structuredClone(filterCfg);
    newFilterCfg[index] = structuredClone(configs.current[index]);
    updateFilterCfg(newFilterCfg);
    if (newFilterCfg[index].cfg.ruleCfg) {
      setStatementsList(
        structuredClone(newFilterCfg[index].cfg.ruleCfg.stmtOrder)
      );
    }
    getVariablesdata();
  }, []);

  const getVariablesdata = async () => {
    const data = await getConfigureVariablesdata(
      selectedProjectKey,
      selectedProjVersion,
      selectedFeatureGroup,
      updateIsLoading,
      updateAlertMessage
    );
    if (data && Array.isArray(data)) {
      const namesArray = data.map((item) => item.name);
      setVariablesData(namesArray);
    }
    if (!data) {
      updateSnackbarMsg("Something went wrong. Please try again later");
      return;
    }
  };

  const getFeaturesInfo = async (ds) => {
    const columnsInfo = [];
    const features = await fetchFeatures(
      ds,
      null,
      selectedProjectKey,
      selectedProjVersion,
      selectedFeatureGroup,
      updateIsLoading,
      updateAlertMessage
    );
    if (!features) {
      updateSnackbarMsg("Couldn't fetch features. Please save and try again");
      return;
    }
    columnsInfo.push(features);
    const allColumnValues = prepareColumns(columnsInfo, nodeInfo.columnsInfo);
    setMapNumColumns(allColumnValues.allColumnsInfo.numerical);
    setMapCatColumns(allColumnValues.allColumnsInfo.categorical);
    setMapDateColumns(allColumnValues.allColumnsInfo.date);
    setMapMixedColumns(allColumnValues.allColumnsInfo.mixed);
  };

  const handleChange = (e) => {
    configs.current = structuredClone(filterCfg);
    const value = e.target.value;

    if (
      e?.target?.type === "text" &&
      (e.target.name === "name" || e.target.name === "opColName")
    ) {
      if (!(value.length <= 50 && /^[a-zA-Z0-9_]*$/.test(value))) {
        return;
      }
    }
    if (e?.target?.type === "text" && e.target.name === "description") {
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        return;
      }
    }
    if (e?.target?.type === "number" && !/^[0-9]+$/.test(value)) {
      return;
    }
    if (
      e.target.name === "subtype" ||
      e.target.name === "trueValType" ||
      e.target.name === "trueValue"
    ) {
      configs.current[index][e.target.name] = e.target.value;
    } else if (e.target.name === "filterValues") {
      configs.current[index].cfg[e.target.name] =
        e.target.value.split(",")[0] !== "" ? e.target.value.split(",") : [];
    } else {
      configs.current[index].cfg[e.target.name] =
        e.target.value === "true"
          ? true
          : e.target.value === "false"
          ? false
          : e.target.value;
    }
    if (e.target.name === "valueType") {
      if (e.target.value === "map") {
        configs.current[index].cfg["dataMap"] = true;
      } else {
        configs.current[index].cfg["dataMap"] = false;
      }
    }
    const newFilterCfg = structuredClone(filterCfg);
    if (e.target.name === "trueValType") {
      configs.current[index]["trueValue"] = "";
    }
    newFilterCfg[index] = structuredClone(configs.current[index]);
    updateFilterCfg(newFilterCfg);
  };

  const handleMapInputChange = (e) => {
    configs.current[index].cfg.mapCfg[e.target.name] = e.target.value;
    const newFilterCfg = structuredClone(configs.current);
    updateFilterCfg(newFilterCfg);
    if (e.target.name === "mapDs") {
      getFeaturesInfo(e.target.value);
    }
  };

  const handleAddStatement = () => {
    const newStatementList = structuredClone(statementsList);
    let statementId = "s0";
    if (newStatementList.length) {
      statementId = "s" + (Number(newStatementList.at(-1).split("s")[1]) + 1);
    }
    newStatementList.push(statementId);
    setStatementsList(newStatementList);

    const newFilterCfg = structuredClone(filterCfg);
    if (!newFilterCfg[index].cfg.ruleCfg) {
      newFilterCfg[index].cfg["ruleCfg"] = {};
      newFilterCfg[index].cfg.ruleCfg["stmtOrder"] = [];
    }
    newFilterCfg[index].cfg.ruleCfg[statementId] = {};
    newFilterCfg[index].cfg.ruleCfg[statementId]["mapCfg"] = {};
    newFilterCfg[index].cfg.ruleCfg.stmtOrder.push(statementId);
    updateFilterCfg(newFilterCfg);
  };

  const handleRemoveStatement = (statementId) => {
    const newStatementList = structuredClone(statementsList);
    newStatementList.splice(newStatementList.indexOf(statementId), 1);
    setStatementsList(newStatementList);
    const newStatementNames = structuredClone(statementNames);
    delete newStatementNames[index][statementId];
    updateStatementNames(newStatementNames);
    const newFilterCfg = structuredClone(filterCfg);
    newFilterCfg[index].cfg.ruleCfg.stmtOrder.splice(
      newFilterCfg[index].cfg.ruleCfg.stmtOrder.indexOf(statementId),
      1
    );
    if (newFilterCfg[index].cfg.ruleCfg[statementId]) {
      delete newFilterCfg[index].cfg.ruleCfg[statementId];
    }
    updateFilterCfg(newFilterCfg);
  };

  const handleCollapseFilter = (evt) => {
    const filterElement = evt.target.closest(".multi-filter");
    filterElement
      .querySelectorAll(".collapsable-item")
      .forEach((item) => item.classList.add("hidden"));
    setIsCollapsed(true);
  };

  const handleExpandFilter = (evt) => {
    const filterElement = evt.target.closest(".multi-filter");
    filterElement
      .querySelectorAll(".collapsable-item")
      .forEach((item) => item.classList.remove("hidden"));
    setIsCollapsed(false);
  };

  const getOptionLabel = (option, identifier) => {
    if (identifier && identifier === "mapDs") {
      if (Object.keys(dsMap).includes(option)) {
        return dsMap[option];
      } else {
        return option;
      }
    } else if (option.item) {
      return option.item;
    } else {
      return option;
    }
  };

  const isOptionEqualToValue = (option, value, options, identifier = null) => {
    if (identifier && identifier === "mapDs") {
      return dsMap[option] === value || value === "";
    } else if (options[0].value) {
      options = options.map((opt) => opt.value);
    }
    if (options.includes(value)) {
      if (typeof option === "object") {
        return option.value === value || option.item === value || value === "";
      } else {
        return option === value || value === "";
      }
    }
    return value === "";
  };

  const renderTags = (value, getTagProps) =>
    value.map((option, index) => (
      <Tooltip key={option} title={option} placement="top">
        <Chip label={option} {...getTagProps({ index })} />
      </Tooltip>
    ));

  const handleSelectChange = (e, value, inputId, mapTypeInput = false) => {
    configs.current = structuredClone(filterCfg);
    if (typeof value === "object" && !Array.isArray(value)) {
      if (value?.item) {
        value = value?.item;
      } else {
        value = value?.value;
      }
    }
    if (inputId === "trueValue") {
      configs.current[index][inputId] = value;
    } else if (mapTypeInput) {
      configs.current[index].cfg.mapCfg[inputId] = value;
      const newFilterCfg = structuredClone(configs.current);
      updateFilterCfg(newFilterCfg);
      if (inputId === "mapDs") {
        getFeaturesInfo(value);
      }
      return;
    } else {
      configs.current[index].cfg[inputId] = value;
    }
    const newFilterCfg = structuredClone(filterCfg);
    newFilterCfg[index] = structuredClone(configs.current[index]);
    updateFilterCfg(newFilterCfg);
  };

  const getColumns = (columns) => {
    let groupedOptions = [];
    const num = columns.filter((col) => numColumns.includes(col));
    const cat = columns.filter((col) => catColumns.includes(col));
    const dat = columns.filter((col) => dateColumns.includes(col));
    const mixed = columns.filter((col) => mixedColumns.includes(col));
    num.forEach((col) => {
      let obj = { item: col, title: "Numerical" };
      groupedOptions.push(obj);
    });
    cat.forEach((col) => {
      let obj = { item: col, title: "Categorical" };
      groupedOptions.push(obj);
    });
    dat.forEach((col) => {
      let obj = { item: col, title: "Date" };
      groupedOptions.push(obj);
    });
    mixed.forEach((col) => {
      let obj = { item: col, title: "Mixed" };
      groupedOptions.push(obj);
    });
    return groupedOptions;
  };

  const getSelectedDataSourceLabel = () => {
    if (
      !filterCfg[index] ||
      !filterCfg[index].cfg.mapCfg ||
      !filterCfg[index].cfg.mapCfg.mapDs
    ) {
      return "";
    }
    const value = filterCfg[index].cfg.mapCfg.mapDs;
    return dsMap[value];
  };

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap="1rem"
      >
        <FormControl id="name" className="text-field" size="small">
          <TextField
            type="text"
            name="name"
            size="small"
            label="Filter name"
            className="w-100"
            value={
              filterCfg[index] &&
              filterCfg[index].cfg &&
              filterCfg[index].cfg.name
                ? filterCfg[index].cfg.name
                : ""
            }
            onChange={handleChange}
            variant="outlined"
            autoComplete="off"
            sx={textFieldStyles}
            required
          />
        </FormControl>

        {showRemove ? (
          <Stack direction="row">
            <Tooltip title="Remove filter" placement="top">
              <IconButton
                aria-label="remove"
                className="remove-icon"
                onClick={() => handleRemoveFilter(index)}
              >
                <CloseIcon></CloseIcon>
              </IconButton>
            </Tooltip>
            {/* TODO: Make the filter collapsable using the minusCircleIcon and plusCircleIcon */}
            <Tooltip
              title={isCollapsed ? "Expand Filter" : "Collapse Filter"}
              placement="top"
            >
              {!isCollapsed ? (
                <IconButton
                  aria-label="collapse"
                  className="collapse-icon"
                  onClick={(evt) => handleCollapseFilter(evt, index)}
                >
                  <CollapseIcon></CollapseIcon>
                </IconButton>
              ) : (
                <IconButton
                  aria-label="expand"
                  className="expand-icon"
                  onClick={(evt) => handleExpandFilter(evt, index)}
                >
                  <ExpandIcon></ExpandIcon>
                </IconButton>
              )}
            </Tooltip>
          </Stack>
        ) : (
          <></>
        )}
      </Stack>
      {showDescription && (
        <FormControl
          id="description"
          className="text-field collapsable-item"
          size="small"
        >
          <TextField
            type="text"
            name="description"
            size="small"
            label="Description"
            className="w-100"
            value={
              filterCfg[index] &&
              filterCfg[index].cfg &&
              filterCfg[index].cfg.description
                ? filterCfg[index].cfg.description
                : ""
            }
            onChange={handleChange}
            variant="outlined"
            autoComplete="off"
            sx={textFieldStyles}
          />
        </FormControl>
      )}

      {isBinaryFilter ? (
        <Stack className="collapsable-item">
          <FormControl id="trueValType" sx={formControlStyles} size="small">
            <InputLabel sx={labelStyles}>True value type</InputLabel>
            <Select
              placeholder="Select option"
              name="trueValType"
              label="True value type"
              value={
                filterCfg[index] && filterCfg[index].trueValType
                  ? filterCfg[index].trueValType
                  : ""
              }
              onChange={handleChange}
              required
              size="small"
              sx={selectStyles}
              inputProps={{ sx: selectStyles }}
              MenuProps={{ sx: selectStyles }}
              autoWidth={true}
            >
              <MenuItem value="constant" sx={menuItemStyles}>
                Constant
              </MenuItem>
              <MenuItem value="column" sx={menuItemStyles}>
                Column
              </MenuItem>
            </Select>
          </FormControl>
          {filterCfg[index] &&
          filterCfg[index]?.trueValType &&
          filterCfg[index]?.trueValType === "column" ? (
            <Autocomplete
              key={"trueValue"}
              autoHighlight
              size="small"
              id="tags-outlined"
              options={getColumns(allColumns)}
              getOptionLabel={(option) => getOptionLabel(option)}
              autoComplete
              includeInputInList
              disableClearable
              className="select-dropdown"
              ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
              isOptionEqualToValue={(option, value) => {
                return isOptionEqualToValue(option, value, allColumns);
              }}
              value={
                filterCfg[index] && filterCfg[index].trueValue
                  ? filterCfg[index].trueValue
                  : ""
              }
              onChange={(e, value) => {
                handleSelectChange(e, value, "trueValue");
              }}
              renderTags={renderTags}
              groupBy={(option) => option.title}
              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
              componentsProps={{
                paper: {
                  sx: {
                    backgroundColor: "#212b35",
                    color: "#d9d9d9",
                    borderRadius: "4px",
                    boxShadow:
                      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                    "li:hover": {
                      color: "#69c2d2",
                    },
                  },
                },
              }}
              renderInput={(params) => (
                <Tooltip
                  title={
                    filterCfg[index] && filterCfg[index].trueValue
                      ? filterCfg[index].trueValue
                      : ""
                  }
                  placement="right"
                >
                  <TextField
                    {...params}
                    size="small"
                    label="Column Name"
                    placeholder="Select option"
                    autoComplete="off"
                    sx={textFieldStyles}
                  />
                </Tooltip>
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Tooltip title={getOptionLabel(option)} placement="right">
                    <Typography noWrap>{getOptionLabel(option)}</Typography>
                  </Tooltip>
                </li>
              )}
            />
          ) : (
            <FormControl id="trueValue" className="text-field" size="small">
              <TextField
                type="text"
                name="trueValue"
                size="small"
                label="True value"
                className="w-100"
                value={
                  filterCfg[index] && filterCfg[index].trueValue
                    ? filterCfg[index].trueValue
                    : ""
                }
                onChange={handleChange}
                variant="outlined"
                autoComplete="off"
                sx={textFieldStyles}
                required
              />
            </FormControl>
          )}
        </Stack>
      ) : (
        <></>
      )}
      <FormControl
        id="filter-on"
        className="collapsable-item"
        sx={{ marginTop: "15px !important" }}
      >
        <FormLabel id="row-radio-buttons-group-label" sx={labelStyles}>
          Filter on
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="row-radio-buttons-group-label"
          name="subtype"
          value={
            filterCfg[index] && filterCfg[index].subtype
              ? filterCfg[index].subtype
              : ""
          }
          onChange={handleChange}
        >
          <FormControlLabel
            value="col-value"
            control={<Radio style={{ color: "#69C2D2" }} />}
            label="Column based"
          />
          <FormControlLabel
            value="rule-filter"
            control={<Radio style={{ color: "#69C2D2" }} />}
            label="Rule based"
          />
          <FormControlLabel
            value="substr-filter"
            control={<Radio style={{ color: "#69C2D2" }} />}
            label="Substring based"
          />
        </RadioGroup>
      </FormControl>
      {filterCfg[index]?.subtype === "substr-filter" ? (
        <Stack>
          <Autocomplete
            key={"filterCol"}
            autoHighlight
            size="small"
            id="tags-outlined"
            options={getColumns(allColumns)}
            getOptionLabel={(option) => getOptionLabel(option)}
            autoComplete
            includeInputInList
            disableClearable
            className="select-dropdown"
            ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
            isOptionEqualToValue={(option, value) => {
              return isOptionEqualToValue(option, value, allColumns);
            }}
            value={
              filterCfg[index] && filterCfg[index].cfg["filterCol"]
                ? filterCfg[index].cfg["filterCol"]
                : ""
            }
            onChange={(e, value) => {
              handleSelectChange(e, value, "filterCol");
            }}
            renderTags={renderTags}
            groupBy={(option) => option.title}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            componentsProps={{
              paper: {
                sx: {
                  backgroundColor: "#212b35",
                  color: "#d9d9d9",
                  borderRadius: "4px",
                  boxShadow:
                    "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                  "li:hover": {
                    color: "#69c2d2",
                  },
                },
              },
            }}
            renderInput={(params) => (
              <Tooltip
                title={
                  filterCfg[index] && filterCfg[index].cfg["filterCol"]
                    ? filterCfg[index].cfg["filterCol"]
                    : ""
                }
                placement="right"
              >
                <TextField
                  {...params}
                  size="small"
                  label="Column Name"
                  placeholder="Select option"
                  autoComplete="off"
                  sx={textFieldStyles}
                />
              </Tooltip>
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Tooltip title={getOptionLabel(option)} placement="right">
                  <Typography noWrap>{getOptionLabel(option)}</Typography>
                </Tooltip>
              </li>
            )}
          />

          <FormControl id="filterCond" sx={formControlStyles} size="small">
            <InputLabel sx={labelStyles}>Filter condition</InputLabel>
            <Select
              placeholder="Select option"
              name="filterCond"
              label="Filter condition"
              value={
                filterCfg[index] && filterCfg[index].cfg.filterCond
                  ? filterCfg[index].cfg.filterCond
                  : ""
              }
              onChange={handleChange}
              required
              size="small"
              sx={selectStyles}
              inputProps={{ sx: selectStyles }}
              MenuProps={{ sx: selectStyles }}
              autoWidth={true}
            >
              <MenuItem value="like" sx={menuItemStyles}>
                like
              </MenuItem>
              <MenuItem value="notlike" sx={menuItemStyles}>
                not like
              </MenuItem>
              <MenuItem value="ilike" sx={menuItemStyles}>
                ilike
              </MenuItem>
              <MenuItem value="rlike" sx={menuItemStyles}>
                rlike
              </MenuItem>
              <MenuItem value="notilike" sx={menuItemStyles}>
                not ilike
              </MenuItem>
              <MenuItem value="notrlike" sx={menuItemStyles}>
                not rlike
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl id="subStr" className="text-field" size="small">
            <TextField
              type="text"
              name="subStr"
              size="small"
              label="Substring with % inclusive accordingly"
              value={
                filterCfg[index] && filterCfg[index].cfg.subStr
                  ? filterCfg[index].cfg.subStr
                  : ""
              }
              onChange={handleChange}
              variant="outlined"
              autoComplete="off"
              sx={textFieldStyles}
              required
            />
          </FormControl>
        </Stack>
      ) : filterCfg[index]?.subtype === "col-value" ? (
        <Stack>
          <Autocomplete
            key={"filterCol"}
            autoHighlight
            size="small"
            id="tags-outlined"
            options={getColumns(allColumns)}
            getOptionLabel={(option) => getOptionLabel(option)}
            autoComplete
            includeInputInList
            disableClearable
            className="select-dropdown"
            ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
            isOptionEqualToValue={(option, value) => {
              return isOptionEqualToValue(option, value, allColumns);
            }}
            value={
              filterCfg[index] && filterCfg[index].cfg["filterCol"]
                ? filterCfg[index].cfg["filterCol"]
                : ""
            }
            onChange={(e, value) => {
              handleSelectChange(e, value, "filterCol");
            }}
            renderTags={renderTags}
            groupBy={(option) => option.title}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            componentsProps={{
              paper: {
                sx: {
                  backgroundColor: "#212b35",
                  color: "#d9d9d9",
                  borderRadius: "4px",
                  boxShadow:
                    "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                  "li:hover": {
                    color: "#69c2d2",
                  },
                },
              },
            }}
            renderInput={(params) => (
              <Tooltip
                title={
                  filterCfg[index] && filterCfg[index].cfg["filterCol"]
                    ? filterCfg[index].cfg["filterCol"]
                    : ""
                }
                placement="right"
              >
                <TextField
                  {...params}
                  size="small"
                  label="Column Name to filter on"
                  placeholder="Select option"
                  autoComplete="off"
                  sx={textFieldStyles}
                />
              </Tooltip>
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Tooltip title={getOptionLabel(option)} placement="right">
                  <Typography noWrap>{getOptionLabel(option)}</Typography>
                </Tooltip>
              </li>
            )}
          />

          <FormControl id="filterCond" sx={formControlStyles} size="small">
            <InputLabel sx={labelStyles}>Filter condition</InputLabel>
            <Select
              placeholder="Select option"
              name="filterCond"
              label="Filter condition"
              value={
                filterCfg[index] && filterCfg[index].cfg.filterCond
                  ? filterCfg[index].cfg.filterCond
                  : ""
              }
              onChange={handleChange}
              required
              size="small"
              sx={selectStyles}
              inputProps={{ sx: selectStyles }}
              MenuProps={{ sx: selectStyles }}
              autoWidth={true}
            >
              <MenuItem value="eq" sx={menuItemStyles}>
                eq
              </MenuItem>
              <MenuItem value="neq" sx={menuItemStyles}>
                neq
              </MenuItem>
              <MenuItem value="null-excl" sx={menuItemStyles}>
                null-excl
              </MenuItem>
              <MenuItem value="null-incl" sx={menuItemStyles}>
                null-incl
              </MenuItem>
            </Select>
          </FormControl>
          {filterCfg[index].cfg &&
          (filterCfg[index].cfg.filterCond === "neq" ||
            filterCfg[index].cfg.filterCond === "eq") ? (
            <Stack>
              <FormControl id="valueType" sx={formControlStyles} size="small">
                <InputLabel sx={labelStyles}>Value type</InputLabel>
                <Select
                  placeholder="Select option"
                  name="valueType"
                  label="Value type"
                  value={
                    filterCfg[index] && filterCfg[index].cfg.valueType
                      ? filterCfg[index].cfg.valueType
                      : ""
                  }
                  onChange={handleChange}
                  required
                  size="small"
                  sx={selectStyles}
                  inputProps={{ sx: selectStyles }}
                  MenuProps={{ sx: selectStyles }}
                  autoWidth={true}
                >
                  <MenuItem value="map" sx={menuItemStyles}>
                    Map
                  </MenuItem>
                  <MenuItem value="constant" sx={menuItemStyles}>
                    Constant
                  </MenuItem>
                  <MenuItem value="variable" sx={menuItemStyles}>
                    Variable
                  </MenuItem>
                </Select>
              </FormControl>
              {filterCfg[index].cfg &&
              filterCfg[index].cfg.valueType === "map" ? (
                <Stack>
                  <Autocomplete
                    key={"mapDs"}
                    autoHighlight
                    size="small"
                    id="tags-outlined"
                    options={Object.keys(dsMap)}
                    getOptionLabel={(option) => getOptionLabel(option, "mapDs")}
                    autoComplete
                    includeInputInList
                    disableClearable
                    className="select-dropdown"
                    ListboxProps={{
                      style: { maxHeight: 300, overflow: "auto" },
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return isOptionEqualToValue(
                        option,
                        value,
                        Object.keys(dsMap),
                        "mapDs"
                      );
                    }}
                    value={getSelectedDataSourceLabel()}
                    onChange={(e, value) => {
                      handleSelectChange(e, value, "mapDs", true);
                    }}
                    renderTags={renderTags}
                    groupBy={(option) => option.title}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader padding="0px">{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                    componentsProps={{
                      paper: {
                        sx: {
                          backgroundColor: "#212b35",
                          color: "#d9d9d9",
                          borderRadius: "4px",
                          boxShadow:
                            "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                          "li:hover": {
                            color: "#69c2d2",
                          },
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <Tooltip
                        title={getSelectedDataSourceLabel()}
                        placement="right"
                      >
                        <TextField
                          {...params}
                          size="small"
                          label="mapping dataset"
                          placeholder="Select option"
                          autoComplete="off"
                          sx={textFieldStyles}
                        />
                      </Tooltip>
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Tooltip
                          title={getOptionLabel(option, "mapDs")}
                          placement="right"
                        >
                          <Typography noWrap>
                            {getOptionLabel(option, "mapDs")}
                          </Typography>
                        </Tooltip>
                      </li>
                    )}
                  />
                  <Autocomplete
                    key={"mapKeyFeat"}
                    autoHighlight
                    size="small"
                    id="tags-outlined"
                    options={getColumns(allColumns)}
                    getOptionLabel={(option) => getOptionLabel(option)}
                    autoComplete
                    includeInputInList
                    disableClearable
                    className="select-dropdown"
                    ListboxProps={{
                      style: { maxHeight: 300, overflow: "auto" },
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return isOptionEqualToValue(option, value, allColumns);
                    }}
                    value={
                      filterCfg[index] &&
                      filterCfg[index].cfg.mapCfg &&
                      filterCfg[index].cfg.mapCfg.mapKeyFeat
                        ? filterCfg[index].cfg.mapCfg.mapKeyFeat
                        : ""
                    }
                    onChange={(e, value) => {
                      handleSelectChange(e, value, "mapKeyFeat", true);
                    }}
                    renderTags={renderTags}
                    groupBy={(option) => option.title}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                    componentsProps={{
                      paper: {
                        sx: {
                          backgroundColor: "#212b35",
                          color: "#d9d9d9",
                          borderRadius: "4px",
                          boxShadow:
                            "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                          "li:hover": {
                            color: "#69c2d2",
                          },
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <Tooltip
                        title={
                          filterCfg[index] &&
                          filterCfg[index].cfg.mapCfg &&
                          filterCfg[index].cfg.mapCfg.mapKeyFeat
                            ? filterCfg[index].cfg.mapCfg.mapKeyFeat
                            : ""
                        }
                        placement="right"
                      >
                        <TextField
                          {...params}
                          size="small"
                          label="Key column Name"
                          placeholder="Select option"
                          autoComplete="off"
                          sx={textFieldStyles}
                        />
                      </Tooltip>
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Tooltip
                          title={getOptionLabel(option)}
                          placement="right"
                        >
                          <Typography noWrap>
                            {getOptionLabel(option)}
                          </Typography>
                        </Tooltip>
                      </li>
                    )}
                  />
                  <Autocomplete
                    key={"mapValFeat"}
                    autoHighlight
                    size="small"
                    id="tags-outlined"
                    options={getColumns(allColumns)}
                    getOptionLabel={(option) => getOptionLabel(option)}
                    autoComplete
                    includeInputInList
                    disableClearable
                    className="select-dropdown"
                    ListboxProps={{
                      style: { maxHeight: 300, overflow: "auto" },
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return isOptionEqualToValue(option, value, allColumns);
                    }}
                    value={
                      filterCfg[index] &&
                      filterCfg[index].cfg.mapCfg &&
                      filterCfg[index].cfg.mapCfg.mapValFeat
                        ? filterCfg[index].cfg.mapCfg.mapValFeat
                        : ""
                    }
                    onChange={(e, value) => {
                      handleSelectChange(e, value, "mapValFeat", true);
                    }}
                    renderTags={renderTags}
                    groupBy={(option) => option.title}
                    renderGroup={(params) => (
                      <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                    componentsProps={{
                      paper: {
                        sx: {
                          backgroundColor: "#212b35",
                          color: "#d9d9d9",
                          borderRadius: "4px",
                          boxShadow:
                            "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                          "li:hover": {
                            color: "#69c2d2",
                          },
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <Tooltip
                        title={
                          filterCfg[index] &&
                          filterCfg[index].cfg.mapCfg &&
                          filterCfg[index].cfg.mapCfg.mapValFeat
                            ? filterCfg[index].cfg.mapCfg.mapValFeat
                            : ""
                        }
                        placement="right"
                      >
                        <TextField
                          {...params}
                          size="small"
                          label="Value column Name"
                          placeholder="Select option"
                          autoComplete="off"
                          sx={textFieldStyles}
                        />
                      </Tooltip>
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Tooltip
                          title={getOptionLabel(option)}
                          placement="right"
                        >
                          <Typography noWrap>
                            {getOptionLabel(option)}
                          </Typography>
                        </Tooltip>
                      </li>
                    )}
                  />
                  <FormControl
                    id="mapKeyVal"
                    className="text-field"
                    size="small"
                  >
                    <TextField
                      type="text"
                      name="mapKeyVal"
                      size="small"
                      label="Key for which the value is mapped"
                      value={
                        filterCfg[index] &&
                        filterCfg[index].cfg.mapCfg &&
                        filterCfg[index].cfg.mapCfg.mapKeyVal
                          ? filterCfg[index].cfg.mapCfg.mapKeyVal
                          : ""
                      }
                      onChange={handleMapInputChange}
                      autoComplete="off"
                      variant="outlined"
                      sx={textFieldStyles}
                      required
                    />
                  </FormControl>
                </Stack>
              ) : filterCfg[index].cfg &&
                filterCfg[index].cfg.valueType === "constant" ? (
                <FormControl
                  id="filterValues"
                  className="text-field"
                  size="small"
                >
                  <TextField
                    type="text"
                    name="filterValues"
                    size="small"
                    label="Comma separated values"
                    value={
                      filterCfg[index] && filterCfg[index].cfg.filterValues
                        ? filterCfg[index].cfg.filterValues.toString()
                        : ""
                    }
                    onChange={handleChange}
                    autoComplete="off"
                    variant="outlined"
                    sx={textFieldStyles}
                    required
                  />
                </FormControl>
              ) : filterCfg[index].cfg &&
                filterCfg[index].cfg.valueType === "variable" ? (
                <Autocomplete
                  key={"selectedVariable"}
                  autoHighlight
                  size="small"
                  id="tags-outlined"
                  options={variablesData}
                  getOptionLabel={(option) => getOptionLabel(option)}
                  autoComplete
                  includeInputInList
                  disableClearable
                  className="select-dropdown"
                  ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
                  isOptionEqualToValue={(option, value) => {
                    return isOptionEqualToValue(option, value, variablesData);
                  }}
                  value={
                    filterCfg[index] &&
                    filterCfg[index].cfg &&
                    filterCfg[index].cfg.selectedVariable
                      ? filterCfg[index].cfg.selectedVariable
                      : ""
                  }
                  onChange={(e, value) => {
                    handleSelectChange(e, value, "selectedVariable");
                  }}
                  renderTags={renderTags}
                  componentsProps={{
                    paper: {
                      sx: {
                        backgroundColor: "#212b35",
                        color: "#d9d9d9",
                        borderRadius: "4px",
                        boxShadow:
                          "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                        "li:hover": {
                          color: "#69c2d2",
                        },
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <Tooltip
                      title={
                        filterCfg[index] &&
                        filterCfg[index].cfg &&
                        filterCfg[index].cfg.selectedVariable
                          ? filterCfg[index].cfg.selectedVariable
                          : ""
                      }
                      placement="right"
                    >
                      <TextField
                        {...params}
                        size="small"
                        label="Selected variable"
                        placeholder="Select option"
                        autoComplete="off"
                        sx={textFieldStyles}
                      />
                    </Tooltip>
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Tooltip title={getOptionLabel(option)} placement="right">
                        <Typography noWrap>{getOptionLabel(option)}</Typography>
                      </Tooltip>
                    </li>
                  )}
                />
              ) : (
                <></>
              )}
            </Stack>
          ) : (
            <></>
          )}
        </Stack>
      ) : filterCfg[index]?.subtype === "rule-filter" ? (
        <Stack>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={() => handleAddStatement()}
              autoFocus
              sx={addStatementBttonStyles}
              className="add-statement-btn"
            >
              Add statement
            </Button>
          </Stack>
          <Stack>
            {statementsList.map((statement, statementIndex) => (
              <Accordion
                key={statement}
                sx={{
                  background: "#151c24;",
                  color: "rgb(239, 241, 241) !important",
                  marginTop: "10px",
                }}
              >
                <AccordionSummary
                  expandIcon={<ChevronDown className="icon" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: "#191818",
                  }}
                >
                  <Typography>{statementNames[index][statement]}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="rule-based-filter">
                    <RuleBasedFilter
                      key={statement}
                      connectedDataSources={connectedDataSources}
                      allColumns={allColumns}
                      numColumns={numColumns}
                      catColumns={catColumns}
                      dateColumns={dateColumns}
                      mixedColumns={mixedColumns}
                      dsMap={dsMap}
                      index={index}
                      statementId={statement}
                      handleRemoveStatement={handleRemoveStatement}
                    ></RuleBasedFilter>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </Stack>
        </Stack>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default Filter;
