import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import {
  Autocomplete,
  Chip,
  TextField,
  Tooltip,
  Snackbar,
} from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { ReactComponent as QuestionMarkIcon } from "../../assets/icons/questionMark.svg";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import "./pipelineDialog.scss";
import api from "../../apiInterceptor";
import { buttonStyles, textFieldStyles } from "./pipelineDialogStyle";
import CustomTooltip from "components/Tooltip/CustomTooltip";
const uploadDataAPITestData = require("../../assets/apiTestData/pipeline-getprojcfg-test-data.json");
const getfecfgAPITestData = require("../../assets/apiTestData/getfecfg-test-data.json");
const listprojectsAPITestData = require("../../assets/apiTestData/listprojects-test-data.json");

const PipelineDialog = (modalProps) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  const userAccessType = localStorage.getItem("ACCESS_TYPE");
  const userAccessRestricted = userAccessType === "view";
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  let IS_LIST_PROJECTS_GET_TYPE = localStorage.getItem(
    "IS_LIST_PROJECTS_GET_TYPE"
  );
  IS_LIST_PROJECTS_GET_TYPE =
    IS_LIST_PROJECTS_GET_TYPE === "true" || IS_LIST_PROJECTS_GET_TYPE === true
      ? true
      : false;
  const modalType = "apiDialog";
  const formData = {
    name: "",
    description: "",
    sourceProjectKey: "",
    sourceProjVersion: "",
    sourceProjFg: "base",
    sourceDs: "",
  };
  const selectedNode = modalProps.selectedNode;
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [allProjects, setAllProjects] = useState([]);
  const [allProjectsInfo, setAllProjectsInfo] = useState([]);
  const [versionsToShow, setVersionsToShow] = useState([]);
  const [dsToShow, setDsToShow] = useState([]);
  const [allowOtherProject, setAllowOtherProject] = useState(false);
  const modalForm = useSelector((state) => state.modalForm);
  const pipeline = useSelector((state) => state.pipeline);
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedFeatureGroup = useSelector(
    (state) => state.selectedFeatureGroup
  );
  const snackbarMsg = useSelector((state) => state.snackbarMsg);
  const detailedMsgTooltipData = useSelector(
    (state) => state.detailedMsgTooltipData
  );
  const nodeConfigurations =
    useSelector((state) => state.nodeConfigurations) || {};
  const dispatch = useDispatch();
  const {
    updateModalForm,
    updateNodeConfigurations,
    updateSnackbarMsg,
    updateIsLoading,
    updateAlertMessage,
  } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    getProjects();
    getNodeLevelInfo();
  }, []);

  useEffect(() => {
    if (modalForm) {
      handleVersionsToShow(modalForm.sourceProjectKey);
      handleDataSourcesToShow(
        modalForm.sourceProjectKey,
        modalForm.sourceProjVersion
      );
    }
    let disableSave = false;
    if (!modalForm) {
      disableSave = true;
    } else {
      disableSave = handleSaveDisable();
    }
    if (disableSave) {
      setIsSaveDisabled(true);
      return;
    }
    disableSave = false;
    setIsSaveDisabled(false);
  }, [modalForm]);

  const getNodeLevelInfo = async () => {
    try {
      const apiUrl = BASE_API_URL + "getprojcfg";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: selectedProjectKey,
        projVersion: selectedProjVersion,
        projFg: selectedFeatureGroup,
        nodeType: selectedNode.data.nodeType,
      };
      updateIsLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: uploadDataAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, {
          headers: headers,
        });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        setAllowOtherProject(result.allowOtherProject);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
        modalProps.closeModal(modalType);
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const initializeModalForm = () => {
    if (nodeConfigurations[selectedNode.id]) {
      setTimeout(() => updateModalForm(nodeConfigurations[selectedNode.id]), 1);
    } else {
      formData.sourceProjectKey = selectedProjectKey;
      setTimeout(() => updateModalForm(formData), 1);
    }
  };

  const handleSaveDisable = () => {
    if (
      !modalForm.name ||
      !modalForm.sourceProjVersion ||
      !modalForm.sourceDs
    ) {
      return true;
    }
    if (allowOtherProject && !modalForm.sourceProjectKey) {
      return true;
    }
    return false;
  };

  const getProjectObject = (pKey, projects = allProjectsInfo) => {
    return projects.find((obj) => obj.projectKey === pKey);
  };

  const handleVersionsToShow = (value) => {
    try {
      if (allProjects.length) {
        const projKey = value;
        let versions = [];
        versions = getProjectObject(projKey).versionInfo;
        if (projKey === selectedProjectKey) {
          versions = versions.filter(
            (version) => version.vname !== selectedProjVersion
          );
        }
        setVersionsToShow(versions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDataSources = async (projKey, projVersion) => {
    try {
      const apiUrl = BASE_API_URL + "getfecfg";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: projKey,
        cfgProjectKey: projKey,
        projVersion: projVersion,
        projFg: "base",
      };
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: getfecfgAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, {
          headers: headers,
        });
      }
      if (response.data.status === 200) {
        const feCfg = response.data.data.posts[0];
        const datasources = feCfg.clientMetaData
          ? feCfg.clientMetaData.dsMap
          : {};
        setDsToShow(datasources);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDataSourcesToShow = (projKey, projVersion) => {
    if (projKey && projVersion) {
      getDataSources(projKey, projVersion);
    } else {
      setDsToShow({});
    }
  };

  const handleChange = (e) => {
    try {
      const value = e.target.value;
      const name = e.target.name;
      const newFormData = structuredClone(modalForm);
      if (e?.target?.type === "text" && name === "name") {
        if (!(value.length <= 50 && /^[a-zA-Z0-9_]*$/.test(value))) {
          return;
        }
      }
      newFormData[name] = value;
      updateModalForm(newFormData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (e, inputId, value) => {
    const newFormData = structuredClone(modalForm);
    if (inputId === "sourceProjectKey") {
      value = value.key;
      newFormData.sourceDs = "";
      newFormData.sourceProjVersion = "";
    } else if (inputId === "sourceProjVersion") {
      value = value.vname;
      newFormData.sourceDs = "";
    }
    newFormData[inputId] = value;
    updateModalForm(newFormData);
  };

  const checkNodeNameDuplicacy = (nodeConfigs) => {
    const dsMap = pipeline.clientMetaData.dsMap;
    if (nodeConfigs.name || nodeConfigs.fileName) {
      for (let nodeId of Object.keys(dsMap)) {
        if (
          selectedNode.id !== nodeId &&
          ((nodeConfigs.name && dsMap[nodeId] === nodeConfigs.name) ||
            (nodeConfigs.fileName && nodeConfigs.fileName === dsMap[nodeId]))
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const clickSubmit = (e) => {
    saveUploadedFile(e);
  };

  const saveUploadedFile = async (e) => {
    const apiUrl = BASE_API_URL + "upload-data";
    const headers = {};
    let payload = new FormData();
    let newModalForm = structuredClone(modalForm);
    payload.append("sourceType", "pipeline");
    newModalForm["sourceType"] = "pipeline";
    newModalForm["dsName"] = newModalForm.name;
    payload.append("dSource", selectedNode.id);
    payload.append("projectKey", selectedProjectKey);
    payload.append("projVersion", selectedProjVersion);
    payload.append("projFg", selectedFeatureGroup);
    payload.append("dataCfg", JSON.stringify(newModalForm));
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: uploadDataAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, {
          headers: headers,
          data: null,
        });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        handleSubmit(e);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newNodeConfigurations = structuredClone(nodeConfigurations);
    const newFormData = structuredClone(modalForm);
    const duplicatedName = checkNodeNameDuplicacy(newFormData);
    if (duplicatedName) {
      updateSnackbarMsg("Name already taken. Please enter a unique name");
      return;
    }
    updateModalForm(newFormData);
    newNodeConfigurations[selectedNode.id] = structuredClone(newFormData);
    updateNodeConfigurations(newNodeConfigurations);
    modalProps.unHighlightProblematicNode(selectedNode);
    setTimeout(
      () => modalProps.saveConfig(newNodeConfigurations, selectedNode.id),
      1
    );
    modalProps.closeModal(modalType);
  };

  const prepareProjectsDropdown = (projects) => {
    let projectsDropdownList = [];
    projects.forEach((project) => {
      let projObj = {
        key: project.projectKey,
        name: project.pname,
      };
      projectsDropdownList.push(projObj);
    });
    setAllProjects(projectsDropdownList);
  };

  const getProjects = async () => {
    try {
      const apiUrl = BASE_API_URL + "listprojects";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {};
      updateIsLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: listprojectsAPITestData,
        };
      } else {
        if (IS_LIST_PROJECTS_GET_TYPE) {
          response = await api.get(apiUrl, {
            headers: headers,
          });
        } else {
          response = await api.post(apiUrl, payload, { headers: headers });
        }
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        setAllProjectsInfo(response.data.data.posts[0]);
        prepareProjectsDropdown(response.data.data.posts[0]);
        if (modalForm === null) {
          initializeModalForm();
        }
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const getDsOptionLabel = (option) => {
    if (option) {
      return dsToShow[option];
    }
    return "";
  };

  const getOptionLabel = (option) => {
    if (typeof option === "object") {
      return option.vname;
    } else {
      return option;
    }
  };

  const getSelectedValueLabel = () => {
    try {
      if (allProjectsInfo && modalForm && modalForm.sourceProjectKey) {
        return getProjectObject(modalForm.sourceProjectKey).pname;
      }
      return "";
    } catch (error) {
      console.error(error);
    }
  };

  const renderSelectTags = (value, getTagProps) =>
    value.map((option, index) => (
      <Tooltip key={option} title={option} placement="top">
        <Chip label={option} {...getTagProps({ index })} />
      </Tooltip>
    ));

  const getSelectOptionLabel = (option) => {
    if (typeof option === "object") {
      return option.name;
    }
    return option;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    updateSnackbarMsg(null);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <div>
      <Dialog
        fullWidth
        open={selectedNode !== null}
        aria-labelledby="pipeline-dialog"
        className="pipeline-dialog"
        maxWidth="sm"
        PaperProps={{
          style: {
            background: "#212B35",
            border: "1px solid #818181",
            boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            color: "#EFF1F1",
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          Pipeline
          <div>
            {detailedMsgTooltipData[selectedNode.data.nodeType] ? (
              <IconButton>
                <CustomTooltip
                  title={detailedMsgTooltipData[selectedNode.data.nodeType]}
                >
                  <QuestionMarkIcon width={25} height={25}></QuestionMarkIcon>
                </CustomTooltip>
              </IconButton>
            ) : (
              <></>
            )}
            <IconButton onClick={() => modalProps.closeModal(modalType)}>
              <CloseIcon></CloseIcon>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <form>
            <Stack>
              <FormControl id="name" className="text-field" size="small">
                <TextField
                  type="text"
                  name="name"
                  size="small"
                  label="Name"
                  value={modalForm ? modalForm.name : ""}
                  onChange={(evt) => handleChange(evt)}
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                />
              </FormControl>
              <FormControl id="description" className="text-field" size="small">
                <TextField
                  type="text"
                  name="description"
                  size="small"
                  label="Description"
                  value={modalForm ? modalForm.description : ""}
                  onChange={(evt) => handleChange(evt)}
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                />
              </FormControl>
              {allowOtherProject && (
                <Autocomplete
                  autoHighlight
                  size="small"
                  id="tags-outlined"
                  options={allProjects}
                  getOptionLabel={(option) => getSelectOptionLabel(option)}
                  autoComplete
                  includeInputInList
                  disableClearable
                  className="select-dropdown"
                  ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name || value === ""
                  }
                  value={getSelectedValueLabel()}
                  onChange={(e, value) => {
                    handleSelectChange(e, "sourceProjectKey", value);
                  }}
                  componentsProps={{
                    paper: {
                      sx: {
                        backgroundColor: "#212b35",
                        color: "#d9d9d9",
                        borderRadius: "4px",
                        boxShadow:
                          "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                        "li:hover": {
                          color: "#69c2d2",
                        },
                      },
                    },
                  }}
                  renderTags={renderSelectTags}
                  renderInput={(params) => (
                    <Tooltip title={getSelectedValueLabel()} placement="right">
                      <TextField
                        {...params}
                        size="small"
                        label="Project Name"
                        placeholder="Select option"
                        autoComplete="off"
                        sx={textFieldStyles}
                      />
                    </Tooltip>
                  )}
                />
              )}
              <Autocomplete
                autoHighlight
                size="small"
                id="tags-outlined"
                options={versionsToShow}
                getOptionLabel={(option) => getOptionLabel(option)}
                autoComplete
                includeInputInList
                disableClearable
                className="select-dropdown"
                ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
                isOptionEqualToValue={(option, value) =>
                  option.vname === value || value === ""
                }
                value={modalForm ? modalForm.sourceProjVersion : ""}
                onChange={(e, value) => {
                  handleSelectChange(e, "sourceProjVersion", value);
                }}
                componentsProps={{
                  paper: {
                    sx: {
                      backgroundColor: "#212b35",
                      color: "#d9d9d9",
                      borderRadius: "4px",
                      boxShadow:
                        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                      "li:hover": {
                        color: "#69c2d2",
                      },
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Project Version"
                    placeholder="Select option"
                    autoComplete="off"
                    sx={textFieldStyles}
                  />
                )}
              />
              <Autocomplete
                autoHighlight
                size="small"
                id="tags-outlined"
                options={Object.keys(dsToShow)}
                getOptionLabel={(option) => getDsOptionLabel(option)}
                autoComplete
                includeInputInList
                disableClearable
                className="select-dropdown"
                ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
                isOptionEqualToValue={(option, value) =>
                  option === value || value === ""
                }
                value={
                  modalForm && modalForm.sourceDs ? modalForm.sourceDs : ""
                }
                onChange={(e, value) => {
                  handleSelectChange(e, "sourceDs", value);
                }}
                componentsProps={{
                  paper: {
                    sx: {
                      backgroundColor: "#212b35",
                      color: "#d9d9d9",
                      borderRadius: "4px",
                      boxShadow:
                        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                      "li:hover": {
                        color: "#69c2d2",
                      },
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Datasource"
                    placeholder="Select option"
                    autoComplete="off"
                    sx={textFieldStyles}
                  />
                )}
              />
              <FormControl
                id="sourceProjFg"
                className="text-field"
                size="small"
              >
                <TextField
                  type="text"
                  name="sourceProjFg"
                  size="small"
                  label="Feature group name"
                  value={modalForm ? modalForm.sourceProjFg : ""}
                  disabled
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                />
              </FormControl>
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            sx={buttonStyles}
            onClick={() => modalProps.closeModal(modalType)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={buttonStyles}
            onClick={clickSubmit}
            disabled={isSaveDisabled || userAccessRestricted}
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarMsg}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMsg}
        action={action}
      />
    </div>
  );
};

export default PipelineDialog;
