import {
  Box,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import "./dbCfgForm.scss";
import { ReactComponent as FileUploadIcon } from "../../assets/icons/fileUploadIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import {
  labelStyles,
  textFieldStyles,
  menuItemStyles,
  selectStyles,
  formControlStyles,
} from "./dbCfgFormStyles";
import api from "../../apiInterceptor";
const getprojcfgAPITestData = require("../../assets/apiTestData/getprojcfg-test-data.json");

const DbCfgForm = ({ uploadedFile }) => {
  const inputRef = useRef(null);
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedProjFg = useSelector((state) => state.selectedFeatureGroup);
  const dbTypeOptionsList = [
    {
      name: "Mysql",
      value: "mysql",
    },
    {
      name: "PostgreSql",
      value: "postgres",
    },
    {
      name: "Oracle",
      value: "oracle",
    },
    {
      name: "SnowSql",
      value: "snowsql",
    },
    {
      name: "MariaDB",
      value: "mariadb",
    },
    {
      name: "SQL Server",
      value: "sqlserver",
    },
    {
      name: "BigQuery",
      value: "bigquery",
    },
  ];
  const authenticationOptions = [
    {
      name: "Active Directory Password",
      value: "ActiveDirectoryPassword",
    },
  ];
  const authTypeOptions = [
    {
      name: "No Authentication",
      value: "none",
    },
    {
      name: "Service Account",
      value: "servacc",
    },
  ];
  const dateFormatOptions = [
    "yyyy:MM:dd",
    "dd:MM:yyyy",
    "dd-MM-yyyy",
    "yyyy-MM-dd",
    "dd/MM/yyyy",
    "yyyyMMdd",
    "yyyy/MM/dd",
    "MM/dd/yyyy",
    "yyyy-MM-dd HH:mm:ss",
    "M/d/yyyy",
    "ddMMMyyyy:HH:mm:ss.S",
    "dd-MMM-yy",
    "ddMMMyyyy",
  ];
  const [dbTypeOptions, setDBTypeOptions] = useState(dbTypeOptionsList);
  const databaseConfigs = useSelector((state) => state.databaseConfigs);
  const dispatch = useDispatch();
  const { updateDatabaseConfigs, updateIsLoading, updateSnackbarMsg } =
    bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    getDBOptions();
  }, []);

  const getDBOptions = async () => {
    try {
      const apiUrl = BASE_API_URL + "getprojcfg";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: selectedProjectKey,
        projVersion: selectedProjVersion,
        projFg: selectedProjFg,
        nodeType: "database",
      };

      updateIsLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: getprojcfgAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        if (Array.isArray(result.options) && result.options.length > 0) {
          setDBTypeOptions(result.options);
        }
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateSnackbarMsg(response.data.data.reason);
        } else {
          updateSnackbarMsg("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateSnackbarMsg(errorMessage);
    }
  };

  const isOptionEqualToValue = (option, value, options) => {
    if (options[0]?.name) {
      options = options.map((opt) => opt.name);
    } else if (options[0]?.value) {
      options = options.map((opt) => opt.value);
    }
    if (options.includes(value)) {
      if (typeof option === "object") {
        return (
          option.value === value ||
          option.item === value ||
          option.name === value ||
          value === ""
        );
      } else {
        return option === value || value === "";
      }
    }
    return value === "";
  };

  const getNameFromValue = (value, options) => {
    const option = options.find((option) => option.value === value);
    return option ? option.name : value;
  };

  const renderTags = (value, getTagProps) =>
    value.map((option, index) => (
      <Tooltip key={option} title={option} placement="top">
        <Chip label={option} {...getTagProps({ index })} />
      </Tooltip>
    ));

  const getOptionLabel = (option) => {
    if (typeof option === "object") {
      if (option.name) {
        return option.name;
      } else if (option.item) {
        return option.item;
      }
      return option.value;
    }
    return option;
  };

  const handleDbInputChange = (evt, inputId = null) => {
    const clonedDbCfg = structuredClone(databaseConfigs);
    if (inputId === "colList") {
      clonedDbCfg[evt.target.name] = evt.target.value.split(",");
    } else {
      clonedDbCfg[evt.target.name] = evt.target.value;
    }
    updateDatabaseConfigs(clonedDbCfg);
  };

  const clearSelect = (inputName) => {
    const clonedDbCfg = structuredClone(databaseConfigs);
    clonedDbCfg[inputName] = null;
    updateDatabaseConfigs(clonedDbCfg);
  };

  const handleFileChange = async (e, inputId, fileDropped = false) => {
    let selectedFile = null;
    if (fileDropped) {
      e.preventDefault();
      selectedFile = e.dataTransfer.files[0];
    } else {
      selectedFile = e.target.files[0];
    }
    uploadedFile.current = selectedFile;
    const clonedDbCfg = structuredClone(databaseConfigs);
    const newfileDetails = {
      name: selectedFile.name,
      type: selectedFile.type,
      size: selectedFile.size,
    };
    clonedDbCfg[inputId] = newfileDetails;
    updateDatabaseConfigs(clonedDbCfg);
  };

  const handleFileRemove = (inputId) => {
    uploadedFile.current = null;
    const clonedDbCfg = structuredClone(databaseConfigs);
    clonedDbCfg[inputId] = "";
    updateDatabaseConfigs(clonedDbCfg);
  };

  const onInputClick = (event) => {
    event.target.value = "";
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleSelectChangeForDb = (inputId, value) => {
    const clonedDbCfg = structuredClone(databaseConfigs);
    clonedDbCfg[inputId] = value;
    updateDatabaseConfigs(clonedDbCfg);
  };

  const handleInputInSelectChange = (
    e,
    value,
    inputId,
    isInputEvent = false
  ) => {
    const clonedDbCfg = structuredClone(databaseConfigs);
    if (isInputEvent) {
      value = e.target.value;
    }
    clonedDbCfg[inputId] = value;
    updateDatabaseConfigs(clonedDbCfg);
  };

  return (
    <Stack sx={{ marginTop: "0 !important" }}>
      <TextField
        key={"name"}
        m={2}
        type={"text"}
        label={"Name"}
        variant="outlined"
        size="small"
        className="url-input"
        name={"name"}
        value={databaseConfigs["name"]}
        sx={textFieldStyles}
        autoComplete="off"
        onChange={handleDbInputChange}
      />
      <TextField
        key={"description"}
        m={2}
        type={"text"}
        label={"Description"}
        variant="outlined"
        size="small"
        className="url-input"
        name={"description"}
        value={databaseConfigs["description"]}
        sx={textFieldStyles}
        autoComplete="off"
        onChange={handleDbInputChange}
      />
      <Autocomplete
        key={"dbType"}
        autoHighlight
        size="small"
        id="tags-outlined-2"
        options={dbTypeOptions}
        getOptionLabel={(option) => getOptionLabel(option)}
        autoComplete
        includeInputInList
        disableClearable
        className="select-dropdown"
        ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
        isOptionEqualToValue={(option, value) => {
          return isOptionEqualToValue(option, value, dbTypeOptions);
        }}
        value={
          databaseConfigs["dbType"]
            ? getNameFromValue(databaseConfigs["dbType"], dbTypeOptions)
            : ""
        }
        onChange={(e, value) => {
          handleSelectChangeForDb("dbType", value.value);
        }}
        renderTags={renderTags}
        componentsProps={{
          paper: {
            sx: {
              backgroundColor: "#212b35",
              color: "#d9d9d9",
              borderRadius: "4px",
              boxShadow:
                "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
              "li:hover": {
                color: "#69c2d2",
              },
            },
          },
        }}
        renderInput={(params) => (
          <Tooltip
            title={getNameFromValue(databaseConfigs["dbType"], dbTypeOptions)}
            placement="right"
          >
            <TextField
              {...params}
              size="small"
              label={"DB type"}
              placeholder="Select option"
              autoComplete="off"
              sx={textFieldStyles}
            />
          </Tooltip>
        )}
        renderOption={(props, option) => (
          <li {...props}>
            <Tooltip title={option.name} placement="right">
              <Typography noWrap>{option.name}</Typography>
            </Tooltip>
          </li>
        )}
      />
      {databaseConfigs["dbType"] !== "bigquery" && (
        <>
          <TextField
            key={"dbHost"}
            m={2}
            type={"text"}
            label={"Host"}
            variant="outlined"
            size="small"
            className="url-input"
            name={"dbHost"}
            value={databaseConfigs["dbHost"]}
            sx={textFieldStyles}
            autoComplete="off"
            onChange={handleDbInputChange}
          />
          <TextField
            key={"dbPort"}
            m={2}
            type={"number"}
            label={"Port"}
            variant="outlined"
            size="small"
            className="url-input"
            name={"dbPort"}
            value={databaseConfigs["dbPort"]}
            sx={textFieldStyles}
            autoComplete="off"
            onChange={handleDbInputChange}
          />
          <TextField
            key={"dbName"}
            m={2}
            type={"text"}
            label={"DB Name"}
            variant="outlined"
            size="small"
            className="url-input"
            name={"dbName"}
            value={databaseConfigs["dbName"]}
            sx={textFieldStyles}
            autoComplete="off"
            onChange={handleDbInputChange}
          />
        </>
      )}
      {databaseConfigs["dbType"] === "bigquery" && (
        <>
          <TextField
            key={"parentProject"}
            m={2}
            type={"text"}
            label={"Parent Project"}
            variant="outlined"
            size="small"
            className="url-input"
            name={"parentProject"}
            value={databaseConfigs["parentProject"]}
            sx={textFieldStyles}
            autoComplete="off"
            onChange={handleDbInputChange}
          />
          <TextField
            key={"project"}
            m={2}
            type={"text"}
            label={"Project"}
            variant="outlined"
            size="small"
            className="url-input"
            name={"project"}
            value={databaseConfigs["project"]}
            sx={textFieldStyles}
            autoComplete="off"
            onChange={handleDbInputChange}
          />
          <TextField
            key={"dataset"}
            m={2}
            type={"text"}
            label={"Dataset Name"}
            variant="outlined"
            size="small"
            className="url-input"
            name={"dataset"}
            value={databaseConfigs["dataset"]}
            sx={textFieldStyles}
            autoComplete="off"
            onChange={handleDbInputChange}
          />
        </>
      )}
      <TextField
        key={"dbTable"}
        m={2}
        type={"text"}
        label={"Table Name"}
        variant="outlined"
        size="small"
        className="url-input"
        name={"dbTable"}
        value={databaseConfigs["dbTable"]}
        sx={textFieldStyles}
        autoComplete="off"
        onChange={handleDbInputChange}
      />
      {databaseConfigs["dbType"] !== "bigquery" && (
        <>
          <TextField
            key={"dbUser"}
            m={2}
            type={"text"}
            label={"DB User"}
            variant="outlined"
            size="small"
            className="url-input"
            name={"dbUser"}
            value={databaseConfigs["dbUser"]}
            sx={textFieldStyles}
            autoComplete="off"
            onChange={handleDbInputChange}
          />
          <TextField
            key={"dbPswd"}
            m={2}
            type={"password"}
            label={"Password"}
            variant="outlined"
            size="small"
            className="url-input"
            name={"dbPswd"}
            value={databaseConfigs["dbPswd"]}
            sx={textFieldStyles}
            autoComplete="off"
            onChange={handleDbInputChange}
          />
        </>
      )}
      {databaseConfigs.dbType === "sqlserver" && (
        <FormControl
          id="authentication"
          sx={formControlStyles}
          className="url-input"
          size="small"
        >
          <InputLabel sx={labelStyles}>Authentication</InputLabel>
          <Select
            placeholder="Authentication"
            name="authentication"
            label="Authentication"
            value={
              databaseConfigs["authentication"]
                ? databaseConfigs["authentication"]
                : ""
            }
            onChange={(evt) => handleDbInputChange(evt, true)}
            required
            size="small"
            sx={selectStyles}
            inputProps={{ sx: selectStyles }}
            MenuProps={{ sx: selectStyles }}
            autoWidth={true}
            endAdornment={
              databaseConfigs.authentication && (
                <InputAdornment sx={{ marginRight: "10px" }} position="end">
                  <IconButton
                    size="small"
                    onClick={() => clearSelect("authentication")}
                  >
                    <CloseIcon style={{ width: "20px" }} />
                  </IconButton>
                </InputAdornment>
              )
            }
          >
            {authenticationOptions.map((option, optionIndex) => (
              <MenuItem
                key={optionIndex}
                value={option.value}
                sx={menuItemStyles}
              >
                <Tooltip title={option.name} placement="right">
                  <Typography noWrap>{option.name}</Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {databaseConfigs["dbType"] === "bigquery" && (
        <FormControl
          id="authType"
          sx={formControlStyles}
          className="url-input"
          size="small"
        >
          <InputLabel sx={labelStyles}>Authentication Type</InputLabel>
          <Select
            placeholder="Authentication Type"
            name="authType"
            label="Authentication Type"
            value={
              databaseConfigs["authType"] ? databaseConfigs["authType"] : ""
            }
            onChange={(evt) => handleDbInputChange(evt, true)}
            required
            size="small"
            sx={selectStyles}
            inputProps={{ sx: selectStyles }}
            MenuProps={{ sx: selectStyles }}
            autoWidth={true}
          >
            {authTypeOptions.map((option, optionIndex) => (
              <MenuItem
                key={optionIndex}
                value={option.value}
                sx={menuItemStyles}
              >
                <Tooltip title={option.name} placement="right">
                  <Typography noWrap>{option.name}</Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {databaseConfigs["authType"] === "servacc" &&
        databaseConfigs["dbType"] === "bigquery" && (
          <Box sx={{ marginTop: "0px !important" }} key={"files"}>
            <input
              type="file"
              onChange={(evt) => handleFileChange(evt, "files")}
              onClick={onInputClick}
              className="db-file-upload-input"
              ref={inputRef}
            />
            <div
              className="select-file-button"
              onClick={() => inputRef.current.click()}
            >
              <FileUploadIcon className="file-upload-icon"></FileUploadIcon>
              <span>Upload credential file</span>
            </div>
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              className="file-upload-area"
              onDragOver={handleDragOver}
              onDrop={(evt) => handleFileChange(evt, "files", true)}
            >
              <Stack gap="10px">
                <Stack direction="row" gap="5px">
                  <p>
                    {databaseConfigs["files"]
                      ? databaseConfigs["files"].name
                      : "Drop your files here"}
                  </p>
                  <Tooltip title="Remove file" placement="top">
                    {databaseConfigs["files"] ? (
                      <Box
                        className="remove-file-icon"
                        onClick={(evt) => handleFileRemove("files")}
                      />
                    ) : (
                      <Box />
                    )}
                  </Tooltip>
                </Stack>
              </Stack>
              {databaseConfigs["files"] && (
                <p>{databaseConfigs["files"].size} bytes</p>
              )}
            </Stack>
          </Box>
        )}
      <FormControl
        key={"rowSelInfo"}
        id={"rowSelInfo"}
        sx={{ marginTop: "15px !important" }}
      >
        <FormLabel id="row-radio-buttons-group-label" sx={labelStyles}>
          {"Data coverage"}
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="row-radio-buttons-group-label"
          name={"rowSelInfo"}
          value={databaseConfigs["rowSelInfo"]}
          onChange={handleDbInputChange}
        >
          <FormControlLabel
            value={"all"}
            control={<Radio style={{ color: "#69C2D2" }} />}
            label={"Entire records"}
          />
          <FormControlLabel
            value={"duration"}
            control={<Radio style={{ color: "#69C2D2" }} />}
            label={"Duration"}
          />
          <FormControlLabel
            value={"query"}
            control={<Radio style={{ color: "#69C2D2" }} />}
            label={"Query"}
          />
          <FormControlLabel
            value={"identifier"}
            control={<Radio style={{ color: "#69C2D2" }} />}
            label={"Identifier"}
          />
        </RadioGroup>
      </FormControl>
      {databaseConfigs.rowSelInfo === "duration" ? (
        <Stack>
          <TextField
            key={"dateCol"}
            m={2}
            type={"text"}
            label={"Date Column"}
            variant="outlined"
            size="small"
            className="url-input"
            name={"dateCol"}
            value={databaseConfigs["dateCol"]}
            sx={textFieldStyles}
            autoComplete="off"
            onChange={handleDbInputChange}
          />
          <Autocomplete
            freeSolo
            sx={{ marginTop: "1rem" }}
            options={dateFormatOptions}
            getOptionLabel={(option) => option}
            value={databaseConfigs["dateFormat"]}
            ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
            onChange={(e, value) =>
              handleInputInSelectChange(e, value, "dateFormat")
            }
            componentsProps={{
              paper: {
                sx: {
                  backgroundColor: "#212b35",
                  color: "#d9d9d9",
                  borderRadius: "4px",
                  boxShadow:
                    "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                  "li:hover": {
                    color: "#69c2d2",
                  },
                },
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label={"Date Format"}
                placeholder={"Date Format"}
                sx={textFieldStyles}
                autoComplete="off"
                onChange={(e) =>
                  handleInputInSelectChange(e, "", "dateFormat", true)
                }
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Tooltip title={option} placement="right">
                  <Typography noWrap>{option}</Typography>
                </Tooltip>
              </li>
            )}
          />
          <FormControl
            id={"durationInfo"}
            sx={{ marginTop: "15px !important" }}
          >
            <FormLabel id="row-radio-buttons-group-label" sx={labelStyles}>
              {"Data coverage"}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="row-radio-buttons-group-label"
              name={"durationInfo"}
              value={databaseConfigs["durationInfo"]}
              onChange={handleDbInputChange}
            >
              <FormControlLabel
                value={"range"}
                control={<Radio style={{ color: "#69C2D2" }} />}
                label={"Range"}
              />
              <FormControlLabel
                value={"offset"}
                control={<Radio style={{ color: "#69C2D2" }} />}
                label={"Offset"}
              />
            </RadioGroup>
          </FormControl>
          {databaseConfigs["durationInfo"] === "range" ? (
            <Stack>
              <TextField
                key={"startDate"}
                m={2}
                type={"text"}
                label={"Start Date"}
                variant="outlined"
                size="small"
                className="url-input"
                name={"startDate"}
                value={databaseConfigs["startDate"]}
                sx={textFieldStyles}
                autoComplete="off"
                onChange={handleDbInputChange}
              />
              <TextField
                key={"endDate"}
                m={2}
                type={"text"}
                label={"End Date"}
                variant="outlined"
                size="small"
                className="url-input"
                name={"endDate"}
                value={databaseConfigs["endDate"]}
                sx={textFieldStyles}
                autoComplete="off"
                onChange={handleDbInputChange}
              />
            </Stack>
          ) : databaseConfigs["durationInfo"] === "offset" ? (
            <Stack
              direction="row"
              gap="10px"
              alignItems="center"
              className="select-container"
            >
              <FormControl key={"duration"} id={"duration"} size="small">
                <TextField
                  type={"number"}
                  name={"duration"}
                  label={"Duration"}
                  size="small"
                  autoComplete="off"
                  value={databaseConfigs["duration"]}
                  onChange={handleDbInputChange}
                  variant="outlined"
                  sx={textFieldStyles}
                  required
                />
              </FormControl>
              <FormControl
                key={"offsetUnit"}
                id={"offsetUnit"}
                sx={formControlStyles}
                className="w-100"
                size="small"
              >
                <InputLabel sx={labelStyles}>{"Duration Time"}</InputLabel>
                <Select
                  placeholder="Select option"
                  name={"offsetUnit"}
                  label={"Duration Time"}
                  size="small"
                  value={databaseConfigs["offsetUnit"]}
                  onChange={handleDbInputChange}
                  required
                  sx={selectStyles}
                  inputProps={{ sx: selectStyles }}
                  MenuProps={{ sx: selectStyles }}
                  autoWidth={true}
                >
                  <MenuItem value={"days"} sx={menuItemStyles}>
                    <Tooltip title={"Days"} placement="right">
                      <Typography noWrap>{"Days"}</Typography>
                    </Tooltip>
                  </MenuItem>
                  <MenuItem value={"month"} sx={menuItemStyles}>
                    <Tooltip title={"Months"} placement="right">
                      <Typography noWrap>{"Months"}</Typography>
                    </Tooltip>
                  </MenuItem>
                  <MenuItem value={"year"} sx={menuItemStyles}>
                    <Tooltip title={"Years"} placement="right">
                      <Typography noWrap>{"Years"}</Typography>
                    </Tooltip>
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>
          ) : (
            <></>
          )}
        </Stack>
      ) : databaseConfigs.rowSelInfo === "query" ? (
        <Stack>
          <TextField
            key={"query"}
            m={2}
            type={"text"}
            label={"Query"}
            variant="outlined"
            size="small"
            className="url-input"
            name={"query"}
            value={databaseConfigs["query"]}
            sx={textFieldStyles}
            autoComplete="off"
            onChange={handleDbInputChange}
          />
        </Stack>
      ) : databaseConfigs.rowSelInfo === "identifier" ? (
        <Stack>
          <TextField
            key={"idList"}
            m={2}
            type={"text"}
            label={"Comma seperated column names"}
            variant="outlined"
            size="small"
            className="url-input"
            name={"idList"}
            value={databaseConfigs["idList"]}
            sx={textFieldStyles}
            autoComplete="off"
            onChange={handleDbInputChange}
          />
        </Stack>
      ) : (
        <></>
      )}
      {databaseConfigs.rowSelInfo === "all" ||
      databaseConfigs.rowSelInfo === "duration" ? (
        <Stack>
          <FormControl
            key={"colSelInfo"}
            id={"colSelInfo"}
            sx={{ marginTop: "15px !important" }}
          >
            <FormLabel id="row-radio-buttons-group-label" sx={labelStyles}>
              {"Data coverage"}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="row-radio-buttons-group-label"
              name={"colSelInfo"}
              value={databaseConfigs["colSelInfo"]}
              onChange={handleDbInputChange}
            >
              <FormControlLabel
                value={"all"}
                control={<Radio style={{ color: "#69C2D2" }} />}
                label={"All Columns"}
              />
              <FormControlLabel
                value={"partial"}
                control={<Radio style={{ color: "#69C2D2" }} />}
                label={"Partial Columns"}
              />
            </RadioGroup>
          </FormControl>
          {databaseConfigs.colSelInfo === "partial" ? (
            <FormControl id={"colList"} className="text-field" size="small">
              <TextField
                type={"countedWordsInput"}
                name={"colList"}
                size="small"
                label={"Comma seperated column names"}
                value={databaseConfigs["colList"]}
                onChange={(e) => handleDbInputChange(e, "colList")}
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyles}
                required
              />
            </FormControl>
          ) : (
            <></>
          )}
        </Stack>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default DbCfgForm;
