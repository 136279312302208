const textFieldStyles = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#D9D9D9",
    },
    "&:hover fieldset": {
      borderColor: "#69C2D2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#69C2D2",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#D9D9D9",
  },
  "& .MuiInputLabel-root.Mui-error": {
    color: "#d32f2f",
  },
  "& .MuiInputLabel-outlined.Mui-focused": {
    color: "#69C2D2",
    "& .MuiInputLabel-asterisk": {
      color: "#69C2D2",
    },
  },
  "& .MuiInputLabel-outlined.Mui-focused.Mui-error": {
    color: "#69C2D2",
  },
  "& .MuiInputBase-root": {
    color: "#D9D9D9",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#D9D9D9",
  },
  "& .Mui-disabled ": {
    color: "#D9D9D9",
    "-webkit-text-fill-color": "#D9D9D9 !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#D9D9D9 !important",
    },
  },
  svg: {
    fill: "#D9D9D9",
  },
};

const buttonStyles = {
  color: "white",
  background: "#69c2d2",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "28px",
  textTransform: "none",
  ":hover": {
    background: "#40bcd2",
  },
  ":disabled": {
    background: "rgb(213 205 205 / 59%)",
  },
};
const formControlStyles = {
  "& .MuiInputLabel-root, & .MuiOutlinedInput-root": {
    color: "#D9D9D9",
    marginTop: "15px",
  },
};
const labelStyles = {
  color: "#69C2D2",
  "&.Mui-focused": {
    color: "#69C2D2",
  },
};
const selectStyles = {
  color: "#D9D9D9",
  "&:hover, &.Mui-focused, &.MuiSelect-nativeOpen": {
    color: "#D9D9D9",
    borderColor: "#D9D9D9",
  },
  "&:hover": {
    "&& fieldset": {
      borderColor: "#69C2D2",
    },
  },
  ul: {
    maxWidth: "300px !important",
    maxHeight: "600px",
    backgroundColor: "#212B35",
    li: {
      "&:hover": { color: "#69C2D2" },
      "&:selected": { backgroundColor: "none" },
      maxWidth: "300px !important",
    },
  },
  svg: {
    fill: "#D9D9D9",
  },
  fieldset: {
    borderColor: "#D9D9D9",
    "&:hover, &.Mui-focused": {
      borderColor: "#D9D9D9",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#D9D9D9",
    borderWidth: "1px",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#69C2D2",
    borderWidth: "1px",
  },
};

const menuItemStyles = {
  color: "#D9D9D9",
  backgroundColor: "#212B35",
  "&.Mui-selected": {
    color: "#69C2D2",
  },
  "&:hover": {
    color: "#D9D9D9",
  },
};

const chipTextFieldStyles = {
  "& .MuiOutlinedInput-root": {
    overflow: "hidden",
    maxHeight: "200px",
    "&.Mui-focused": {
      overflow: "scroll",
      maxHeight: "fit-content",
      marginBottom: "200px",
    },
    "& fieldset": {
      borderColor: "#D9D9D9",
    },
    "&:hover fieldset": {
      borderColor: "#69C2D2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#69C2D2",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#D9D9D9",
  },
  "& .MuiInputLabel-root.Mui-error": {
    color: "#d32f2f",
  },
  "& .MuiInputLabel-outlined.Mui-focused": {
    color: "#69C2D2",
    "& .MuiInputLabel-asterisk": {
      color: "#69C2D2",
    },
  },
  "& .MuiInputLabel-outlined.Mui-focused.Mui-error": {
    color: "#69C2D2",
  },
  "& .MuiInputBase-root": {
    color: "#D9D9D9",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#D9D9D9",
  },
  svg: {
    fill: "#D9D9D9",
  },
};

export {
  buttonStyles,
  formControlStyles,
  labelStyles,
  selectStyles,
  menuItemStyles,
  textFieldStyles,
  chipTextFieldStyles,
};
