import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  Autocomplete,
  InputAdornment,
  FormControl,
  TextField,
} from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch } from "react-redux";
import "./dateFeatureDialog.scss";
import api from "../../apiInterceptor";
import { buttonStyles, textFieldStyles } from "./dateFeatureDialogStyle";
const perffeatmapAPITestData = require("../../assets/apiTestData/success-test-data.json");

const DateFeatureDialog = ({
  dateDialogOpen,
  dateTypeFeatureList,
  setDateDialogOpen,
  dsData,
  featuresInfo,
  saveFeatureMapData,
}) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const dispatch = useDispatch();
  const { updateIsLoading, updateSnackbarMsg } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [dateCfg, setDateCfg] = useState({});

  const dateMapOptions = [
    "yyyy:MM:dd",
    "dd:MM:yyyy",
    "dd-MM-yyyy",
    "yyyy-MM-dd",
    "dd/MM/yyyy",
    "yyyyMMdd",
    "yyyy/MM/dd",
    "MM/dd/yyyy",
    "yyyy-MM-dd HH:mm:ss",
    "M/d/yyyy",
    "ddMMMyyyy:HH:mm:ss.S",
    "dd-MMM-yy",
    "ddMMMyyyy",
  ];

  useEffect(() => {
    setIsSaveDisabled(
      dateTypeFeatureList.length !== Object.keys(dateCfg).length ||
        Object.values(dateCfg).some((cfg) => !cfg.userFormat)
    );
  }, [dateCfg, dateTypeFeatureList]);

  const handleInputInSelectChange = (event, value, feature) => {
    setDateCfg((prev) => ({
      ...prev,
      [feature]: {
        userFormat: value,
        reqdFormat: featuresInfo[feature].reqdDateFormat,
      },
    }));
  };

  useEffect(() => {
    dateTypeFeatureList.forEach((feature) => {
      handleInputInSelectChange(
        null,
        featuresInfo[feature].reqdDateFormat,
        feature
      );
    });
  }, []);

  const closeDialog = () => {
    setDateDialogOpen(false);
  };

  const handleSave = async () => {
    try {
      const apiUrl = BASE_API_URL + "perffeatdatemap";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: dsData.projectKey,
        projVersion: dsData.projVersion,
        projFg: dsData.projFg,
        dSource: dsData.dsKey,
        dateCfg: dateCfg,
      };
      let response = {};
      updateIsLoading(true);
      if (USING_TEST_DATA) {
        response = {
          data: perffeatmapAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        closeDialog();
        saveFeatureMapData();
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateSnackbarMsg(response.data.data.reason);
        } else {
          updateSnackbarMsg("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      updateIsLoading(false);
      console.error(error);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateSnackbarMsg(errorMessage);
    }
  };

  return (
    <Dialog
      fullWidth
      open={dateDialogOpen}
      aria-labelledby="datefeature-dialog"
      className="datefeature-dialog"
      maxWidth="sm"
      PaperProps={{
        style: {
          background: "#212B35",
          border: "1px solid #818181",
          boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          color: "#EFF1F1",
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title">
        Configure Date Format
        <IconButton onClick={closeDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="datefeature-table-container">
          <Stack className="table">
            <Stack className="table-header" direction="row">
              <Box
                className="header-item"
                sx={{ width: "50%", paddingLeft: "8px !important" }}
              >
                Feature
              </Box>
              <Box className="header-item" sx={{ width: "50%" }}>
                Date Format
              </Box>
            </Stack>
            <div className="table-content">
              {dateTypeFeatureList.map((feature) => (
                <Stack key={feature} className="table-row" direction="row">
                  <Box
                    className="row-item"
                    sx={{ width: "50%", paddingLeft: "8px !important" }}
                  >
                    <Tooltip title={feature} placement="top-start">
                      <Typography noWrap sx={{ fontSize: "13px" }}>
                        {feature}
                      </Typography>
                    </Tooltip>
                  </Box>
                  <FormControl
                    className="text-field"
                    size="small"
                    sx={{
                      marginTop: "0px !important",
                      padding: "4px",
                      width: "50%",
                    }}
                  >
                    <Autocomplete
                      freeSolo
                      disableClearable
                      options={dateMapOptions}
                      getOptionLabel={(option) => option}
                      value={dateCfg[feature]?.userFormat || ""}
                      ListboxProps={{
                        style: { maxHeight: 300, overflow: "auto" },
                      }}
                      onChange={(e, value) =>
                        handleInputInSelectChange(e, value, feature)
                      }
                      componentsProps={{
                        paper: {
                          sx: {
                            backgroundColor: "#212b35",
                            color: "#d9d9d9",
                            borderRadius: "4px",
                            boxShadow:
                              "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                            "li:hover": { color: "#69c2d2" },
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={textFieldStyles}
                          autoComplete="off"
                          onChange={(e) =>
                            handleInputInSelectChange(
                              e,
                              e.target.value,
                              feature
                            )
                          }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {params.InputProps.endAdornment}
                                <InputAdornment position="end">
                                  <IconButton
                                    sx={{ width: "30px", height: "30px" }}
                                    onClick={() =>
                                      handleInputInSelectChange(
                                        null,
                                        "",
                                        feature
                                      )
                                    }
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </InputAdornment>
                              </>
                            ),
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Tooltip title={option} placement="right">
                            <Typography noWrap>{option}</Typography>
                          </Tooltip>
                        </li>
                      )}
                    />
                  </FormControl>
                </Stack>
              ))}
            </div>
          </Stack>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={buttonStyles}
          onClick={handleSave}
          disabled={isSaveDisabled}
          autoFocus
        >
          Save
        </Button>
        <Button
          autoFocus
          variant="contained"
          sx={buttonStyles}
          onClick={closeDialog}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DateFeatureDialog;
