import api from "apiInterceptor";
import { isEmpty } from "lodash";

const filterColConditions = ["eq", "neq", "null-excl", "null-incl"];

const intilizeRowAndDerivedFeatures = (colInfo, nodeColsInfo) => {
  const rowFeatures = nodeColsInfo.rowFeatures.enable
    ? colInfo?.rowFeatures
    : {
        numerical: [],
        categorical: [],
        date: [],
        mixed: [],
      };
  const derivedFeatures = nodeColsInfo.derivedFeatures.enable
    ? colInfo?.derivedFeatures
    : {
        numerical: [],
        categorical: [],
        date: [],
        mixed: [],
      };
  rowFeatures["numerical"] = nodeColsInfo.rowFeatures.showNum
    ? rowFeatures.numerical
    : [];
  derivedFeatures["numerical"] = nodeColsInfo.derivedFeatures.showNum
    ? derivedFeatures.numerical
    : [];
  rowFeatures["categorical"] = nodeColsInfo.rowFeatures.showCat
    ? rowFeatures.categorical
    : [];
  derivedFeatures["categorical"] = nodeColsInfo.derivedFeatures.showCat
    ? derivedFeatures.categorical
    : [];
  rowFeatures["date"] = nodeColsInfo.rowFeatures.showDate
    ? rowFeatures.date
    : [];
  derivedFeatures["date"] = nodeColsInfo.derivedFeatures.showDate
    ? derivedFeatures.date
    : [];
  rowFeatures["mixed"] = nodeColsInfo.rowFeatures.showMixed
    ? rowFeatures.mixed
    : [];
  derivedFeatures["mixed"] = nodeColsInfo.derivedFeatures.showMixed
    ? derivedFeatures.mixed
    : [];
  return {
    rowFeat: rowFeatures,
    derivedFeat: derivedFeatures,
  };
};

const prepareColumns = (columnsInfo, nodeColsInfo) => {
  let columns = [];
  let index = 0;
  let commonFeatures = [];
  let allColumnsInfo = {};
  for (let colInfo of columnsInfo) {
    const features = intilizeRowAndDerivedFeatures(colInfo, nodeColsInfo);
    const rowFeatures = features.rowFeat;
    const derivedFeatures = features.derivedFeat;
    if (allColumnsInfo.numerical) {
      allColumnsInfo["numerical"] = [
        ...allColumnsInfo.numerical,
        ...rowFeatures.numerical,
        ...derivedFeatures.numerical,
      ];
    } else {
      allColumnsInfo["numerical"] = [
        ...rowFeatures.numerical,
        ...derivedFeatures.numerical,
      ];
    }
    if (allColumnsInfo.categorical) {
      allColumnsInfo["categorical"] = [
        ...allColumnsInfo.categorical,
        ...rowFeatures.categorical,
        ...derivedFeatures.categorical,
      ];
    } else {
      allColumnsInfo["categorical"] = [
        ...rowFeatures.categorical,
        ...derivedFeatures.categorical,
      ];
    }
    if (allColumnsInfo.date) {
      allColumnsInfo["date"] = [
        ...allColumnsInfo.date,
        ...rowFeatures.date,
        ...derivedFeatures.date,
      ];
    } else {
      allColumnsInfo["date"] = [...rowFeatures.date, ...derivedFeatures.date];
    }
    if (allColumnsInfo.mixed) {
      allColumnsInfo["mixed"] = [
        ...allColumnsInfo.mixed,
        ...rowFeatures.mixed,
        ...derivedFeatures.mixed,
      ];
    } else {
      allColumnsInfo["mixed"] = [
        ...rowFeatures.mixed,
        ...derivedFeatures.mixed,
      ];
    }
    allColumnsInfo["numerical"] = [...new Set(allColumnsInfo["numerical"])];
    allColumnsInfo["categorical"] = [...new Set(allColumnsInfo["categorical"])];
    allColumnsInfo["date"] = [...new Set(allColumnsInfo["date"])];
    allColumnsInfo["mixed"] = [...new Set(allColumnsInfo["mixed"])];
    const colItems = structuredClone(allColumnsInfo);
    colItems.numerical.forEach((numCol) => columns.push(numCol));
    colItems.categorical.forEach((catCol) => columns.push(catCol));
    colItems.date.forEach((datCol) => columns.push(datCol));
    colItems.mixed.forEach((mixedCol) => columns.push(mixedCol));
    commonFeatures = getCommonColumns(
      rowFeatures,
      derivedFeatures,
      commonFeatures,
      index
    );
    index++;
  }
  columns = [...new Set(columns)];
  return {
    columns: columns,
    allColumnsInfo: allColumnsInfo,
    commonFeatures: commonFeatures,
  };
};

const getCommonColumns = (
  rowFeatures,
  derivedFeatures,
  commonFeatures,
  index
) => {
  let filteredArray = [];
  let currentDsFeatures = [
    ...rowFeatures.numerical,
    ...derivedFeatures.numerical,
    ...rowFeatures.categorical,
    ...derivedFeatures.categorical,
    ...rowFeatures.date,
    ...derivedFeatures.date,
    ...rowFeatures.mixed,
    ...derivedFeatures.mixed,
  ];
  currentDsFeatures = [...new Set(currentDsFeatures)];
  if (index === 0) {
    filteredArray = structuredClone(currentDsFeatures);
  } else {
    filteredArray = commonFeatures.filter((value) =>
      currentDsFeatures.includes(value)
    );
  }
  return filteredArray;
};

const handleColumnFilterDisabling = (filterObj) => {
  if (
    !filterObj.filterCol ||
    !filterColConditions.includes(filterObj.filterCond)
  ) {
    return true;
  } else if (filterObj.filterCond === "eq" || filterObj.filterCond === "neq") {
    if (
      filterObj.valueType === "map" &&
      (!filterObj.mapCfg.mapDs ||
        !filterObj.mapCfg.mapKeyFeat ||
        !filterObj.mapCfg.mapKeyVal ||
        !filterObj.mapCfg.mapValFeat)
    ) {
      return true;
    } else if (
      filterObj.valueType === "constant" &&
      (!filterObj.filterValues || filterObj.filterValues.length === 0)
    ) {
      return true;
    } else if (
      filterObj.valueType === "variable" &&
      !filterObj.selectedVariable
    ) {
      return true;
    } else if (filterObj.valueType === undefined) {
      return true;
    }
  }
  return false;
};

const handleFilterSaveDisable = (filterCfg) => {
  if (isEmpty(filterCfg)) {
    return true;
  } else {
    for (let filterItem of filterCfg) {
      if (!filterItem.cfg.name || !filterItem.subtype) {
        return true;
      }
      if (
        filterItem.subtype === "substr-filter" &&
        (!filterItem.cfg.filterCol ||
          !filterItem.cfg.filterCond ||
          !filterItem.cfg.subStr)
      ) {
        return true;
      } else if (filterItem.subtype === "col-value") {
        const disableSave = handleColumnFilterDisabling(filterItem.cfg);
        if (disableSave) {
          return disableSave;
        }
      } else if (filterItem.subtype === "rule-filter") {
        if (
          !filterItem.cfg["ruleCfg"] ||
          !filterItem.cfg.ruleCfg.stmtOrder.length
        ) {
          return true;
        } else if (filterItem.cfg.ruleCfg.stmtOrder.length) {
          const disableSave = handleSaveDisablingInStatements(filterItem);
          if (disableSave) {
            return disableSave;
          }
        }
        return false;
      }
    }
  }
  return false;
};

const handleSaveDisablingInStatements = (filterItem) => {
  for (let statement of filterItem.cfg.ruleCfg.stmtOrder) {
    if (
      !filterItem.cfg.ruleCfg[statement].stmtName ||
      !filterItem.cfg.ruleCfg[statement].filterType
    ) {
      return true;
    } else if (filterItem.cfg.ruleCfg[statement].filterType === "colVal") {
      const disableSave = handleColumnFilterDisabling(
        filterItem.cfg.ruleCfg[statement]
      );
      if (disableSave) {
        return disableSave;
      }
    } else if (filterItem.cfg.ruleCfg[statement].filterType === "sub-Str") {
      if (
        !filterItem.cfg.ruleCfg[statement].filterCol ||
        !filterItem.cfg.ruleCfg[statement].filterCond ||
        // !filterItem.cfg.ruleCfg[statement].valueType ||
        !filterItem.cfg.ruleCfg[statement].subStr
      ) {
        return true;
      }
    } else if (filterItem.cfg.ruleCfg[statement].filterType === "operType") {
      if (
        !filterItem.cfg.ruleCfg[statement].lhsType ||
        !filterItem.cfg.ruleCfg[statement].lhs ||
        !filterItem.cfg.ruleCfg[statement].operator ||
        !filterItem.cfg.ruleCfg[statement].rhsType ||
        !filterItem.cfg.ruleCfg[statement].rhs
      ) {
        return true;
      }
    }
  }
  return false;
};

const updateInputsVisibility = (inputs, input, nodeConfigs, allInputs) => {
  const selectedVal = nodeConfigs[input.identifier];
  const dependentInputs = inputs.filter((inp) => {
    if (typeof inp.dependentOn === "string") {
      return inp.dependentOn === selectedVal;
    } else if (typeof inp.dependentOn === "number") {
      return inp.dependentOn === selectedVal;
    } else if (typeof inp.dependentOn === "boolean") {
      return inp.dependentOn === selectedVal;
    } else if (Array.isArray(inp.dependentOn)) {
      return inp.dependentOn.includes(selectedVal);
    }
  });
  dependentInputs.forEach((depInp) => {
    depInp.hidden = false;
    if (depInp.mappedWith) {
      const mappedInputs = allInputs.filter((inp) =>
        depInp.mappedWith.includes(inp.identifier)
      );
      updateInputsVisibility(mappedInputs, depInp, nodeConfigs, allInputs);
    }
  });
};

const restoreDialogUI = (currentModalUI, selectedNode, nodeConfigurations) => {
  const nodeConfigs = nodeConfigurations[selectedNode.id]
    ? structuredClone(nodeConfigurations[selectedNode.id])
    : null;
  const allInputs = structuredClone(currentModalUI.input);
  if (nodeConfigs) {
    for (let input of allInputs) {
      if (input.mappedWith && !input.dependentOn) {
        const mappedInputs = allInputs.filter((inp) =>
          input.mappedWith.includes(inp.identifier)
        );
        updateInputsVisibility(mappedInputs, input, nodeConfigs, allInputs);
      }
    }
  }
  return allInputs;
};

const getPreviousUnStagedNode = (flow, node) => {
  if (!node.data.isStage) {
    return node;
  }
  const incomingEdge = flow.edges.find((edge) => edge.source === node.id);
  if (!incomingEdge) {
    return;
  }
  const prevNode = flow.nodes.find((node) => node.id === incomingEdge.target);
  return getPreviousUnStagedNode(flow, prevNode);
};

const getConnectedDataSources = (nodesFlow, selectedNode, getNode) => {
  const connectedDataSources = [];
  nodesFlow.edges.forEach((edge) => {
    if (edge.source === selectedNode.id) {
      const incomingUnStagedNode = getPreviousUnStagedNode(
        nodesFlow,
        getNode(edge.target)
      );
      if (incomingUnStagedNode) {
        connectedDataSources.push(incomingUnStagedNode.id);
      }
    }
  });
  return connectedDataSources;
};

const getConnectedNodes = (nodesFlow, selectedNode, getNode) => {
  const connectedNodes = [];
  nodesFlow.edges.forEach((edge) => {
    if (edge.source === selectedNode.id) {
      let connectionObj = {
        dSource: null,
        stageId: null,
      };
      const prevNode = getNode(edge.target);
      if (prevNode.data.isStage) {
        const incomingUnStagedNode = getPreviousUnStagedNode(
          nodesFlow,
          prevNode
        );
        if (incomingUnStagedNode) {
          connectionObj.dSource = incomingUnStagedNode.id;
        }
        connectionObj.stageId = prevNode.id;
      } else {
        connectionObj.dSource = prevNode.id;
      }
      connectedNodes.push(connectionObj);
    }
  });
  return connectedNodes;
};

const fetchFeatures = async (
  ds,
  stageId,
  selectedProjectKey,
  selectedProjVersion,
  selectedFeatureGroup,
  updateIsLoading,
  updateAlertMessage
) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const apiUrl = BASE_API_URL + "fetchpartialfeatures";
  const headers = {
    "Content-type": "application/json",
    Accept: "text/plain",
  };
  const payload = {
    projectKey: selectedProjectKey,
    projVersion: selectedProjVersion,
    projFg: selectedFeatureGroup,
    terminator: {
      dSource: ds,
      stageId: stageId,
    },
  };
  updateIsLoading(true);
  try {
    if (USING_TEST_DATA) {
      const fetchpartialfeaturesAPITestData = require("../assets/apiTestData/fetchpartialfeatures-test-data.json");
      updateIsLoading(false);
      return fetchpartialfeaturesAPITestData.data.posts[0]["dndnode_12"];
    }
    const response = await api.post(apiUrl, payload, {
      headers: headers,
      withCredentials: true,
    });
    updateIsLoading(false);
    if (response.data.status === 200) {
      const result = response.data.data.posts[0];
      return result[ds];
    } else if (response.data.status === 404) {
      return null;
    }
  } catch (error) {
    console.log(error);
    updateIsLoading(false);
    const errorMessage =
      "Something went wrong. Please contact the administrator";
    updateAlertMessage(errorMessage);
    return null;
  }
};

const getCurrentProjectDetails = async (
  projectKey,
  updateIsLoading,
  updateAlertMessage
) => {
  try {
    const BASE_API_URL = localStorage.getItem("BASE_API_URL");
    let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
    USING_TEST_DATA =
      USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
    let IS_LIST_PROJECTS_GET_TYPE = localStorage.getItem(
      "IS_LIST_PROJECTS_GET_TYPE"
    );
    IS_LIST_PROJECTS_GET_TYPE =
      IS_LIST_PROJECTS_GET_TYPE === "true" || IS_LIST_PROJECTS_GET_TYPE === true
        ? true
        : false;
    const apiUrl = BASE_API_URL + "listprojects";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = {};
    updateIsLoading(true);
    let response = {};
    if (USING_TEST_DATA) {
      const listprojectsAPITestData = require("../assets/apiTestData/listprojects-test-data.json");

      response = {
        data: listprojectsAPITestData,
      };
    } else {
      if (IS_LIST_PROJECTS_GET_TYPE) {
        response = await api.get(apiUrl, {
          headers: headers,
        });
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
    }
    updateIsLoading(false);
    if (response.data.status === 200) {
      const projects = response.data.data.posts[0];
      const project = projects.find((proj) => proj.projectKey === projectKey);
      return project;
    } else if (response.data.status === 404) {
      if (response.data.data.reason) {
        updateAlertMessage(response.data.data.reason);
      } else {
        updateAlertMessage("Something went wrong. Please try again later");
      }
    }
  } catch (error) {
    console.log(error);
    updateIsLoading(false);
    const errorMessage =
      "Something went wrong. Please contact the administrator";
    updateAlertMessage(errorMessage);
  }
};

const getConfigureVariablesdata = async (
  selectedProjectKey,
  selectedProjVersion,
  selectedFeatureGroup,
  updateIsLoading,
  updateAlertMessage
) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const apiUrl = BASE_API_URL + "fetchvarinfo";
  const headers = {
    "Content-type": "application/json",
    Accept: "text/plain",
  };
  const payload = {
    projectKey: selectedProjectKey,
    projVersion: selectedProjVersion,
    projFg: selectedFeatureGroup,
  };
  updateIsLoading(true);
  try {
    let response = {};
    if (USING_TEST_DATA) {
      const fetchvarinfoAPITestData = require("../assets/apiTestData/fetchvarinfo-test-data.json");
      response = {
        data: fetchvarinfoAPITestData,
      };
    } else {
      response = await api.post(apiUrl, payload, {
        headers: headers,
        withCredentials: true,
      });
    }
    updateIsLoading(false);
    if (response.data.status === 200) {
      const result = response.data.data.posts[0];
      return result;
    } else if (response.data.status === 404) {
      return null;
    }
  } catch (error) {
    console.log(error);
    updateIsLoading(false);
    const errorMessage =
      "Something went wrong. Please contact the administrator";
    updateAlertMessage(errorMessage);
    return null;
  }
};

const updateDependentInputs = (
  inputsList,
  newModalUI,
  inputVal,
  mappedInput,
  modalForm
) => {
  inputsList.forEach((input) => {
    if (mappedInput.includes(input.identifier)) {
      // To check if dependentOn is an array or a string and compare it with inputVal.
      input.hidden = !(
        (typeof input.dependentOn === "string" &&
          inputVal === input.dependentOn) ||
        (typeof input.dependentOn === "number" &&
          Number(inputVal) === input.dependentOn) ||
        (typeof input.dependentOn === "boolean" &&
          inputVal === input.dependentOn) ||
        (Array.isArray(input.dependentOn) &&
          input.dependentOn.includes(inputVal))
      );
      if (input.mappedWith && modalForm[input.identifier] && !input.hidden) {
        inputsList = updateDependentInputs(
          inputsList,
          newModalUI,
          modalForm[input.identifier],
          input.mappedWith,
          modalForm
        );
      }
    }
  });
  return inputsList;
};

const resetDependentInputsVisibility = (mappedInput, newModalUI) => {
  newModalUI.input.forEach((input) => {
    if (mappedInput.includes(input.identifier)) {
      input.hidden = true;
      if (input.mappedWith) {
        newModalUI = resetDependentInputsVisibility(
          input.mappedWith,
          newModalUI
        );
      }
    }
  });
  return newModalUI;
};

const handleDependentInputs = (modalUI, mappedInput, newValue, modalForm) => {
  const newModalUI = structuredClone(
    resetDependentInputsVisibility(
      structuredClone(mappedInput),
      structuredClone(modalUI)
    )
  );
  let inputs = newModalUI.input;
  inputs = structuredClone(
    updateDependentInputs(
      structuredClone(inputs),
      newModalUI,
      newValue,
      mappedInput,
      modalForm
    )
  );
  return inputs;
};

const initializeFilterInfo = (
  nodeConfigurations,
  filterCfg,
  statementNames,
  selectedNode
) => {
  const newFilterCfg = structuredClone(filterCfg);
  const newStatementNames = structuredClone(statementNames);
  nodeConfigurations[selectedNode.id].filterCfg.forEach((data, index) => {
    newFilterCfg[index] = data.dataCfg;
    newStatementNames[index] = {};
    if (data.dataCfg.cfg.ruleCfg) {
      data.dataCfg.cfg.ruleCfg.stmtOrder.forEach((statementID) => {
        newStatementNames[index][statementID] =
          data.dataCfg.cfg.ruleCfg[statementID].stmtName;
      });
    }
  });
  return {
    newStatementNames: newStatementNames,
    newFilterCfg: newFilterCfg,
  };
};

const handleGeneralSaveButtonDisabling = (inputs, modalForm, selectedNode) => {
  if (!modalForm) {
    return true;
  }
  for (let input of inputs) {
    if (input.type === "inputWithSelect") {
      for (let innerInput of input.input) {
        if (
          !modalForm[innerInput.identifier] ||
          modalForm[innerInput.identifier] === ""
        ) {
          return true;
        }
      }
    } else {
      if (
        (!modalForm[input.identifier] ||
          modalForm[input.identifier] === "" ||
          (Array.isArray(modalForm[input.identifier]) &&
            modalForm[input.identifier]?.length === 0)) &&
        !(
          (selectedNode.data.nodeType === "database" &&
            input.identifier === "dbTable" &&
            modalForm?.rowSelInfo === "query") ||
          (selectedNode.data.nodeType === "database" &&
            modalForm?.dbType === "sqlserver" &&
            input.identifier === "authentication") ||
          (selectedNode.data.nodeType === "database" &&
            modalForm?.dbType === "bigquery" &&
            modalForm?.authType === "none" &&
            input.identifier === "files") ||
          input.identifier === "description" ||
          (input.identifier === "name" && selectedNode.data.isStage)
        )
      ) {
        return true;
      } else if (Array.isArray(modalForm[input.identifier])) {
        // Condition to check if any array does not contain any empty strings
        const hasEmptyString = modalForm[input.identifier].some(
          (element) => element.length === 0
        );
        if (hasEmptyString) {
          return true;
        }
      }
    }
    // Condition to disable button if stats node numCols array has less than 2 items
    if (
      selectedNode.data.nodeType === "statisticalTransformation" &&
      input.identifier === "numCols" &&
      Array.isArray(modalForm[input.identifier]) &&
      modalForm[input.identifier]?.length <= 1
    ) {
      return true;
    }
  }
  return false;
};

const getNextDsNode = (flow, outgoingEdges, outgoingDs) => {
  for (let outgoingEdge of outgoingEdges) {
    const outgoingNode = flow.nodes.find(
      (node) => node.id === outgoingEdge.source
    );
    if (!outgoingNode.data.isStage) {
      outgoingDs.push(outgoingNode);
    } else {
      const outgoingEdges = flow.edges.filter(
        (edge) => edge.target === outgoingNode.id
      );
      getNextDsNode(flow, outgoingEdges, outgoingDs);
    }
  }
  return outgoingDs;
};

const getOutgoingDsNodes = (flow, node) => {
  const outgoingEdges = flow.edges.filter((edge) => edge.target === node.id);
  const outgoingDataSources = getNextDsNode(flow, outgoingEdges, []);
  return outgoingDataSources;
};

const showSuccessAlert = (message, updateSuccessAlertMessage) => {
  updateSuccessAlertMessage(message);
  setTimeout(() => {
    updateSuccessAlertMessage(null);
  }, 6000);
};

const handleModellingNodesRedirect = async (
  savedPayload,
  savedNodeId,
  selectedProjectKey,
  selectedProjVersion,
  selectedFeatureGroup,
  updateIsPlaygroundLoading,
  handleMultipleModellingNodesAnimation,
  updateAlertMessage
) => {
  if (!savedNodeId) {
    return;
  }
  const { dataCfg } = savedPayload[savedNodeId] || {};
  const mlNodes = savedPayload.mlNodes;
  const { type: nodeKind, cfg: nodeConfig } = dataCfg || {};
  if (!nodeConfig || isEmpty(nodeConfig)) {
    return;
  }
  let nodesData;
  if (nodeKind === "model-dev") {
    nodesData = await createAutoMLProject(
      nodeConfig,
      savedNodeId,
      selectedProjectKey,
      selectedProjVersion,
      selectedFeatureGroup,
      mlNodes,
      updateIsPlaygroundLoading,
      updateAlertMessage
    );
  } else if (nodeKind === "model-score") {
    nodesData = await handleCfgOotScoringAPI(
      nodeConfig,
      savedNodeId,
      selectedProjectKey,
      selectedProjVersion,
      selectedFeatureGroup,
      mlNodes,
      updateIsPlaygroundLoading,
      updateAlertMessage
    );
  }
  if (nodesData) {
    setTimeout(
      () =>
        handleMultipleModellingNodesAnimation(
          nodesData,
          savedPayload,
          savedNodeId
        ),
      1
    );
  }
};

const createAutoMLProject = async (
  nodeConfig,
  savedNodeId,
  selectedProjectKey,
  selectedProjVersion,
  selectedFeatureGroup,
  mlNodes,
  updateIsPlaygroundLoading,
  updateAlertMessage
) => {
  const payload = {
    projectKey: selectedProjectKey,
    projVersion: selectedProjVersion,
    projFg: selectedFeatureGroup,
    dSource: nodeConfig.inputDs,
    pname: nodeConfig.name,
    description: nodeConfig.description,
    ptype: nodeConfig.ptype,
    nodeId: savedNodeId,
    mlNodes: mlNodes,
  };
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const apiUrl = BASE_API_URL + "createautomlproject";
  const headers = {
    "Content-type": "application/json",
    Accept: "text/plain",
  };
  try {
    updateIsPlaygroundLoading(true);
    let response = {};
    if (USING_TEST_DATA) {
      const createautomlprojectAPITestData = require("../assets/apiTestData/createautomlproject-test-data.json");
      response = {
        data: createautomlprojectAPITestData,
      };
    } else {
      response = await api.post(apiUrl, payload, {
        headers: headers,
      });
    }
    updateIsPlaygroundLoading(false);
    if (response?.status === "Success") {
      return response.data.posts[0];
    } else if (response.data.status === 404) {
      if (response.data.data.reason) {
        updateAlertMessage(response.data.data.reason);
      } else {
        updateAlertMessage("Something went wrong. Please try again later");
      }
    }
  } catch (error) {
    console.log(error);
    updateIsPlaygroundLoading(false);
    const errorMessage =
      "Something went wrong. Please contact the administrator";
    updateAlertMessage(errorMessage);
  }
};

const handleCfgOotScoringAPI = async (
  nodeConfig,
  savedNodeId,
  selectedProjectKey,
  selectedProjVersion,
  selectedFeatureGroup,
  mlNodes,
  updateIsPlaygroundLoading,
  updateAlertMessage
) => {
  const payload = {
    projectKey: selectedProjectKey,
    projVersion: selectedProjVersion,
    projFg: selectedFeatureGroup,
    dSource: nodeConfig.dSource,
    pname: nodeConfig.name,
    modelList: [],
    modelSource: nodeConfig.modelSource,
    nodeId: savedNodeId,
    autoMlProjectKey: "",
    autoMlProjVersion: "",
    mlNodes: mlNodes,
  };
  if (nodeConfig.modelSource === "None") {
    payload["autoMlProjectKey"] = nodeConfig.autoMlProjectKey;
    payload["autoMlProjVersion"] = nodeConfig.autoMlProjVersion;
  }
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const apiUrl = BASE_API_URL + "cfgootscoring";
  const headers = {
    "Content-type": "application/json",
    Accept: "text/plain",
  };
  try {
    updateIsPlaygroundLoading(true);
    let response = {};
    if (USING_TEST_DATA) {
      const cfgootscoringAPITestData = require("../assets/apiTestData/cfgootscoring-test-data.json");
      response = {
        data: cfgootscoringAPITestData,
      };
    } else {
      response = await api.post(apiUrl, payload, {
        headers: headers,
      });
    }
    updateIsPlaygroundLoading(false);
    if (response?.status === "Success") {
      return response.data.posts[0];
    } else if (response.data.status === 404) {
      if (response.data.data.reason) {
        updateAlertMessage(response.data.data.reason);
      } else {
        updateAlertMessage("Something went wrong. Please try again later");
      }
    }
  } catch (error) {
    console.log(error);
    updateIsPlaygroundLoading(false);
    const errorMessage =
      "Something went wrong. Please contact the administrator";
    updateAlertMessage(errorMessage);
  }
};

const getAllModelDevNodesList = (mlNodes = [], nodesFlow) => {
  const modelDevNodes = [];
  nodesFlow.nodes.forEach((node) => {
    if (
      mlNodes.includes(node.id) &&
      node.data.nodeType === "modelDevelopment"
    ) {
      modelDevNodes.push(node.id);
    }
  });
  return modelDevNodes;
};

const getModelSourceOptions = (mlNodes = [], nodesFlow, nodeConfigurations) => {
  const modelDevNodes = getAllModelDevNodesList(
    mlNodes,
    nodesFlow,
    nodeConfigurations
  );
  const modelSourceOptions = [];
  modelDevNodes.forEach((nodeId) => {
    const nodeName = nodeConfigurations[nodeId]?.name;
    modelSourceOptions.push({
      name: nodeName,
      value: nodeId,
    });
  });
  modelSourceOptions.push({
    name: "None",
    value: "None",
  });
  return modelSourceOptions;
};

const getAutoMlProjectsData = async (
  selectedProjectKey,
  selectedProjVersion,
  selectedFeatureGroup,
  updateIsLoading,
  updateAlertMessage
) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const apiUrl = BASE_API_URL + "listautomlprojects ";
  const headers = {
    "Content-type": "application/json",
    Accept: "text/plain",
  };
  const payload = {
    projectKey: selectedProjectKey,
    projVersion: selectedProjVersion,
    projFg: selectedFeatureGroup,
  };
  updateIsLoading(true);
  try {
    let response = {};
    if (USING_TEST_DATA) {
      const listautomlprojectsAPITestData = require("../assets/apiTestData/listautomlprojects-test-data.json");
      response = {
        data: listautomlprojectsAPITestData,
      };
    } else {
      response = await api.post(apiUrl, payload, {
        headers: headers,
        withCredentials: true,
      });
    }
    updateIsLoading(false);
    if (response.data.status === 200) {
      const result = response.data.data.posts[0];
      return result;
    } else if (response.data.status === 404) {
      return null;
    }
  } catch (error) {
    console.log(error);
    updateIsLoading(false);
    const errorMessage =
      "Something went wrong. Please contact the administrator";
    updateAlertMessage(errorMessage);
    return [];
  }
};

const getAutoMlProjectsList = (data) => {
  const projectsList = data.map((project) => ({
    name: project.pname,
    value: project.projectkey,
  }));
  return projectsList;
};

const getAutoMlVersionsList = (selectedProjectKey, data) => {
  const selectedProject = data.find(
    (project) => project.projectkey === selectedProjectKey
  );
  if (selectedProject) {
    const versionsList = selectedProject.versionInfo.map((version) => ({
      name: version.vname,
      value: version.vname,
    }));
    return versionsList;
  } else {
    return [];
  }
};

const downloadFile = async (
  downloadUrl,
  fileName,
  fileType,
  updateIsLoading,
  updateAlertMessage
) => {
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  updateIsLoading(true);
  try {
    let response = {};
    if (USING_TEST_DATA) {
      const dwnldDataAPITestData = require("../assets/apiTestData/dwnld-data-test-data.json");
      response = {
        data: dwnldDataAPITestData,
      };
    } else {
      response = await api.get(downloadUrl, {
        responseType: "blob",
      });
    }
    updateIsLoading(false);
    const blob = new Blob([response.data], {
      type: `application/${fileType}`,
    });
    const url = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", `${fileName}.${fileType}`);
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
    updateIsLoading(false);
    const errorMessage =
      "Something went wrong. Please contact the administrator";
    updateAlertMessage(errorMessage);
  }
};

const simulateMouseClick = (element) => {
  element.dispatchEvent(
    new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
      buttons: 1,
    })
  );
};

const disableCopyingMode = (
  nodes,
  updateEnableCopyMode,
  updateCopyNodesList
) => {
  updateEnableCopyMode(false);
  nodes.forEach((nd) => {
    let nodeClasses = nd.className.split(" ");
    if (nodeClasses.includes("copy-mode")) {
      nodeClasses.splice(nodeClasses.indexOf("copy-mode", 1));
    }
    nd.className = nodeClasses.join(" ");
  });
  updateCopyNodesList([]);
};

const handleCopyModeDisabling = (
  flowInfo,
  updateEnableCopyMode,
  updateCopyNodesList,
  updateNodesOpacity
) => {
  const flow = flowInfo.toObject();
  const nodes = flow.nodes;
  disableCopyingMode(nodes, updateEnableCopyMode, updateCopyNodesList);
  updateNodesOpacity(nodes);
  const drawingArea = document.querySelector(".dndflow .react-flow__pane");
  simulateMouseClick(drawingArea);
};

const getAndUpdateTooltipData = async (
  updateIsPlaygroundLoading,
  updateShortMsgTooltipData,
  updateDetailedMsgTooltipData,
  updateAlertMessage
) => {
  try {
    const BASE_API_URL = localStorage.getItem("BASE_API_URL");
    let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
    USING_TEST_DATA =
      USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
    const apiUrl = BASE_API_URL + "odatooltip";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    updateIsPlaygroundLoading(true);
    let response = {};
    if (USING_TEST_DATA) {
      const odatooltipAPITestData = require("../assets/apiTestData/tooltip-testdata.json");
      response = odatooltipAPITestData;
    } else {
      response = await api.get(apiUrl, {
        headers: headers,
      });
    }
    updateIsPlaygroundLoading(false);
    if (response.status === 200) {
      const data = response.data;
      updateTooltipData(
        data,
        updateShortMsgTooltipData,
        updateDetailedMsgTooltipData
      );
    } else if (response.status === 404) {
      if (response.data.reason) {
        updateAlertMessage(response.data.reason);
      } else {
        updateAlertMessage("Something went wrong. Please try again later");
      }
    }
  } catch (error) {
    console.log(error);
    updateIsPlaygroundLoading(false);
    const errorMessage =
      "Something went wrong. Please contact the administrator";
    updateAlertMessage(errorMessage);
  }
};

const updateTooltipData = (
  tooltipData,
  updateShortMsgTooltipData,
  updateDetailedMsgTooltipData
) => {
  const shortMsgTooltip = {};
  const detailedMsgTooltip = {};
  if (Array.isArray(tooltipData) && tooltipData) {
    tooltipData.forEach((data) => {
      if (data.TT_CODE) {
        shortMsgTooltip[data.TT_CODE] = data.TT_SHORT_MSG;
        detailedMsgTooltip[data.TT_CODE] = data.TT_DETAILED_MSG;
      }
    });
    updateShortMsgTooltipData(shortMsgTooltip);
    updateDetailedMsgTooltipData(detailedMsgTooltip);
  }
};

const handleProjectDeletion = async (
  projectKey,
  updateIsPlaygroundLoading,
  updateAlertMessage
) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const apiUrl = BASE_API_URL + "delproject";
  const headers = {
    "Content-type": "application/json",
    Accept: "text/plain",
  };
  const payload = {
    projectKey: projectKey,
    projVersion: null,
  };
  updateIsPlaygroundLoading(true);
  try {
    let response = {};
    if (USING_TEST_DATA) {
      const delprojectAPITestData = require("../assets/apiTestData/success-test-data.json");
      response = {
        data: delprojectAPITestData,
      };
    } else {
      response = await api.post(apiUrl, payload, { headers: headers });
    }
    updateIsPlaygroundLoading(false);
    if (response.data.status === 404) {
      if (response.data.data.reason) {
        updateAlertMessage(response.data.data.reason);
      } else {
        updateAlertMessage("Something went wrong. Please try again later");
      }
    }
  } catch (error) {
    console.log(error);
    updateIsPlaygroundLoading(false);
    const errorMessage =
      "Something went wrong. Please contact the administrator";
    updateAlertMessage(errorMessage);
  }
};

export {
  prepareColumns,
  handleFilterSaveDisable,
  restoreDialogUI,
  getConnectedDataSources,
  handleDependentInputs,
  initializeFilterInfo,
  handleGeneralSaveButtonDisabling,
  getConnectedNodes,
  fetchFeatures,
  getConfigureVariablesdata,
  getPreviousUnStagedNode,
  getOutgoingDsNodes,
  showSuccessAlert,
  handleModellingNodesRedirect,
  getModelSourceOptions,
  getAutoMlProjectsData,
  getAutoMlProjectsList,
  getAutoMlVersionsList,
  downloadFile,
  simulateMouseClick,
  disableCopyingMode,
  handleCopyModeDisabling,
  getCurrentProjectDetails,
  getAndUpdateTooltipData,
  handleProjectDeletion,
};
